import { RouteCommonToolbarMeteoSwitchMap } from "./components"
import {
	RouteCommonAdditionalItemType,
	RouteCommonAisDataSourceType,
	RouteCommonMeteoType
} from "./type"
import { CommonInitItemEvent } from "@/types/event"

export enum RouteCommonAisItemColorMap {
	Purple = "#B91CFD", // Bright Purple
	Pink = "#E538CF", // Vibrant Pink
	Blue = "#474BFF", // Electric Blue
	Red = "#FD451C", // Bright Red
	LightOrange = "#FFBA8C", // Light Orange
	Lavender = "#CE95FF", // Lavender
	LimeGreen = "#0CF000", // Neon Green
	DarkGray = "#990", // Dark Gray
	OliveGreen = "#8CC63E", // Olive Green
	ForestGreen = "#009345", // Forest Green
	SkyBlue = "#00C4EF", // Sky Blue
	AquaGreen = "#00FFA6", // Aqua Green
	VividBlue = "#00A1D8",
	DeepBlue = "#0056D6",
	DeepPurple = "5E30EB",
	DeepPink = "E63B7A",
	VermilionOrange = "#FE6250",
	WarmOrange = "#FE8648",
	ElectricPurple = "#BE38F3",
	LemonYellow = "#F5EC00",
	YellowTint = "#9BA50E",
	AppleGreen = "#669D34"
}

export const initialMeteoStatus: Record<RouteCommonMeteoType, RouteCommonToolbarMeteoSwitchMap> = {
	Wind: RouteCommonToolbarMeteoSwitchMap.Off,
	Wave: RouteCommonToolbarMeteoSwitchMap.Off,
	Swell: RouteCommonToolbarMeteoSwitchMap.Off,
	Current: RouteCommonToolbarMeteoSwitchMap.Off,
	Typhoon: RouteCommonToolbarMeteoSwitchMap.Off,
	Pressure: RouteCommonToolbarMeteoSwitchMap.Off,
	ENC: RouteCommonToolbarMeteoSwitchMap.Off
}

export const initialAisData: CommonInitItemEvent<RouteCommonAisDataSourceType> = (item) => {
	return {
		current: item?.current ?? null,
		route: {
			ais: item?.route?.ais ?? {
				type: "FeatureCollection",
				features: []
			},
			trackPoints: item?.route?.trackPoints ?? []
		}
	}
}

export const initialAddtional: CommonInitItemEvent<RouteCommonAdditionalItemType> = (item) => {
	return {
		currentMode: item?.currentMode ?? null,
		currentTools: item?.currentTools ?? [],
		rangeOption: {
			startDate: item?.rangeOption?.startDate ?? null,
			endDate: item?.rangeOption?.endDate ?? null,
			type: item?.rangeOption?.type ?? null
		}
	}
}
