import { CommonInitItemEvent } from "@/types/event"
import { CommonNewlyAccountItemType } from "./type"
import React from "react"

export const initialAccountItem: CommonInitItemEvent<CommonNewlyAccountItemType> = (item) => {
	return {
		id: item?.id ?? null,
		companyId: item?.companyId ?? null,
		companyName: item?.companyName ?? null,
		bankName: item?.bankName ?? "",
		bankAccountAddress: item?.bankAccountAddress ?? "",
		bankAccountNumber: item?.bankAccountNumber ?? "",
		beneficiaryAddress: item?.beneficiaryAddress ?? "",
		beneficiaryCompanyAddress: item?.beneficiaryCompanyAddress ?? "",
		beneficiaryName: item?.beneficiaryName ?? "",
		swiftNumber: item?.swiftNumber ?? ""
	}
}

export const checkAccountKeys: (keyof CommonNewlyAccountItemType)[] = [
	"bankName",
	// "bankAccountAddress",
	"bankAccountNumber",
	"beneficiaryName",
	// "beneficiaryAddress",
	"swiftNumber"
]

export const columns: {
	title: string
	dataIndex: Exclude<keyof CommonNewlyAccountItemType, "id" | "companyId">
	width: number
	fixed?: "left" | "right"
	required?: boolean
	fixedWidth?: number
	render?: (
		value: string | number,
		record: CommonNewlyAccountItemType,
		index: number,
		actions?: {
			onRemove?: (record: CommonNewlyAccountItemType) => void
		}
	) => React.ReactNode
}[] = [
	{
		title: "Bank Name",
		dataIndex: "bankName",
		width: 186,
		fixed: "left",
		fixedWidth: 0,
		required: true
	},
	{
		title: "Bank Account Address",
		dataIndex: "bankAccountAddress",
		required: false,
		width: 208
	},
	{
		title: "Bank Account Number",
		dataIndex: "bankAccountNumber",
		width: 225,
		required: true
	},
	{
		title: "Beneficiary Name",
		dataIndex: "beneficiaryName",
		width: 136,
		required: true
	},
	{
		title: "Beneficiary Address",
		dataIndex: "beneficiaryAddress",
		width: 250,
		required: false
	},
	{
		title: "Beneficiary Company Address",
		dataIndex: "beneficiaryCompanyAddress",
		width: 250
	},
	{
		title: "Swift Code",
		dataIndex: "swiftNumber",
		width: 207,
		required: true
	},
	{
		title: "action",
		dataIndex: "bankAccountAddress",
		width: 70,
		fixed: "right",
		fixedWidth: 0,
		render: (value, record, index, actions) => {
			return (
				<span
					className="font_family fleet-delete"
					onClick={() => actions?.onRemove?.(record)}
				></span>
			)
		}
	}
]
