import { FleetOptionType } from "@/types/advancedType"
import i18n from "@/locale"
import { CommonInitItemEvent } from "@/types/event"
import { MovementTctcDialogItemKeys, MovementTctcOtherItemType } from "./type"

export const movementTctcDialogOptions: (FleetOptionType<string, MovementTctcDialogItemKeys> & {
	disabled?: boolean
})[] = [
	{
		label: i18n.t("voyageManagement.movement.bunkerPlan"),
		value: "bunkerPlan"
	},
	// {
	// 	label: i18n.t("voyageManagement.movement.offHire"),
	// 	value: "offHire"
	// },
	{
		label: i18n.t("voyageManagement.movement.otherExpenses"),
		value: "otherExpenses"
	},
	{
		label: i18n.t("voyageManagement.movement.otherIncome"),
		value: "otherIncome"
	},
	{
		label: i18n.t("voyageManagement.movement.disputeCharge"),
		value: "disputeCharge"
	},
	{
		label: i18n.t("voyageManagement.movement.handoverForm"),
		value: "handoverForm"
	},
	{
		label: i18n.t("voyageManagement.movement.commission"),
		value: "commission"
	}
]

export const initialMovementBunkerCost: CommonInitItemEvent<
	MovementTctcOtherItemType["reletBunkerPlan"]
> = (item) => {
	return {
		lsfoTotalPrice: item?.lsfoTotalPrice ?? 0,
		hsfoTotalPrice: item?.hsfoTotalPrice ?? 0,
		mdoTotalPrice: item?.mdoTotalPrice ?? 0,
		mgoTotalPrice: item?.mgoTotalPrice ?? 0,
		scrubberTotalPrice: item?.scrubberTotalPrice ?? 0,
		settledTime: item?.settledTime ?? null,
		status: item?.status ?? "Settled",
		bunkerPlanExpenseSettled: item?.bunkerPlanExpenseSettled ?? null,
		bunkerPlanSettled: item?.bunkerPlanSettled ?? null
	}
}

export const initialMovementTctcOtherItem: CommonInitItemEvent<MovementTctcOtherItemType> = (
	item
) => {
	return {
		id: item?.id ?? null,
		highlightedPoint: item?.highlightedPoint ?? "",
		otherIncome: item?.otherIncome ?? 0,
		stage: item?.stage ?? "Delivery",
		status: item?.status ?? "Commencing",
		voyageNo: item?.voyageNo ?? "",
		voyageRemark: item?.voyageRemark ?? "",
		voyageType: item?.voyageType ?? "TCTC",
		offHire: item?.offHire ?? [],
		rentHireList: item?.rentHireList ?? [],
		reletHireList: item?.reletHireList ?? [],
		opNote: item?.opNote ?? "",
		otherExpense: item?.otherExpense ?? [],
		disputeChargeList: item?.disputeChargeList ?? [],
		reletBunkerPlan: item?.reletBunkerPlan ?? initialMovementBunkerCost(item?.reletBunkerPlan),
		rentBunkerPlan: item?.rentBunkerPlan ?? initialMovementBunkerCost(item?.rentBunkerPlan),
		ar: item?.ar ?? null,
		ap: item?.ap ?? null,
		totalInsure: item?.totalInsure ?? 0,
		commissionList: item?.commissionList ?? []
	}
}
