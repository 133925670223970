import { CommonInitItemEvent } from "@/types/event"
import { EstDialogItemType, EstOtherItemType } from "./type"

export const initialEstOtherItem: CommonInitItemEvent<EstOtherItemType> = (item) => {
	return {
		ecaDistanceAdjustFactor: item?.ecaDistanceAdjustFactor ?? 0,
		status: item?.status ?? "DFT",
		remark: item?.remark ?? "",
		startVoyTime: item?.startVoyTime ?? "",
		frtMidValue: item?.frtMidValue ?? "",
		frtStep: item?.frtStep ?? "",
		tceMidValue: item?.tceMidValue ?? "",
		tceStep: item?.tceStep ?? "",
		id: item?.id ?? null,
		cpSpeed: item?.cpSpeed ?? "",
		deliveryPort: [],
		ballastMarginDay: item?.ballastMarginDay ?? "",
		ladenMarginDay: item?.ladenMarginDay ?? "",
		ballastMarginDistance: item?.ballastMarginDistance ?? "",
		ladenMarginDistance: item?.ladenMarginDistance ?? "",
		ballastMarginFlag: item?.ballastMarginFlag ?? 0,
		ladenMarginFlag: item?.ladenMarginFlag ?? 0,
		type: item?.type ?? 0
	}
}

export const initialDialogItem: CommonInitItemEvent<EstDialogItemType> = () => {
	return {
		spdAllocation: true,
		stowagePlan: true,
		performance: true,
		freightSensitivity: true
	}
}
