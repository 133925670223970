import React, { FC } from "react"
import style from "./index.module.less"
import { MovementCurrentPlanProps } from "./type"
import useIndex from "./hooks/useIndex"
import dayjs from "dayjs"
import { PurposeMap, voyageList } from "./source"

const MovementCurrentPlan: FC<MovementCurrentPlanProps> = ({ voyageId, showMap }) => {
	const { currentPlanData, mapContainer, showDeatil, handleShow } = useIndex(voyageId, showMap)
	return (
		<div className={style["movement-currentPlan"]} style={{ padding: showMap ? "0" : "16px" }}>
			<div className="movement-details--header">
				<span className="details--header-left">
					{!showMap && <span className="fleet-title">Current Voyage</span>}
					<span className="currentPlan-header-detail">
						Updated：
						{currentPlanData?.currentState?.updateTime
							? dayjs(currentPlanData?.currentState?.updateTime).format("YYYY-MM-DD HH:mm")
							: "--"}
					</span>
				</span>
				<span className="currentPlan-header-detail">VoyageNo：{currentPlanData?.voyageNo}</span>
			</div>

			<div className="voyage-message-line">
				<div style={{ display: "flex" }}>
					{currentPlanData?.portRation?.map((item, index) => {
						return (
							<div className="message-line-stepBox" key={index}>
								<div className="message-line-step">
									<div className="step-title">{item.portName ? item.portName : "--"}</div>
									<div className="step-content">
										<div>
											{item.ata
												? "ATA: " + dayjs(item.ata).format("YYYY-MM-DD HH:mm")
												: "ETA: " + (item.eta ? dayjs(item.eta).format("YYYY-MM-DD HH:mm") : "--")}
										</div>
										<div>
											{item.atd
												? "ATD: " + dayjs(item.atd).format("YYYY-MM-DD HH:mm")
												: "ETD: " + (item.etd ? dayjs(item.etd).format("YYYY-MM-DD HH:mm") : "--")}
										</div>
									</div>
								</div>

								<div style={{ position: "relative", width: "100%" }}>
									<div
										className={`el-step__line ${
											index < currentPlanData?.portRation?.length - 1 &&
											(currentPlanData?.portRation[index + 1]["ata"] ||
												currentPlanData?.portRation[index + 1]["atd"])
												? "arrived"
												: "not-arrived"
										}`}
										style={{
											display: index == currentPlanData?.portRation?.length - 1 ? "none" : ""
										}}
									>
										<i className="el-step__line-inner"></i>
									</div>
									<div
										className={`el-step__icon is-text ${
											currentPlanData?.portRation[index]["ata"] ||
											currentPlanData?.portRation[index]["atd"]
												? "active"
												: ""
										}`}
									>
										<div className="el-step__icon-inner">{PurposeMap[item.purpose]}</div>
									</div>
								</div>
							</div>
						)
					})}
				</div>
			</div>
			{!showMap && (
				<div className="routeGraph-showBar" onClick={handleShow}>
					<span>Details</span>
					<span
						className={"font_family fleet-a-slideup"}
						style={{
							transform: showDeatil ? "none" : "rotate(180deg)",
							transition: "transform 0.3s ease" // 可选：添加动画效果
						}}
					></span>
				</div>
			)}

			<div
				className="routeGraph-container"
				style={{
					display: showDeatil || showMap ? "flex" : "none"
				}}
			>
				<div className="routeGraph-map">
					<div className="routeGraph" ref={mapContainer}></div>
				</div>
				<div className="voyage-message-container">
					{currentPlanData?.portRationBasicList?.map((item, index) => {
						return (
							<div className="voyage-message-box" key={index}>
								<div className="voyage-message-item">
									<span className="text-[#333] font-medium">{item.portName}</span>
									<span>
										<span>DATE OF REPORT: </span>
										<span>
											{item.dateTime ? dayjs(item.dateTime).format("YYYY-MM-DD HH:mm") : "--"}
										</span>
									</span>
								</div>
								{voyageList?.map((vitem, vindex) => {
									return (
										<div className="voyage-message-item" key={vindex}>
											<span>{vitem?.label}</span>
											<span>
												{vitem.prop === "nameOfBerth"
													? item?.[vitem?.prop]
													: item?.[vitem?.prop]
														? dayjs(item?.[vitem?.prop]).format("YYYY-MM-DD HH:mm")
														: "--"}
											</span>
										</div>
									)
								})}
								<div className="voyage-message-item">
									<span>ETCD (LT)</span>
									<span>{item.ETCD ? dayjs(item?.ETCD)?.diff(dayjs(), "day") + "D" : "--"} </span>
									<span>{item.ETCD ? dayjs(item.ETCD).format("YYYY-MM-DD HH:mm") : "--"}</span>
								</div>
							</div>
						)
					})}
				</div>
			</div>
		</div>
	)
}

export default MovementCurrentPlan
