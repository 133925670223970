import { useEffect, useRef } from "react"
import { useInvTemplate } from "../../../../../common"
import { InvFreightTemplateProps, InvFreightTemplateReSourceType } from "../type"
import { formatThousandthNumber } from "@/tools/amount"

const useIndex = (
	canvasRef: InvFreightTemplateProps["canvasRef"],
	dataSource: InvFreightTemplateProps["dataSource"],
	activeTab: InvFreightTemplateProps["activeTab"]
) => {
	const {
		markRef,
		ctxPosition,
		drawLine,
		drawHeader,
		drawSpecialText,
		drawText,
		markRefInit,
		drawFooter,
		splitWords
	} = useInvTemplate()

	const resource = useRef<InvFreightTemplateReSourceType>({
		ownerFields: [],
		plusItems: [],
		lessItems: []
	})

	const initDataSource = () => {
		const { baseItem, beneficiaryFieldItems, otherItem, otherExpenses } = dataSource
		let lessItems: InvFreightTemplateReSourceType["lessItems"] = [],
			plusItems: InvFreightTemplateReSourceType["lessItems"] = []
		const ownerFields: InvFreightTemplateReSourceType["ownerFields"] = [
			...beneficiaryFieldItems,
			{
				fieldName: "Beneficiary Account",
				fieldVal: baseItem?.beneficiaryAccount
			},
			{ fieldName: "Beneficiary Bank", fieldVal: baseItem?.beneficiaryBank },
			{ fieldName: "Beneficiary Name", fieldVal: baseItem?.beneficiaryName },
			{ fieldName: "BanK Address", fieldVal: baseItem?.bankAddress },
			{ fieldName: "Swift Code", fieldVal: baseItem?.swiftCode }
		]

		if (otherItem?.addOther) {
			otherExpenses?.forEach((expenseItem) => {
				switch (true) {
					case +expenseItem?.fieldVal > 0:
						plusItems?.push({
							fieldName: expenseItem?.fieldName,
							fieldVal: expenseItem?.fieldVal,
							description: expenseItem?.description
						})
						break
					default:
						lessItems?.push({
							fieldName: expenseItem?.fieldName,
							fieldVal: expenseItem?.fieldVal,
							description: expenseItem?.description
						})
						break
				}
			})
		}

		ctxPosition.current = {
			currentX: 66,
			currentY: 52
		}

		resource.current = {
			ownerFields,
			plusItems,
			lessItems
		}
	}

	const drawInvoiceType = () => {
		const { otherItem, baseItem } = dataSource
		const ctx = canvasRef?.current?.getContext("2d")
		let currentX = ctxPosition.current.currentX,
			currentY = ctxPosition.current.currentY
		ctx.save()
		ctx.font = "400 24px Arial"
		ctx.textAlign = "left"
		ctx.fillStyle = "rgba(0, 0, 0, 0.88)"

		let occupyZone = drawText(ctx, "Freight Invoices", 351.5, currentY)
		currentY += occupyZone.occupyHeight + 6

		drawLine(ctx, 351.5, currentY, 351.5 + occupyZone.occupyWidth, currentY, 1.5, "#000")

		currentY += 1.5 + 8.5

		ctx.font = "700 14px Arial"

		occupyZone = drawText(ctx, `TO: ${baseItem?.ownerName}`, currentX, currentY)

		currentY += occupyZone.occupyHeight + 2

		occupyZone = drawText(ctx, `Business registration address: `, currentX, currentY)

		const { lines } = splitWords(
			ctx,
			baseItem?.registerAddress,
			930 - 65 * 2 - occupyZone.occupyWidth
		)

		for (let line of lines) {
			const _occupyZone = drawText(ctx, line, currentX + occupyZone.occupyWidth, currentY)
			currentY += _occupyZone.occupyHeight + 1
		}

		if (lines?.length <= 0) {
			currentY += occupyZone.occupyHeight
		}

		currentY += 27

		ctx.restore()
		ctxPosition.current.currentY = currentY
	}

	const drawInvHeader = () => {
		const ctx = canvasRef?.current?.getContext("2d")
		let currentX = ctxPosition.current.currentX,
			currentY = ctxPosition.current.currentY
		ctx.save()
		ctx.textAlign = "left"
		ctx.fillStyle = "rgba(0, 0, 0, 0.88)"
		ctx.font = "700 14px Arial"

		let occupyZone = drawText(ctx, "Descriptions", 282, currentY)
		ctx.textAlign = "right"
		occupyZone = drawText(
			ctx,
			"AMOUNT",
			canvasRef.current.width - ctxPosition.current.currentX,
			currentY
		)

		currentY += occupyZone.occupyHeight + 2

		drawLine(
			ctx,
			ctxPosition.current.currentX,
			currentY,
			canvasRef.current.width - ctxPosition.current.currentX,
			currentY
		)

		currentY += 1 + 30.5

		ctx.restore()
		ctxPosition.current.currentY = currentY
	}

	const drawDescBaseItem = (currentWidth: number, label: string, value: string) => {
		const ctx = canvasRef?.current?.getContext("2d")
		let currentX = currentWidth,
			currentY = ctxPosition.current.currentY
		ctx.save()
		ctx.textAlign = "left"
		ctx.fillStyle = "rgba(0, 0, 0, 0.88)"
		ctx.font = "700 12px Arial"

		let occupyZone = drawText(ctx, label, currentX, currentY, 136)
		currentX += 136
		ctx.font = "400 12px Arial"

		occupyZone = drawText(ctx, value, currentX, currentY, 158)
		// currentX += occupyZone.occupyWidth + 158;
		currentX += 158

		ctx.restore()

		return {
			currentX,
			occupyHeight: occupyZone.occupyHeight
		}
	}

	const drawDescBase = () => {
		const { cargoItems, descItem, otherItem } = dataSource
		const ctx = canvasRef?.current?.getContext("2d")
		let currentX = ctxPosition.current.currentX
		ctx.save()

		let occupyZone = drawDescBaseItem(currentX, "MV", descItem?.vesselName)
		currentX = occupyZone.currentX + 60

		occupyZone = drawDescBaseItem(currentX, "C/P DATE", descItem?.fixtureDate)
		ctxPosition.current.currentY += occupyZone.occupyHeight + 9
		currentX = ctxPosition.current.currentX

		occupyZone = drawDescBaseItem(currentX, "IMO No.", descItem?.imo ? `${descItem?.imo}` : "")
		currentX = occupyZone.currentX + 60
		if (otherItem?.addCargo) {
			for (let i = 0; i <= cargoItems?.length - 1; i++) {
				// if (!cargoItems[i]?.bolDate) continue;
				occupyZone = drawDescBaseItem(
					currentX,
					`B/L ${cargoItems?.[i]?.bolNo} DATE:`,
					cargoItems[i]?.bolDate
				)
				ctxPosition.current.currentY += occupyZone.occupyHeight + 9
				// switch (true) {
				// 	case i === cargoItems?.length - 1:
				// 		ctxPosition.current.currentY += occupyZone.occupyHeight;
				// 		currentX = ctxPosition.current.currentX;
				// 		break;
				// 	case i % 2 === 0 && i !== cargoItems?.length - 1:
				// 		ctxPosition.current.currentY += occupyZone.occupyHeight + 9;
				// 		currentX = ctxPosition.current.currentX;
				// 		break;
				// 	default:
				// 		currentX = occupyZone.currentX + 60;
				// 		break;
				// }
			}
		}

		ctxPosition.current.currentY += 20

		ctx.restore()
	}

	const drawPort = () => {
		const { otherItem, loadPortDemDes, dischargeDemDes } = dataSource
		if (!otherItem?.addPort) return

		const ctx = canvasRef?.current?.getContext("2d")
		ctx.save()
		ctx.textAlign = "left"
		ctx.fillStyle = "rgba(0, 0, 0, 0.88)"
		let occupyZone
		let currentX = ctxPosition.current.currentX,
			currentY = ctxPosition.current.currentY

		ctx.font = "700 12px Arial"
		occupyZone = drawText(ctx, "L/PORT", currentX, currentY)
		currentX += 136

		ctx.font = "400 12px Arial"

		for (let i = 0; i <= loadPortDemDes?.length - 1; i++) {
			occupyZone = drawText(ctx, loadPortDemDes?.[i]?.portName, currentX, currentY)
			switch (true) {
				case i === loadPortDemDes?.length - 1:
					currentY += occupyZone.occupyHeight
					break
				default:
					currentY += occupyZone.occupyHeight + 5
					break
			}
		}

		// let occupyZone = drawDescBaseItem(currentX, "L/PORT", "PORT KEMBLA, AUSTRALIA");
		currentY += 20

		ctxPosition.current.currentY = currentY

		ctx.font = "700 12px Arial"
		occupyZone = drawText(ctx, "D/PORT", ctxPosition.current.currentX, currentY)

		ctx.font = "400 12px Arial"

		for (let i = 0; i <= dischargeDemDes?.length - 1; i++) {
			occupyZone = drawText(ctx, dischargeDemDes?.[i]?.portName, currentX, currentY)
			switch (true) {
				case i === dischargeDemDes?.length - 1:
					currentY += occupyZone.occupyHeight
					break
				default:
					currentY += occupyZone.occupyHeight + 5
					break
			}
		}

		currentY += 20

		ctxPosition.current.currentY = currentY
		ctx.restore()
	}

	const drawCargo = () => {
		const { otherItem, cargoItems, descItem } = dataSource
		if (!otherItem?.addCargo) return
		const ctx = canvasRef?.current?.getContext("2d")
		let currentX = ctxPosition.current.currentX,
			currentY = ctxPosition.current.currentY
		ctx.save()
		ctx.textAlign = "left"
		ctx.fillStyle = "rgba(0, 0, 0, 0.88)"
		ctx.font = "700 12px Arial"

		let occupyZone = drawText(ctx, "CARGO DETAILS", currentX, currentY)
		currentX += 136

		for (let i = 0; i <= cargoItems?.length - 1; i++) {
			const cargoItem = cargoItems?.[i]
			ctx.font = "400 12px Arial"
			occupyZone = drawText(
				ctx,
				`${cargoItem?.bolNo} ${cargoItem?.cargoName}`,
				currentX,
				currentY,
				265
			)
			currentX += 20 + 265

			ctx.font = "700 12px Arial"
			drawText(
				ctx,
				`${formatThousandthNumber(+cargoItem?.cargoQuantity)} ${cargoItem?.cargoUnit}`,
				currentX,
				currentY
			)

			currentX = ctxPosition.current.currentX + 136
			currentY += occupyZone.occupyHeight + 5
		}

		currentX += 20 + 265

		ctx.font = "700 12px Arial"
		occupyZone = drawText(
			ctx,
			`${formatThousandthNumber(+descItem?.ttlCargoQuantity)} ${cargoItems?.[0]?.cargoUnit}`,
			currentX,
			currentY
		)
		currentX -= 106
		occupyZone = drawText(ctx, "TOTAL", currentX, currentY)

		currentY += occupyZone.occupyHeight + 26

		ctx.font = "700 14px Arial"

		let occupyHeight = drawSpecialText(ctx, canvasRef, "$", "suffix", currentY, true)

		ctxPosition.current.currentY = currentY + occupyHeight + 17
		ctx.restore()
	}

	const drawFreightItem = (
		type: "freight" | "comm",
		title: string,
		price: number,
		quantity: number,
		precent: number
	) => {
		const ctx = canvasRef?.current?.getContext("2d")
		let currentX = ctxPosition.current.currentX,
			currentY = ctxPosition.current.currentY
		ctx.save()
		ctx.textAlign = "left"
		ctx.fillStyle = "rgba(0, 0, 0, 0.88)"
		ctx.font = "700 12px Arial"

		let occupyZone = drawText(ctx, title, currentX, currentY)
		ctx.font = "400 12px Arial"
		currentX += 136

		occupyZone = drawText(ctx, `USD ${formatThousandthNumber(price)} PMT`, currentX, currentY)
		currentX += occupyZone.occupyWidth + 12

		occupyZone = drawText(ctx, "x", currentX, currentY)
		currentX += occupyZone.occupyWidth + 12

		if (type === "freight") {
			occupyZone = drawText(ctx, `${formatThousandthNumber(quantity, 3, 3)} MT`, currentX, currentY)
			currentX += occupyZone.occupyWidth + 12

			occupyZone = drawText(ctx, "x", currentX, currentY)
			currentX += occupyZone.occupyWidth + 12
		}

		occupyZone = drawText(ctx, `${precent}%`, currentX, currentY)

		currentX += occupyZone.occupyWidth + 12

		if (type === "comm") {
			occupyZone = drawText(ctx, "x", currentX, currentY)
			currentX += occupyZone.occupyWidth + 12
			occupyZone = drawText(ctx, `${quantity}%`, currentX, currentY)

			currentX += occupyZone.occupyWidth + 12
		}

		const totalPrice =
			type === "freight"
				? title === "FREIGHT Brokerage"
					? -(price * quantity * precent) / 100
					: (price * quantity * precent) / 100
				: -(price * quantity * precent) / 10000
		drawSpecialText(ctx, canvasRef, formatThousandthNumber(totalPrice), "suffix", currentY)

		ctx.restore()
		ctxPosition.current.currentY = currentY + occupyZone.occupyHeight
	}

	const drawRate = (type: "load" | "discharge") => {
		console.log("test123")

		const { loadPortDemDes, dischargeDemDes } = dataSource
		const ctx = canvasRef?.current?.getContext("2d")
		ctx.save()
		ctx.textAlign = "left"
		ctx.fillStyle = "rgba(0, 0, 0, 0.88)"
		ctx.font = "700 12px Arial"
		let occupyZone,
			portItems = type === "load" ? loadPortDemDes : dischargeDemDes
		let currentX = ctxPosition.current.currentX,
			currentY = ctxPosition.current.currentY
		for (let i = 0; i <= portItems?.length - 1; i++) {
			const portItem = portItems?.[i]
			if (portItem?.rateType === "NOT") continue
			ctx.font = "700 12px Arial"
			occupyZone = drawText(
				ctx,
				`${portItem?.rateType} AT ${portItem?.portName}`,
				currentX,
				currentY
			)
			currentX += 160
			ctx.font = "400 12px Arial"
			// occupyZone = drawText(ctx, portItem?.portName, currentX, ctxPosition.current.currentY);
			if (portItem?.rateType === "DEM") {
				drawSpecialText(ctx, canvasRef, formatThousandthNumber(+portItem?.rate), "suffix", currentY)
				drawSpecialText(
					ctx,
					canvasRef,
					formatThousandthNumber(-(+portItem?.freightBrokerage || 0)),
					"suffix",
					currentY + occupyZone.occupyHeight + 5
				)

				currentY += occupyZone.occupyHeight + 5
				currentX = ctxPosition.current.currentX
				ctx.font = "700 12px Arial"
				occupyZone = drawText(
					ctx,
					`Brokerage ON DEMURRAGE AT ${portItem?.portName}`,
					currentX,
					currentY
				)
				ctx.font = "400 12px Arial"
				occupyZone = drawText(
					ctx,
					`${formatThousandthNumber(+portItem?.rate)} x ${portItem?.freightBrokerageRate || 0}%`,
					currentX + occupyZone.occupyWidth + 12,
					currentY
				)

				currentY += occupyZone.occupyHeight + 5
				currentX = ctxPosition.current.currentX
				ctx.font = "700 12px Arial"
				occupyZone = drawText(
					ctx,
					`ADD COMM ON DEMURRAGE AT ${portItem?.portName}`,
					currentX,
					currentY
				)
				ctx.font = "400 12px Arial"
				occupyZone = drawText(
					ctx,
					`${formatThousandthNumber(+portItem?.rate)} x ${portItem?.addComm}%`,
					currentX + occupyZone.occupyWidth + 12,
					currentY
				)

				// - ${portItem?.rate} x ${portItem?.addComm || 0}%
			}

			drawSpecialText(
				ctx,
				canvasRef,
				formatThousandthNumber(
					portItem?.rateType === "DES"
						? -portItem?.rate
						: -((+portItem?.rate * +portItem?.addComm) / 100)
				),
				"suffix",
				currentY
			)

			currentY += occupyZone.occupyHeight + 5
			ctxPosition.current.currentY = currentY
			currentX = ctxPosition.current.currentX
		}

		ctx.restore()
	}

	const drawFreight = () => {
		const { descItem, otherItem } = dataSource
		const ctx = canvasRef?.current?.getContext("2d")
		ctx.save()
		ctx.textAlign = "left"
		ctx.fillStyle = "rgba(0, 0, 0, 0.88)"
		ctx.font = "700 12px Arial"
		if (otherItem?.addFreightIncome) {
			drawFreightItem(
				"freight",
				"OCEAN FREIGHT",
				+descItem?.freightRate,
				+descItem?.ttlCargoQuantity,
				+descItem?.freightPercent
			)

			ctxPosition.current.currentY += 5
		}

		if (otherItem.addFreightIncome && descItem?.addComm) {
			drawFreightItem(
				"comm",
				"FREIGHT Add.Comm",
				+descItem?.ttlCargoQuantity * +descItem?.freightRate,
				+descItem?.addCommPaymentRatio,
				+descItem?.addCommRate
			)
			ctxPosition.current.currentY += 5
		}
		console.log("test", descItem)

		if (otherItem.addFreightIncome && descItem?.freightBrokerage) {
			descItem?.freightBrokerage &&
				drawFreightItem(
					"freight",
					"FREIGHT Brokerage",
					+descItem?.freightRate,
					+descItem?.ttlCargoQuantity,
					+descItem?.freightBrokerageRate
				)
			ctxPosition.current.currentY += 5
		}

		otherItem?.addDesDem && drawRate("load")
		otherItem?.addDesDem && drawRate("discharge")

		ctx.restore()
	}

	const drawPlusItem = (
		text: string,
		price: number,
		currentWidth: number,
		type: "suffix" | "prefix" = "suffix"
	) => {
		const ctx = canvasRef?.current?.getContext("2d")
		let currentX = currentWidth,
			currentY = ctxPosition.current.currentY
		ctx.save()
		ctx.font = "400 12px Arial"
		ctx.textAlign = "left"
		ctx.fillStyle = "rgba(0, 0, 0, 0.88)"

		let occupyZone = drawText(ctx, text, currentX, currentY)

		drawSpecialText(ctx, canvasRef, formatThousandthNumber(price), type, currentY)

		ctx.restore()

		ctxPosition.current.currentY = currentY + occupyZone.occupyHeight
	}

	const drawPlus = () => {
		const { otherExpenses, otherItem } = dataSource
		const { plusItems } = resource?.current
		if (!otherItem?.addOther) return
		const ctx = canvasRef?.current?.getContext("2d")
		let currentX = ctxPosition.current.currentX,
			currentY = ctxPosition.current.currentY,
			occupyZone
		ctx.save()
		ctx.font = "700 12px Arial"
		ctx.textAlign = "left"
		ctx.fillStyle = "rgba(0, 0, 0, 0.88)"

		if (plusItems?.length > 0) {
			// let occupyZone = drawText(ctx, "Plus:", currentX, currentY);
			// currentX += occupyZone.occupyWidth + 33;

			for (let i = 0; i < plusItems?.length; i++) {
				const currentItem = plusItems?.[i]
				occupyZone = drawText(ctx, currentItem?.fieldName, currentX, ctxPosition.current.currentY)
				drawPlusItem(
					`${currentItem?.description}`,
					+currentItem?.fieldVal,
					currentX + occupyZone.occupyWidth + 22
				)
				if (i !== otherExpenses?.length - 1) {
					ctxPosition.current.currentY += 5
				}
			}
		}
		ctx.restore()
	}

	const drawFreightRate = () => {
		const { descItem } = dataSource
		const ctx = canvasRef?.current?.getContext("2d")
		let currentX = ctxPosition.current.currentX,
			currentY = ctxPosition.current.currentY
		ctx.save()
		ctx.textAlign = "left"
		ctx.fillStyle = "rgba(0, 0, 0, 0.88)"
		ctx.font = "700 12px Arial"

		let occupyZone = drawText(ctx, "CARGO DETAILS", currentX, currentY)
	}

	const drawSecondLessBody = () => {
		const { otherItem } = dataSource
		const { lessItems } = resource?.current
		if (!otherItem?.addOther) return
		const ctx = canvasRef?.current?.getContext("2d")
		let currentX = ctxPosition.current.currentX,
			currentY = ctxPosition.current.currentY,
			occupyZone
		ctx.save()
		ctx.font = "700 12px Arial"
		ctx.textAlign = "left"
		ctx.fillStyle = "rgba(0, 0, 0, 0.88)"
		if (lessItems?.length > 0) {
			// let occupyZone = drawText(ctx, "Less:", currentX, currentY);
			// currentX += occupyZone?.occupyWidth + 31;
			for (let i = 0; i < lessItems?.length; i++) {
				const currentItem = lessItems?.[i]
				occupyZone = drawText(ctx, currentItem?.fieldName, currentX, ctxPosition.current.currentY)
				drawPlusItem(
					`${currentItem?.fieldName}  ${currentItem?.description}`,
					+currentItem?.fieldVal,
					currentX + occupyZone.occupyWidth + 22,
					"suffix"
				)
				if (i !== lessItems?.length - 1) {
					ctxPosition.current.currentY += 5
				}
			}
		}

		ctxPosition.current.currentY += 12

		ctx.restore()
	}

	const drawLess = () => {
		const { otherItem } = dataSource
		const ctx = canvasRef?.current?.getContext("2d")
		let currentX = ctxPosition.current.currentX,
			currentY = ctxPosition.current.currentY,
			occupyZone
		ctx.save()
		// ctx.textAlign = "left"
		// ctx.fillStyle = "rgba(0, 0, 0, 0.88)"
		// ctx.font = "700 12px Arial"
		// if (otherItem?.pastList?.length > 0) {
		// 	occupyZone = drawText(ctx, "Less", currentX, currentY)
		// }

		// for (let i = 0; i <= otherItem?.pastList?.length - 1; i++) {
		// 	ctx.font = "400 12px Arial"
		// 	occupyZone = drawText(
		// 		ctx,
		// 		`${otherItem?.pastList?.[i]?.period} Period Paid`,
		// 		currentX + 136,
		// 		currentY
		// 	)
		// 	drawSpecialText(
		// 		ctx,
		// 		canvasRef,
		// 		`${otherItem?.pastList?.[i]?.remitAmount}`,
		// 		"suffix",
		// 		currentY
		// 	)
		// 	currentY += occupyZone.occupyHeight + (i === otherItem?.pastList?.length - 1 ? 8.5 : 5)
		// }

		drawLine(ctx, currentX, currentY, canvasRef.current.width - currentX, currentY)

		currentY += 9 + 1

		ctx.restore()
		ctxPosition.current.currentY = currentY
	}

	const drawSummary = () => {
		const { summary } = dataSource
		const ctx = canvasRef?.current?.getContext("2d")
		let currentX = ctxPosition.current.currentX,
			currentY = ctxPosition.current.currentY
		ctx.save()
		ctx.textAlign = "left"
		ctx.fillStyle = "rgba(0, 0, 0, 0.88)"
		ctx.font = "700 12px Arial"
		const restPrice = formatThousandthNumber(summary)
		let occupyZone = drawSpecialText(ctx, canvasRef, `DUE TO US: ${restPrice}`, "suffix", currentY)
		currentY += occupyZone
		occupyZone = drawText(ctx, `PLS REMIT USD ${restPrice} INTO OUR FLWG ACCT`, currentX, currentY)

		currentY += occupyZone.occupyHeight + 214

		ctxPosition.current.currentY = currentY

		ctx.restore()
	}

	const drawInvoices = () => {
		const { baseItem, otherItem } = dataSource
		const ctx = canvasRef?.current?.getContext("2d")
		// drawHeader(ctx, [], beneficiary?.beneficiaryName, invDate, `INV.NO. ${invNo}`);
		drawHeader(ctx, [], otherItem?.companyName, baseItem?.invDate, `INV.NO. ${baseItem?.invNo}`)
		drawInvoiceType()
		drawInvHeader()
		drawDescBase()
		drawPort()
		drawCargo()
		drawFreight()
		drawPlus()
		drawSecondLessBody()
		drawLess()
		drawSummary()
		drawFooter(ctx, "freightInv", resource?.current?.ownerFields)
	}

	useEffect(() => {
		if (activeTab === "form") return
		const ctx = canvasRef?.current?.getContext("2d")
		const markCallback = markRefInit()
		ctx?.clearRect(0, 0, canvasRef.current?.width, canvasRef?.current?.height)
		initDataSource()
		drawInvoices()
		return markCallback
	}, [activeTab])

	return {
		markRef
	}
}

export default useIndex
