import { useCallback, useMemo, useRef, useState } from "react"
import {
	SeaReportTableDataItem,
	InitGroupType,
	SeaReportCategoryKeys
} from "../components/seaReportTable/type"
import { SeaReportTableHeaders, initGroup } from "../components/seaReportTable/source"
import {
	getMovementSeaReportsAction,
	saveMovementSeaReportsAction,
	deleteMovementSeaReportsAction,
	analysisMovementSeaReportsAction,
	downloadSeaReportAction,
	updateToMovementAction,
	analysisSeaReportAction
} from "@/action/voyageManage/seaReports"
import useReminder from "@/hook/useReminder"
import { useAppDispatch } from "@/hook"
import { useNavigate } from "react-router-dom"
import { MovementSeaReportsProps } from "../type"

const useSeaReports = (
	voyageId: MovementSeaReportsProps["voyageId"],
	voyageNo: string,
	onConfirm: MovementSeaReportsProps["onConfirm"]
) => {
	const [loading, setLoading] = useState(false)
	const [showDrop, setShowDrop] = useState(false)
	const [active, setActive] = useState<"Ballast" | "Laden">("Ballast")
	const uploadStatus = useRef<"uploading" | "static">("static")
	const tabList = [
		{ key: "Ballast", label: "Ballast" },
		{ key: "Laden", label: "Laden" }
	]
	const [isOpen, setIsOpen] = useState(false)
	const [selectRow, setSelectRow] = useState<{ check: boolean; idx: number }[]>()
	const [isAdd, setIsAdd] = useState(false)
	const [messageText, setMessageText] = useState("")
	const [showTextArea, setShowTextArea] = useState(false)
	const [checkGroup, setCheckGroup] = useState<InitGroupType>({ ...initGroup })
	const { reminder } = useReminder()
	const dispatch = useAppDispatch()
	const navigate = useNavigate()
	const [seaReportTableData, setSeaReportTableData] = useState<SeaReportTableDataItem[]>([])
	const handleChange = (val, index, prop) => {
		console.log(val, index, prop, "--------------")
		// debugger
		if (prop == "check") {
			const selected = selectRow.map((i, idx) => {
				return index === -1 ? { check: val, idx } : i
			})
			if (index != -1) {
				selected.splice(index, 1, { check: val, idx: index })
			}
			setSelectRow(selected)
		}
		if (index == -1) return
		const tableData = seaReportTableData.map((i) => {
			return i
		})
		tableData.splice(index, 1, { ...seaReportTableData[index], [prop]: val })
		setSeaReportTableData(tableData)
	}
	const newSeaReportTableRow = (val: string | number = 0) => {
		return {
			type: "EOSP",
			reportDateTime: null,
			positionLat: 0,
			positionLon: 0,
			course: 0,
			timeOfNextPort: null,
			seaTimeSteam: "",
			seaTimeStop: "",
			seaDistance: "",
			dailyAvgSpeed: "",
			windDir: "",
			windBF: "",
			seaDir: "",
			seaDSS: "",
			meRpm: "",
			tcRpm: "",
			meOutput: "",
			mePower: "",
			meLoadSlr: "",
			slip: "",
			meHfoSlr: "",
			meLsfoSlr: "",
			meMdoSlr: "",
			meMgoSlr: "",
			auxHfoSlr: "",
			auxLsfoSlr: "",
			auxMdoSlr: "",
			auxMgoSlr: "",
			brobHfo: "",
			brobLsfo: "",
			brobMdo: "",
			brobMgo: "",
			meRevolutionCounter: "",
			foFlowMeter: "",
			dtg: "",
			remarks: "",
			status: "Not",
			action: 0,
			isEdit: true,
			showPortName: false
		}
	}
	const handleAdd = () => {
		setIsAdd(true)
		setShowTextArea(true)
		setMessageText("")
		const tableData = seaReportTableData?.map((i) => {
			return i
		})
		tableData.push(newSeaReportTableRow())
		setSeaReportTableData(tableData)
		const selected = selectRow.map((i) => {
			return i
		})
		selected.push({ check: false, idx: selected.length })
		setSelectRow(selected)
	}
	const closeTextarea = () => {
		if (isAdd) {
			setIsAdd(false)
		}
		setShowTextArea(false)
	}
	const handledbClick = (index) => {
		if (!seaReportTableData[index].messageText) return
		setShowTextArea(true)
		setIsAdd(false)
		setMessageText(seaReportTableData[index].messageText)
	}
	const handleReset = useCallback(() => {
		setCheckGroup(initGroup)
	}, [])
	const headerChange = (key: SeaReportCategoryKeys, val: string[]) => {
		// console.log(key,val)
		if (key == "General" && val.length == 0) {
			val = ["type", "reportDateTime"]
		}
		setCheckGroup({
			...checkGroup,
			[key]: val
		})
	}
	const seaReportsApiError = (error) => {
		uploadStatus.current = "static"
		setLoading(false)
		reminder("error", error?.msg ? error?.msg + ": " + error?.data : error?.data)
	}
	const getMovementSeaReportsSuccess = (res) => {
		const selected = []
		const tableData = res.data.map((item, index) => {
			item.showPortName = item.type != "Noon Report"
			item.timeOfNextPort = item.timeOfNextPort || ""
			selected.push({ check: false, idx: index })
			return item
		})
		setSelectRow(selected)
		setSeaReportTableData(tableData)
		setLoading(false)
	}
	const getMovementSeaReports = () => {
		getMovementSeaReportsAction(
			{ id: voyageId, legType: active },
			() => {
				setLoading(true)
			},
			getMovementSeaReportsSuccess,
			seaReportsApiError,
			dispatch,
			navigate
		)
	}
	const handleRow = (index, type) => {
		if (type == "save" && !Boolean(seaReportTableData[index].reportDateTime)) {
			reminder("warning", "Please fill Date/Time(UTC)")
			return
		}
		if (["save", "cancel"].includes(type)) {
			handleChange(false, index, "isEdit")
			setShowTextArea(false)
		} else if (["edit"].includes(type)) {
			handleChange(true, index, "isEdit")
		}
		// return
		if (type == "save") {
			const vo = JSON.parse(JSON.stringify(seaReportTableData[index]))
			vo.loadingCond = active
			delete vo.isEdit
			delete vo.showPortName
			delete vo.showingDetail
			saveMovementSeaReportsAction(
				{ voyageId, vo },
				() => {
					setLoading(true)
				},
				() => {
					getMovementSeaReports()
					reminder("success", "Success")
					setLoading(false)
				},
				seaReportsApiError,
				dispatch,
				navigate
			)
		} else if (type == "delete") {
			if (!seaReportTableData[index].uuid) {
				const tableData = seaReportTableData?.map((i) => {
					return i
				})
				tableData.splice(index, 1)
				setSeaReportTableData(tableData)
				const selected = selectRow.map((i) => {
					return i
				})
				selected.splice(index, 1)
				setSelectRow(selected)
				return
			}
			deleteMovementSeaReportsAction(
				{
					voyageId,
					uuid: seaReportTableData[index]?.uuid || null,
					type: seaReportTableData[index].type
				},
				() => {
					setLoading(true)
				},
				() => {
					getMovementSeaReports()
					setLoading(false)
					reminder("success", "Success")
				},
				seaReportsApiError,
				dispatch,
				navigate
			)
		}
	}
	const identifyToAdd = (data) => {
		const vo = data.data
		const tableData = JSON.parse(JSON.stringify(seaReportTableData))
		const newRow = { ...tableData[tableData.length - 1], ...vo }
		newRow.positionLat = vo.positionLat.toFixed(2)
		newRow.positionLon = vo.positionLon.toFixed(2)
		tableData.splice(tableData.length - 1, 1, newRow)
		setSeaReportTableData(tableData)
		setLoading(false)
	}
	const analysisMovementSeaReports = () => {
		analysisMovementSeaReportsAction(
			{ voyageId, text: messageText },
			() => {
				setLoading(true)
			},
			identifyToAdd,
			seaReportsApiError,
			dispatch,
			navigate
		)
	}
	const downloadSeaReport = () => {
		const array = seaReportTableData
			.filter((e, index) => {
				return selectRow[index].check
			})
			.map((i) => {
				delete i.showPortName
				delete i.isEdit
				delete i.showingDetail
				return i
			})
		downloadSeaReportAction(
			array,
			() => {},
			(response) => {},
			seaReportsApiError,
			dispatch,
			navigate
		)
	}
	const updateToMovement = () => {
		if (!selectRow.some((ele) => ele.check)) {
			reminder("warning", "Please choose a report first")
			return
		}
		const vos = seaReportTableData
			.filter((ele, index) => {
				return selectRow[index].check && ele.uuid
			})
			.map((item) => {
				return {
					voyageId,
					uuid: item.uuid,
					type: item.type
				}
			})
		updateToMovementAction(
			{ voyageId, voyageNo, vos },
			() => {
				setLoading(true)
			},
			(res) => {
				setLoading(false)
				reminder("success", "Update Success")
				onConfirm("seaReports", res.data)
			},
			seaReportsApiError,
			dispatch,
			navigate
		)
	}
	const onUpload = (file) => {
		uploadStatus.current = "uploading"
		const formData = new FormData()
		formData.append("file", file)
		formData.append("voyageId", voyageId.toString())
		formData.append("stage", active)
		analysisSeaReportAction(
			formData,
			() => {
				setLoading(true)
			},
			() => {
				uploadStatus.current = "static"
				getMovementSeaReports()
			},
			seaReportsApiError,
			dispatch,
			navigate
		)
		return false
	}

	const downloadTemplate = () => {
		const link = document.createElement("a")
		link.href = "/static/seareport.xls"
		document.body.appendChild(link)
		link.click()
		document.body.removeChild(link)
	}
	return {
		loading,
		active,
		setActive,
		tabList,
		seaReportTableData,
		handleChange,
		isOpen,
		setIsOpen,
		handleAdd,
		isAdd,
		closeTextarea,
		showTextArea,
		handledbClick,
		checkGroup,
		headerChange,
		handleReset,
		getMovementSeaReports,
		handleRow,
		messageText,
		analysisMovementSeaReports,
		setMessageText,
		downloadSeaReport,
		downloadTemplate,
		selectRow,
		updateToMovement,
		uploadStatus,
		onUpload,
		showDrop,
		setShowDrop
	}
}
export default useSeaReports
