import React from "react"
import { MovementOffHireProps } from "./type"
import { FleetPrompt } from "@/common"
import { FleetFormItem, FleetInput, FleetLoading } from "@/components"
import { Icon } from "@/_components"
import useIndex from "./hooks/useIndex"
import FleetTabs from "@/components/Tabs"
import { tctcTabs } from "./source"
import { getInvPeriod } from "@/tools/inv"
import { formatThousandthNumber } from "@/tools/amount"
import { cn } from "@/tools"

const MovementOffHire: React.FC<MovementOffHireProps> = ({
	hide,
	onClose,
	voyageId,
	voyageType
}) => {
	const {
		selectRows,
		dataSource,
		loading,
		activeTctcType,
		handleTabSelect,
		handleDownload,
		handleRowSelect
	} = useIndex(voyageId, voyageType, hide)

	return (
		<FleetPrompt hide={hide} mode="fixed" containerClassname="h-[80%]">
			<div className="w-[1129px] px-[20px] py-[24px] flex flex-col rounded-[6px] bg-[#fff] gap-[16px] h-full">
				<FleetLoading loading={loading} />
				<div className="w-full flex items-center justify-between">
					<span className="fleet-title">Off-Hire Record</span>
					<Icon type="fleet-close" size={16} className=" cursor-pointer" onClick={onClose} />
				</div>
				{/* <FleetTabs
					list={tctcTabs}
					onClick={handleTabSelect}
					value={activeTctcType}
					className="!h-[38px]"
				/> */}
				{voyageType === "TCTC" && (
					<FleetTabs
						list={tctcTabs}
						onClick={handleTabSelect}
						value={activeTctcType}
						className="!h-[38px]"
					/>
				)}
				<div
					className={cn(
						"w-full overflow-y-auto overflow-x-hidden h-[calc(100%-44px)] fleet-nonScrolBar",
						{
							"h-[calc(100%-98px]": voyageType === "TCTC"
						}
					)}
				>
					<div
						className={cn("w-full flex flex-col gap-[12px]", {
							"h-full items-center justify-center": dataSource?.length === 0
						})}
					>
						{dataSource?.length <= 0 && (
							<span className="text-[16px] text-gray-400 font-medium">
								No off-hire records found.
							</span>
						)}
						{dataSource?.map((item, index) => {
							return (
								<div key={index} className="flex flex-col w-full ">
									<div className="rounded-tl-[4px] rounded-tr-[4px] w-full py-[15px] px-[24px] flex justify-between border border-solid border-[#0000001a] border">
										<div className="flex gap-[16px] items-center">
											<span className="text-[14px] font-semibold leading-[24px] text-[#000000e0]">{`${item?.period} ${getInvPeriod(item?.period)} Statement of Hire`}</span>
											<span className="text-[12px] font-normal leading-[20px] text-[#A3A3A3]">
												INV. No.: {item?.invNo}
											</span>
										</div>
										<div className="flex items-center gap-[12px] text-[12px] leading-[22px] font-normal">
											<span className="text-[#A3A3A3]">Attachments:</span>
											<span className="text-[#036BC0]">
												{" "}
												{item?.period} {getInvPeriod(item?.period)} Statement of Hire.xml
											</span>
											<Icon
												size={14}
												type="fleet-download"
												color="#036BC0"
												className="cursor-pointer"
												onClick={() => {
													handleDownload(item?.invId)
												}}
											/>
											<Icon
												size={14}
												type="fleet-arrow_right"
												className={cn("cursor-pointer rotate-180", {
													"rotate-90": selectRows?.includes(item?.invId)
												})}
												onClick={() => {
													handleRowSelect(item?.invId)
												}}
											/>
										</div>
									</div>
									<div
										className={cn(
											"p-[24px] w-full hidden flex-col gap-[24px] bg-[#FBFBFB]  border border-solid border-[#0000001a] border-t-0 rounded-bl-[4px] rounded-br-[4px]",
											{ flex: selectRows?.includes(item?.invId) }
										)}
									>
										{item?.offHireGroupList?.map((offHireItem, offHireIndex) => {
											const summary =
												offHireItem?.offHireAmount +
												offHireItem?.cve -
												offHireItem?.addComm -
												offHireItem?.hireBrokerage +
												offHireItem?.bunkerPriceList?.reduce(
													(prev, curr) => prev + curr?.total,
													0
												) +
												offHireItem?.otherCalDetailList?.reduce(
													(prev, curr) => prev + +curr?.totalAmount,
													0
												)
											return (
												<div
													key={offHireItem?.id}
													className="w-full p-[24px] rounded-[4px] bg-[#fff] border-solid border border-[#0000001a] relative flex  flex-col gap-[17px]"
												>
													<span className="text-[12px] text-[#fff] font-normal leading-[24px] inline-block px-[10px] bg-[#036BC0] w-fit rounded-full absolute -top-[12px]">
														Off-Hire Party {offHireIndex + 1}
													</span>
													<div className="w-full flex flex-col gap-[4px]">
														<span className="text-[#151515] text-[12px] leading-[24px] font-semibold">
															Time Calculation Details
														</span>
														<div className="w-full grid grid-cols-[1fr_1fr_1fr_1fr_1fr] gap-[17px] grid-rows-[56px]">
															<FleetFormItem
																labelClassname="text-[#686868]"
																label="Off- Hire TTL Days"
															>
																<FleetInput disabled value={offHireItem?.offHireTtlDays} />
															</FleetFormItem>
															<FleetFormItem
																labelClassname="text-[#686868]"
																label="TTL Off-Hire Amount"
															>
																<FleetInput disabled value={offHireItem?.offHireAmount} />
															</FleetFormItem>
															<FleetFormItem
																labelClassname="text-[#686868]"
																label="Off- Hire Add.Comm. ($)"
															>
																<FleetInput disabled value={offHireItem?.addComm} />
															</FleetFormItem>
															{/* <FleetFormItem
																labelClassname="text-[#686868]"
																label="Off- Hire Brokerage ($)"
															>
																<FleetInput disabled value={offHireItem?.brokerage} />
															</FleetFormItem> */}
															<FleetFormItem
																labelClassname="text-[#686868]"
																label="Off- Hire C/V/E Amount ($)"
															>
																<FleetInput disabled value={offHireItem?.cve} />
															</FleetFormItem>
															<FleetFormItem
																labelClassname="text-[#686868]"
																label="Off- Hire Brokerage ($)"
															>
																<FleetInput disabled value={offHireItem?.hireBrokerage} />
															</FleetFormItem>
														</div>
													</div>
													<div className="w-full flex flex-col gap-[4px]">
														<span className="text-[#151515] text-[12px] leading-[24px] font-semibold">
															Bunker Calculation Details
														</span>
														<div className="w-full flex flex-col border border-solid border-[#E7E9EC] rounded-[4px]">
															<ul className="grid grid-cols-[1fr_1fr_1fr_1fr] w-full grid-rows-[34px] bg-[#F8F8F9]">
																<li className="py-[7px] relative pl-[16px] pr-[12px] leading-[20px] text-[#151515] font-normal text-[12px] flex items-center after:absolute after:right-0 after:context-[''] after:h-[20px] after:w-[1px] after:inline-block after:bg-[#E7E9EC]">
																	Bunker Type
																</li>
																<li className="py-[7px] relative pl-[16px] pr-[12px] leading-[20px] text-[#151515] font-normal text-[12px] flex items-center after:absolute after:right-0 after:context-[''] after:h-[20px] after:w-[1px] after:inline-block after:bg-[#E7E9EC]">
																	Off-Hire Consumption
																</li>
																<li className="py-[7px] relative pl-[16px] pr-[12px] leading-[20px] text-[#151515] font-normal text-[12px] flex items-center after:absolute after:right-0 after:context-[''] after:h-[20px] after:w-[1px] after:inline-block after:bg-[#E7E9EC]">
																	Bunker Price
																</li>
																<li className="py-[7px] pl-[16px] pr-[12px] leading-[20px] text-[#151515] font-normal text-[12px]">
																	Total Value
																</li>
															</ul>
															{offHireItem?.bunkerPriceList?.map((bunkerItem) => {
																return (
																	<ul className="grid grid-cols-[1fr_1fr_1fr_1fr] w-full border-b border-solid border-[#E7E9EC] last:border-b-0">
																		<li className="py-[7px] pl-[16px] pr-[12px] leading-[32px] text-[#151515] font-normal text-[12px]">
																			{bunkerItem?.bunkerType}
																		</li>
																		<li className="py-[7px] pl-[16px] pr-[12px] leading-[32px] text-[#151515] font-normal text-[12px]">
																			{formatThousandthNumber(+bunkerItem?.consumption)}
																		</li>
																		<li className="py-[7px] pl-[16px] pr-[12px] leading-[32px] text-[#151515] font-normal text-[12px]">
																			{formatThousandthNumber(+bunkerItem?.price)}
																		</li>
																		<li className="py-[7px] pl-[16px] pr-[12px] leading-[32px] text-[#151515] font-normal text-[12px]">
																			{formatThousandthNumber(+bunkerItem?.total)}
																		</li>
																	</ul>
																)
															})}
														</div>
													</div>
													<div className="w-full flex flex-col gap-[4px]">
														<span className="text-[#151515] text-[12px] leading-[24px] font-semibold">
															Other Calculation Details
														</span>
														<div className="w-full flex flex-col border border-solid border-[#E7E9EC] rounded-[4px]">
															<ul className="grid grid-cols-[238px_178px_1fr] w-full grid-rows-[34px] bg-[#F8F8F9]">
																<li className="py-[7px] relative pl-[16px] pr-[12px] leading-[20px] text-[#151515] font-normal text-[12px] flex items-center after:absolute after:right-0 after:context-[''] after:h-[20px] after:w-[1px] after:inline-block after:bg-[#E7E9EC]">
																	Title
																</li>
																<li className="py-[7px] relative pl-[16px] pr-[12px] leading-[20px] text-[#151515] font-normal text-[12px] flex items-center after:absolute after:right-0 after:context-[''] after:h-[20px] after:w-[1px] after:inline-block after:bg-[#E7E9EC]">
																	Total Amount ($)
																</li>
																<li className="py-[7px] relative pl-[16px] pr-[12px] leading-[20px] text-[#151515] font-normal text-[12px] flex items-center after:absolute after:right-0 after:context-[''] after:h-[20px] after:w-[1px] after:inline-block after:bg-[#E7E9EC]">
																	Remarks
																</li>
															</ul>
															{offHireItem?.otherCalDetailList?.map((bunkerItem) => {
																return (
																	<ul className="grid grid-cols-[238px_178px_1fr] w-full border-b border-solid border-[#E7E9EC] last:border-b-0">
																		<li className="py-[7px] pl-[16px] pr-[12px] leading-[32px] text-[#151515] font-normal text-[12px]">
																			{bunkerItem?.title}
																		</li>
																		<li className="py-[7px] pl-[16px] pr-[12px] leading-[32px] text-[#151515] font-normal text-[12px]">
																			{formatThousandthNumber(+bunkerItem?.totalAmount)}
																		</li>
																		<li className="py-[7px] pl-[16px] pr-[12px] leading-[32px] text-[#151515] font-normal text-[12px]">
																			{bunkerItem?.remark}
																		</li>
																	</ul>
																)
															})}
														</div>
													</div>
													<div className="w-full px-[16px] flex gap-[16px] justify-end items-center rounded-[4px] bg-[#036bc00a] text-[#036BC0] text-[14px] font-medium leading-[32px]">
														<span>Total</span>
														<span>{formatThousandthNumber(summary)}</span>
													</div>
												</div>
											)
										})}
									</div>
								</div>
							)
						})}
					</div>
				</div>
			</div>
		</FleetPrompt>
	)
}

export default MovementOffHire
