import React, { FC } from "react"
import style from "./index.module.less"
import { MovementPlanListProps } from "./type"
import { FleetButton, FleetUpload } from "@/components"
import { Button, Popover } from "@/_components"
import { FleetToast } from "@/common"

const MovementPlanList: FC<MovementPlanListProps> = ({
	planItems,
	activeType,
	activePlanIndex,
	onPlanAdd,
	onPlanUpload,
	onPlanSave,
	onPlanSelect,
	onPlanSet
}) => {
	const handlePlanSave = (
		event,
		currentItem: Parameters<MovementPlanListProps["onPlanSave"]>[0]
	) => {
		event?.stopPropagation()
		onPlanSave?.(currentItem)
	}

	const handlePlanSet = (
		event,
		currentIndex: Parameters<MovementPlanListProps["onPlanSet"]>[0],
		currentItem: Parameters<MovementPlanListProps["onPlanSet"]>[1]
	) => {
		event?.stopPropagation()
		onPlanSet?.(currentIndex, currentItem)
	}

	const downloadTemplate = () => {
		const link = document.createElement("a")
		link.href = "/static/estplan template v2.xlsx"
		document.body.appendChild(link)
		link.click()
		document.body.removeChild(link)
	}

	return (
		<div className={style["movement-charterBase--planList"]}>
			<Popover
				disabled={activeType === "actual"}
				buttonProps={{
					type: activeType === "actual" ? "primary" : "secondary",
					size: "small",
					onClick: () => {
						if (activeType === "actual") {
							onPlanSelect?.("actual", activePlanIndex, planItems?.[activePlanIndex], null)
						}
					},
					children: "Actual"
				}}
				render={(setIsOpen) => {
					return (
						<FleetToast
							label="If switching tabs, data may be lost. Are you sure?"
							onCancel={() => {
								setIsOpen(false)
							}}
							onConfirm={() => {
								onPlanSelect?.("actual", activePlanIndex, planItems?.[activePlanIndex], null)
								setIsOpen(false)
							}}
						/>
					)
				}}
			/>
			<span className="planList-lines"></span>
			<div className={`planList-estimation ${activeType == "actual" ? "" : "estActive"} `}>
				<span className="label">Estimated plan : </span>
				<div className="planList-estimation--list">
					<div className="planList-estimation--scrollArea">
						{planItems?.map((planItem, index) => {
							if (activePlanIndex === index) {
								return (
									<div className="est-active" key={index}>
										<span>{planItem?.planName}</span>
										<span
											onClick={(e) => {
												handlePlanSave(e, planItem)
											}}
										>
											Save
										</span>
										{planItem?.id && !planItem?.main && (
											<span
												onClick={(e) => {
													handlePlanSet(e, index, planItem)
												}}
											>
												Set main plan
											</span>
										)}
									</div>
								)
							}
							return (
								<Popover
									children={
										<Button
											type="secondary"
											className="est--notActive"
											size="small"
											key={index}
											// onClick={() => {
											//   onPlanSelect?.("est", activePlanIndex ?? index, planItems?.[activePlanIndex] ?? planItem, index)
											// }}
										>
											{planItem?.planName}
										</Button>
									}
									render={(setIsOpen) => {
										return (
											<FleetToast
												label="If switching tabs, data may be lost. Are you sure?"
												onCancel={() => {
													setIsOpen(false)
												}}
												onConfirm={() => {
													onPlanSelect?.(
														"est",
														activePlanIndex ?? index,
														planItems?.[activePlanIndex] ?? planItem,
														index
													)
													setIsOpen(false)
												}}
											/>
										)
									}}
								/>
							)
						})}
					</div>
				</div>
			</div>

			{activeType === "est" && (
				<span style={{ display: "flex", gap: 12 }}>
					<Button
						type="secondary"
						size="small"
						onClick={downloadTemplate}
						prefixIcon="fleet-a-Labprofile"
					>
						Template
					</Button>{" "}
					<FleetUpload
						className="!w-auto"
						showFileList={false}
						onUpload={onPlanUpload}
						// uploadStatus={uploadStatus}
						// loading={loading}
						buttonText="Analysis Excel"
					/>
				</span>
			)}
			<Button type="secondary" size="small" onClick={onPlanAdd} prefixIcon="fleet-add">
				Copy and add new
			</Button>
		</div>
	)
}

export default MovementPlanList
