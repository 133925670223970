import React from "react"
import classnames from "classnames"
import style from "./index.module.less"
import { InvoicesItemProps } from "./type"
import { dateFormat } from "@/tools/date"
import { getInvPeriod } from "@/tools/inv"
import { Tooltip } from "@/_components"
import { CommonRoleWrap, FleetToast } from "@/common"
import { Popover } from "@/_components"

const InvoicesItem: React.FC<InvoicesItemProps> = ({
	lastItem,
	item,
	currentIndex,
	activeIndex,
	onSelect,
	onDelect
}) => {
	return (
		<div
			onClick={() => onSelect?.(currentIndex, false)}
			className={classnames(style["invoices-item"], {
				[style["invoices-item-active"]]: currentIndex === activeIndex
			})}
		>
			<div className="invoicesItem-header">
				<span className="invoicesItem-period">
					#&nbsp;{item?.period}&nbsp;{getInvPeriod(item?.period)}
				</span>
				<span>
					{item?.invFinal && <span className="invoicesItem-tag final">Final</span>}
					{item?.status === "Settled" && <span className="invoicesItem-tag">Settled</span>}
					{item?.status !== "Settled" && lastItem && currentIndex !== 0 && (
						<CommonRoleWrap roleStr="movement:list:delete">
							<Popover
								render={(setIsOpen) => {
									return (
										<FleetToast
											label="Are you sure delete this item?"
											onCancel={() => {
												setIsOpen(false)
											}}
											onConfirm={() => {
												onDelect?.(item.id)
												setIsOpen(false)
											}}
										/>
									)
								}}
							>
								<span
									className="font_family fleet-delete"
									style={{ color: "red", marginLeft: "5px" }}
								></span>
							</Popover>
						</CommonRoleWrap>
					)}
				</span>
			</div>
			<ul className="invoicesItem-info">
				<li>INV. No.</li>
				<Tooltip
					title={item?.invNo}
					overlayClassName="flex-1 overflow-hidden text-ellipsis whitespace-nowrap"
					children={<li>{item?.invNo ?? "-"}</li>}
					placement="top"
				/>
			</ul>
			<ul className="invoicesItem-info">
				<li>Create Date</li>
				<li>{dateFormat("YY-mm-dd", item?.createTime)}</li>
			</ul>
		</div>
	)
}

export default InvoicesItem
