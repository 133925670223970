import { useMemo, useState } from "react"
import { MovementPlanItemType, MovementPlanListProps } from "../type"
import useReminder from "@/hook/useReminder"
import { useAppDispatch } from "@/hook"
import { useNavigate } from "react-router-dom"
import { getMovementPlanDetailsAction, uploadEstPlanAction } from "@/action/voyageManage/movement"
import { EstCompareItemType } from "@/pages/estimation/business/list/module"
import { getEsCompareAction } from "@/action/estimation"
import { initialPlanItem } from "../source"

const useIndex = (
	voyageId: number,
	setLoading: (val: boolean) => void,
	onEstInit: (item: EstCompareItemType) => void,
	onPlanSave: (
		item: MovementPlanItemType,
		callback: (newPlanItem: MovementPlanItemType) => void
	) => void
) => {
	const dispatch = useAppDispatch()
	const navigate = useNavigate()
	const { reminder } = useReminder()
	const [activePlanType, setActivePlanType] = useState<"actual" | "est">("actual")
	const [activePlanIndex, setActivePlanIndex] = useState<number>(null)
	const [dataSource, setDataSource] = useState<MovementPlanListProps["planItems"]>([])

	const activePlanItem = useMemo(() => {
		return dataSource?.[activePlanIndex]
	}, [activePlanIndex, dataSource])

	const select: MovementPlanListProps["onPlanSelect"] = (
		type,
		prevIndex,
		prevItem,
		currentIndex
	) => {
		debugger
		switch (true) {
			case type === "actual" && activePlanType === "actual":
			case type === "est" && activePlanIndex === currentIndex:
				break
			case type === "actual":
				// onPlanSave?.(prevItem, (newPlanItem) => {
				//   if (!prevItem?.id) {
				//     commit(prevIndex, newPlanItem)
				//   }
				//   setActivePlanIndex(null)
				//   setActivePlanType(type);
				// })
				setActivePlanIndex(null)
				setActivePlanType(type)
				break
			case type === "est":
				// if(prevIndex !== currentIndex) {
				//   onPlanSave?.(prevItem, (newPlanItem) => {
				//     if (!prevItem?.id) {
				//       commit(prevIndex, newPlanItem)
				//     }
				//     setActivePlanIndex(currentIndex)
				//     setActivePlanType(type);
				//     getEsCompare(dataSource?.[currentIndex]?.estResultId)
				//   })
				//   return
				// }
				const mainItem = dataSource?.find((item) => item?.main)
				setActivePlanIndex(currentIndex)
				setActivePlanType(type)
				getEsCompare(dataSource?.[currentIndex]?.estResultId ?? mainItem?.estResultId)
				break
			default:
				break
		}
	}

	const commit = (currentIndex: number, item: MovementPlanItemType) => {
		setDataSource((prev) => {
			const currentItem = prev?.[currentIndex]
			prev?.splice(currentIndex, 1, { ...currentItem, ...item })
			return [...prev]
		})
	}

	const handlePlanSet = (currentIndex: number) => {
		setDataSource((prev) => {
			const newItems = prev?.map((item, index) => ({ ...item, main: currentIndex === index }))
			return [...newItems]
		})
	}

	const add = () => {
		const isNotSave = dataSource?.find((item) => !item?.estResultId || !item?.id)
		if (dataSource?.length > 0 && !!isNotSave) {
			reminder("warning", "Please save the previous unsaved plan first")
			return
		}
		const mainItem = dataSource?.find((item) => !!item?.main)
		getEsCompare(mainItem?.estResultId, () => {
			const newPlanItem = initialPlanItem({
				id: null,
				estResultId: null,
				vmId: voyageId,
				main: false,
				planName: `plan${dataSource.length + 1}`
			})
			setDataSource((prev) => {
				return [...prev, { ...newPlanItem }]
			})

			setActivePlanType("est")
			setActivePlanIndex(dataSource?.length)
		})
	}

	const upload = (file) => {
		// uploadStatus.current = "uploading"
		const formData = new FormData()
		formData.append("file", file)
		uploadEstPlanAction(
			formData,
			() => {
				setLoading(true)
			},
			(response) => {
				reminder("success", "Upload Success!")
				;["contFo", "conDo", "seaDays", "seaFo", "seaMdo", "totalDays"].forEach((item) => {
					response?.data?.portRotationList?.forEach((pitem) => {
						pitem[item] = Math.round(pitem[item] * 1000) / 1000
					})
				})
				onEstInit(response?.data ?? {})
				setLoading(false)
			},
			uploadApiError,
			dispatch,
			navigate
		)
	}

	const uploadApiError = (error) => {
		setLoading(false)
		reminder("error", error?.msg ? error?.msg + ": " + error?.data : error?.data)
	}

	const init = (items: MovementPlanListProps["planItems"]) => {
		setDataSource(items)
	}

	const getEsCompare = (estResultId: number, callback?: () => void) => {
		if (dataSource?.length <= 0) {
			getMovementPlanDetailsAction(
				{ voyageId: voyageId },
				getEsCompareFront,
				(response) => {
					onEstInit(response?.data?.[0] ?? {})
					callback?.()
					setLoading(false)
				},
				getEsCompareError,
				dispatch,
				navigate
			)
			return
		}
		getInitEsCompare(estResultId, callback)
	}
	const getEsCompareFront = () => {
		setLoading(true)
	}

	const getEsCompareError = (error) => {
		reminder("error", error.msg)
		setLoading(false)
	}

	const getInitEsCompare = (estResultId: number, callback: () => void) => {
		if (!estResultId) return
		getEsCompareAction(
			{ ids: estResultId, isReserveDeliveryPort: true },
			getEsCompareFront,
			(response) => {
				onEstInit(response?.data?.[0] ?? {})
				callback?.()
				setLoading(false)
			},
			getEsCompareError,
			dispatch,
			navigate
		)
	}

	return {
		dataSource,
		activePlanIndex,
		activePlanType,
		activePlanItem,
		select,
		add,
		upload,
		init,
		commit,
		handlePlanSet
	}
}

export default useIndex
