import useCommonIndex from "../../../hooks/useIndex"
import { CommonPortItemType } from "../type"
import { useMemo, useState } from "react"
import debounce from "lodash/debounce"
import { queryPortGroupAction } from "@/action/estimation"

const useIndex = (
	activeItems: Partial<CommonPortItemType> | Partial<CommonPortItemType>[],
	showKey: keyof CommonPortItemType,
	onFinish: (newItem: CommonPortItemType, operateType: "add" | "del") => void,
	primaryKey: keyof CommonPortItemType = "id",
	disabled?: boolean
) => {
	const {
		loading,
		open,
		list,
		searchValue,
		selectValue,
		page,
		setOpen,
		setList,
		focusPopup,
		loadMore,
		handleItemSelect,
		tagRender
	} = useCommonIndex<CommonPortItemType, keyof CommonPortItemType>(
		activeItems,
		showKey,
		onFinish,
		queryPortGroupAction,
		primaryKey,
		disabled
	)

	const [status, setStatus] = useState<"num" | "str">("str")

	const closePopup = (open: boolean) => {
		if (!open) {
			// setSearchVal("");
			page.current.pageNum = 1
			setStatus("str")
		}
		setOpen(open)
	}

	const handleSearch = useMemo(() => {
		if (status === "num") {
			return (val: string) => {
				// setSearchVal(val)
			}
		}

		const loadOptions = (val: string) => {
			page.current.pageNum = 1
			setList([])
			focusPopup(false, val)
		}
		return debounce(loadOptions, 800)
	}, [focusPopup, status])

	const handleEnter = (e) => {
		if (/^\d{1,}$/.test(e.key)) {
			setStatus("num")
		} else {
			setStatus("str")
		}
	}

	const handleLatitudeClose = (portCode: string) => {
		console.log("portCode", portCode)
		handleItemSelect?.({
			portCode: "(" + portCode + ")",
			portName: portCode
		} as any)
		setOpen(false)
		setStatus("str")
	}

	const handleChange = (value: string) => {
		let isDelete = false
		document.addEventListener("keydown", (event) => {
			if (event.key === "Backspace" || event.key === "Delete") {
				isDelete = true
			}
		})
		setTimeout(() => {
			if (isDelete) return
			onFinish?.(
				{
					portName: `${value[value.length - 1]}`,
					portCode: "",
					portNameCn: "",
					lon: 0,
					lat: 0,
					countryCode: "",
					country: "",
					timeZone: "",
					alias: "",
					portType: "L",
					draftLimitation: 0,
					updateTime: 0,
					updateUser: 0,
					createTime: 0,
					createUser: 0
				},
				"add"
			)
		}, 100)
	}

	return {
		loading,
		open,
		list,
		searchValue,
		selectValue,
		status,
		page,
		handleSearch,
		handleChange,
		focusPopup,
		closePopup,
		loadMore,
		handleItemSelect,
		handleLatitudeClose,
		handleEnter,
		tagRender
	}
}

export default useIndex
