import { getRetainDecimalsNumber } from "@/tools/amount"
import { FleetNumType } from "@/types/common"

export const getCveAmount = (params: {
	ttlDay: number
	cveRatio: FleetNumType
	unit: "PM" | "PY" | "PD"
}) => {
	const { ttlDay, cveRatio, unit } = params

	if (unit === "PM") {
		return getRetainDecimalsNumber((ttlDay / 365) * 12 * +cveRatio, 3)
	}
	if (unit === "PY") {
		return getRetainDecimalsNumber((ttlDay / 365) * +cveRatio, 3)
	}
	if (unit === "PD") {
		return getRetainDecimalsNumber(+cveRatio * ttlDay, 3)
	}
}
