import React, { useMemo, useRef } from "react"
import style from "./index.module.less"
import {
	FleetDatePicker,
	FleetFormItem,
	FleetIcon,
	FleetInput,
	FleetRangePicker
} from "@/components"
import { MovementCalculatorProps } from "./type"
import dayjs, { Dayjs } from "dayjs"
import { FleetTimeRangePicker } from "@/components/DatePicker"
import useReminder from "@/hook/useReminder"
import { FleetCustomRadio } from "@/components/Radio"
import { formatThousandthNumber } from "@/tools/amount"
import { Icon, Popover } from "@/_components"
import classNames from "classnames"

const Calculator: React.FC<MovementCalculatorProps> = ({
	onRangeDateChange,
	onExpectDateChange,
	rangeDate,
	activeIndex,
	items,
	summary,
	exceptDate,
	onExpectDateCommit,
	onChange,
	norDate,
	onNorDateChange,
	onExpectDateCrud
}) => {
	const { reminder } = useReminder()
	const handleRangeDateChange = (vals: [Dayjs, Dayjs]) => {
		onRangeDateChange?.(activeIndex, vals)
	}

	console.log("summary", {
		summary,
		exceptDate,
		items,
		rangeDate
	})

	const totalExceptDate = useMemo(() => {
		return exceptDate
			?.filter?.((item) => item?.isSelect === 1)
			?.reduce((prev, curr) => prev + (curr?.endTime - curr?.startTime) / 1000 / 60 / 60 / 24, 0)
	}, [exceptDate])

	const warningDate = useMemo(() => {
		let currentTime = 0,
			expireTime = 0
		for (let item of items) {
			const diffTime = +item?.workTo - +item?.workFrom
			expireTime += diffTime
			if (item?.status === 0) continue
			if (currentTime + diffTime > +summary?.allowed * 86400000) {
				return {
					expireItem: item,
					expireTime: expireTime - diffTime + +summary?.allowed * 86400000 - currentTime
				}
			}
			currentTime += diffTime
		}
		return null
	}, [items, summary])

	return (
		<div className={style["movement-calculator"]}>
			<div className="calculator-header">
				<span className="fleet-title">Laytime Calculator</span>
				<div className="header-timeContainer">
					<FleetFormItem type="row" label="NOR Tendered(LT)">
						<FleetDatePicker
							onChange={(value, timeStamp) => {
								onNorDateChange?.(0, timeStamp)
							}}
							showTime={true}
							format="YYYY-MM-DD HH:mm"
							disabled={!activeIndex}
							showHour
							showMinute
							showSecond={false}
							value={norDate?.[0] ? norDate?.[0] : null}
						/>
					</FleetFormItem>
					<FleetFormItem type="row" label="NOR Accepted(LT)">
						<FleetDatePicker
							onChange={(value, timeStamp) => {
								onNorDateChange?.(1, timeStamp)
							}}
							showTime={true}
							format="YYYY-MM-DD HH:mm"
							disabled={!activeIndex}
							showHour
							showMinute
							showSecond={false}
							value={norDate?.[1] ? norDate?.[1] : null}
						/>
					</FleetFormItem>
					<FleetFormItem type="row" label="Commence(LT)">
						<FleetRangePicker
							onChange={handleRangeDateChange}
							showTime={true}
							format="YYYY-MM-DD HH:mm"
							disabled={!activeIndex}
							showHour
							showMinute
							showSecond={false}
							value={rangeDate}
							// disabledDate={(current) => {
							//   if (rangeDate?.[0]) {
							//     return current > dayjs(rangeDate?.[0]).add(1, "month") || current <= rangeDate?.[0]
							//   }
							//   if (rangeDate?.[1]) {
							//     return current < rangeDate?.[1] && current > dayjs(rangeDate?.[1]).subtract(1, "month");
							//   }
							//   return current > dayjs().add(1, "month") || current < dayjs().subtract(1, "month")
							// }}
						/>
					</FleetFormItem>
				</div>
			</div>
			<div className="calculator-container">
				<div className="calculatorArea calculator-leftArea">
					<div className="calculatorArea-header">
						<div className="calculatorItem">
							<span>Date</span>
							<span>Exception Time</span>
							<span>(Y-M-D)</span>
							<span>From-To</span>
						</div>
						<div className="calculatorItem-actions"></div>
					</div>
					<div className="calculatorArea-container fleet-nonScrolBar">
						<div className="calculatorArea-scrollArea">
							{exceptDate?.map((item, idx) => {
								const baseDate = item?.date ? dayjs(item?.date) : null
								const workFrom =
									item?.date && (item?.startTime || item?.startTime === 0) && item?.endTime
										? baseDate.add(+item?.startTime, "milliseconds")
										: null
								const workTo =
									item?.date && item?.endTime
										? baseDate.add(
												+item?.endTime === 86400000 ? 86399000 : +item?.endTime,
												"milliseconds"
											)
										: null
								return (
									<div key={idx} className="calculatorItem calculatorItem-expect">
										<FleetCustomRadio
											className="calculatorItem-select"
											value={!!item?.isSelect}
											onChange={(val) => {
												onExpectDateChange?.(idx, "isSelect", +val as 0 | 1)
											}}
										/>
										<div>
											<FleetDatePicker
												minDate={rangeDate[0]}
												maxDate={rangeDate[1]}
												className="input-item"
												value={baseDate}
												onChange={(val, timeStramp) => {
													onExpectDateChange?.(
														idx,
														"date",
														timeStramp?.set("hour", 0).set("minute", 0).set("second", 0)
													)
												}}
											/>
										</div>
										<div>
											<FleetTimeRangePicker
												value={[workFrom, workTo]}
												// format="kk:mm"
												format="HH:mm"
												showSecond
												className="input-item"
												onChange={(vals) => {
													console.log("vals", vals, {
														hour: vals?.[1]?.hour(),
														minute: vals?.[1]?.minute(),
														second: vals?.[1]?.second()
													})
													if (!baseDate) {
														reminder("warning", "Please select date")
														return
													}
													const startTime =
														(vals?.[0]?.hour() * 60 + vals?.[0]?.minute()) * 60 * 1000
													const endTime =
														vals?.[1]?.hour() === 23 &&
														vals?.[1]?.minute() === 59 &&
														vals?.[1]?.second() === 59
															? 86400000
															: (vals?.[1]?.hour() * 60 + vals?.[1]?.minute()) * 60 * 1000
													console.log("stra", startTime, endTime)
													if (startTime >= endTime) {
														reminder("warning", "StartTime must be less than endTime")
														return
													}
													onExpectDateCommit?.(
														idx,
														{
															startTime: startTime,
															endTime: endTime
														},
														item
													)
													setTimeout(() => {
														const remarkInput = document?.getElementById(
															`except--${item?.date?.format("YYYY-MM-DD")}--${startTime}--${endTime}`
														)
														if (remarkInput) {
															;(
																remarkInput?.firstElementChild
																	?.firstElementChild as HTMLInputElement
															)?.focus()
														}
													}, 100)
												}}
											/>
										</div>
										<div className="calculatorItem-actions">
											<FleetIcon
												type="FleetPortAddIcon"
												onClick={() => onExpectDateCrud("add", idx)}
											/>
											<FleetIcon
												type="FleetPortDelIcon"
												onClick={() => onExpectDateCrud("delete", idx)}
											/>
										</div>
									</div>
								)
							})}
						</div>
					</div>
				</div>
				<div className="calculatorArea calculator-rightArea">
					<div className="calculatorArea-header">
						<div className="calculatorItem-row">
							<div className="calculatorItem">
								<span>Date</span>
								<span>Time List</span>
								<span>Time Used</span>
							</div>
							<div className="calculatorItem">
								<span>(Y-M-D)</span>
								<span>W</span>
								<span>From-To</span>
								<span>Rate</span>
								<span>D</span>
								<span>H</span>
								<span>M</span>
							</div>
						</div>
						<div className="calculatorItem-remark">Remarks</div>
					</div>
					<div className="calculatorArea-container fleet-nonScrolBar">
						<div className="calculatorArea-scrollArea">
							{items?.map((item, idx) => {
								const baseDate = dayjs(item?.workDate)
								const workFrom = baseDate.add(+item?.workFrom, "milliseconds")
								const workTo = baseDate.add(+item?.workTo, "milliseconds")
								return (
									<div
										key={idx}
										className={classNames("calculatorItem", {
											"is-except": item?.status === 0
										})}
									>
										<div className="flex gap-2 !justify-end">
											{warningDate?.expireItem?.workDate === item?.workDate &&
												warningDate?.expireItem?.workFrom === item?.workFrom &&
												warningDate?.expireItem?.workTo === item?.workTo && (
													<Popover
														content={
															<div className="font-normal text-sm">
																{rangeDate?.[0]
																	?.add(warningDate?.expireTime, "millisecond")
																	?.format("YYYY-MM-DD HH:mm")}
															</div>
														}
														trigger="hover"
														children={<Icon type="fleet-warn" className="" color="#f4ad0b" />}
													/>
												)}
											<FleetDatePicker
												className="input-item !w-[calc(100%-24px)]"
												value={baseDate}
												disabled
											/>
										</div>
										<span>{item?.week ?? baseDate?.format("ddd")}</span>
										<div>
											<FleetTimeRangePicker
												value={[workFrom, workTo]}
												format="HH:mm"
												className="input-item"
												disabled
											/>
										</div>
										<div>
											<FleetInput
												value={item?.calcRate}
												min={0}
												max={100}
												className="input-item"
												type="number"
												onChange={(val: number) => {
													onChange?.(idx, "calcRate", val)
												}}
											/>
										</div>
										<span>{item?.timeUsedD}</span>
										<span>{item?.timeUsedH}</span>
										<span>{item?.timeUsedM}</span>
										<div
											id={
												item?.status === 0
													? `except--${item?.workDate}--${item?.workFrom}--${item?.workTo}`
													: ""
											}
										>
											<FleetInput
												value={item?.remark}
												className="input-item"
												onChange={(val: string) => {
													onChange?.(idx, "remark", val)
												}}
											/>
										</div>
									</div>
								)
							})}
						</div>
					</div>
				</div>
			</div>
			<div className="calculatorArea-footer">
				<div className="summay-item">
					<span className="summay-label">Total Exception Time</span>
					<span className="summay-value">{formatThousandthNumber(totalExceptDate)}</span>
				</div>
				<div className="summay-item">
					<span className="summay-label">Laytime Allowed</span>
					<span className="summay-value">{formatThousandthNumber(+summary?.allowed)}</span>
				</div>
				<div className="summay-item">
					<span className="summay-label">Laytime Used</span>
					<span className="summay-value">{formatThousandthNumber(+summary?.used)}</span>
				</div>
				<div className="summay-item">
					<span className="summay-label">Time {summary?.dem_des > 0 ? "DES" : "DEM"}</span>
					<span className="summay-value">
						{formatThousandthNumber(Math.abs(+summary?.dem_des))}
					</span>
				</div>
				<div className="summay-item">
					<span className="summay-label">Amount</span>
					<span className="summay-value">{formatThousandthNumber(summary?.amount)}</span>
				</div>
			</div>
		</div>
	)
}

export default Calculator
