import React, { FC } from "react"
import { InvStatementOffHireTimeCalculateProps } from "./type"
import { FleetFormItem, FleetInput } from "@/components"
import { Button, Icon } from "@/_components"
import dayjs from "dayjs"
import { FleetTabbar } from "@/components/Tabs"
import { getRetainDecimalsNumber } from "@/tools/amount"
import { getCveAmount } from "../../../../tools"

const InvStatementOffHireTimeCalculate: FC<InvStatementOffHireTimeCalculateProps> = ({
	offHireGroup,
	onOpen,
	onCommit
}) => {
	return (
		<div className="w-full gap-2 flex flex-col border-b border-solid border-[#e7e9ec] pb-2">
			<span className="text-[#151515] text-[12px] leading-[20px] font-medium">
				Time Calculation Details
			</span>
			<div className="w-full flex flex-col gap-2">
				{offHireGroup?.moveOffHireList?.map((item, index) => {
					return (
						<div
							key={index}
							className="w-full grid grid-cols-[1fr_61px_93px_1fr] grid-rows-[56px] gap-2"
						>
							<FleetFormItem label="Off-Hire days" labelClassname="!text-[#686868]">
								<div className="bg-[#F3F3F3] h-8 px-[12px] py-[5px] border border-solid boder-[#DADADA] rounded-[4px] flex gap-[4px] items-center">
									<Icon type="fleet-Calender" size={16} />
									<span className="text-[14px] leading-[22px] font-normal flex-1 items-center flex justify-center">
										{item?.startDatetime
											? dayjs(item?.startDatetime)?.format("YYYY-MM-DD HH:mm")
											: null}{" "}
										-{" "}
										{item?.startDatetime
											? dayjs(item?.endDatetime)?.format("YYYY-MM-DD HH:mm")
											: null}
									</span>
								</div>
							</FleetFormItem>
							<FleetFormItem label="Ratio (%)" labelClassname="!text-[#686868]">
								<FleetInput value={item?.ratio} type="number" disabled />
							</FleetFormItem>
							<FleetFormItem label="Final Duration" labelClassname="!text-[#686868]">
								<FleetInput value={item?.finalDuration} type="number" disabled />
							</FleetFormItem>
							<FleetFormItem label="Remarks" labelClassname="!text-[#686868]">
								<FleetInput value={item?.remark} disabled />
							</FleetFormItem>
						</div>
					)
				})}
			</div>
			<Button prefixIcon="fleet-edit2" type="primary" onClick={onOpen}>
				Edit Off-hire
			</Button>
			<div className="w-full grid grid-cols-[1fr_1fr_1fr_1fr] grid-rows-[56px] gap-2">
				<FleetFormItem label="Off- Hire TTL Days" labelClassname="!text-[#686868]">
					<FleetInput
						value={offHireGroup?.offHireTtlDays}
						type="number"
						onChange={(value: number) => {
							const offHireAmount = value * +offHireGroup?.offHireDaily,
								hireBrokerage = (offHireAmount * +offHireGroup?.hireBrokerageRate) / 100,
								addComm = (offHireAmount * +offHireGroup?.addCommRation) / 100,
								cve = getCveAmount({
									unit: offHireGroup?.unit,
									cveRatio: offHireGroup?.cveRatio,
									ttlDay: value
								})
							console.log("getCveAmount", {
								offHireGroup,
								value,
								offHireAmount,
								// brokerage,
								addComm,
								cve
							})
							onCommit?.({
								offHireTtlDays: value,
								offHireAmount: getRetainDecimalsNumber(offHireAmount, 3),
								hireBrokerage: getRetainDecimalsNumber(hireBrokerage, 3),
								addComm: getRetainDecimalsNumber(addComm, 3),
								cve: getRetainDecimalsNumber(cve, 3)
							})
						}}
					/>
				</FleetFormItem>
				<FleetFormItem label="Off- Hire（$）" labelClassname="!text-[#686868]">
					<FleetInput
						value={offHireGroup?.offHireDaily}
						type="number"
						onChange={(value: number) => {
							const offHireAmount = value * +offHireGroup?.offHireTtlDays,
								hireBrokerage = (offHireAmount * +offHireGroup?.hireBrokerageRate) / 100,
								addComm = (offHireAmount * +offHireGroup?.addCommRation) / 100
							onCommit?.({
								offHireDaily: value,
								offHireAmount: getRetainDecimalsNumber(offHireAmount, 3),
								hireBrokerage: getRetainDecimalsNumber(hireBrokerage, 3),
								addComm: getRetainDecimalsNumber(addComm, 3)
							})
						}}
					/>
				</FleetFormItem>
				<FleetFormItem label="TTL Off-Hire Amount" labelClassname="!text-[#686868]">
					<FleetInput value={offHireGroup?.offHireAmount} type="number" disabled />
				</FleetFormItem>
			</div>
			<div className="w-full grid grid-cols-[1fr_1fr_1fr_1fr] grid-rows-[56px] gap-2">
				<FleetFormItem label="Off-Hire Add.Commissions (%)" labelClassname="!text-[#686868]">
					<FleetInput
						min={0}
						max={100}
						value={offHireGroup?.addCommRation}
						type="number"
						onChange={(value: number) => {
							onCommit?.({
								addCommRation: value,
								addComm: getRetainDecimalsNumber((value * offHireGroup?.offHireAmount) / 100, 3)
							})
						}}
					/>
				</FleetFormItem>
				<FleetFormItem label="Off- Hire Add.Comm. ($)" labelClassname="!text-[#686868]">
					<FleetInput value={offHireGroup?.addComm} type="number" disabled />
				</FleetFormItem>
				{/* <FleetFormItem label="Off-Hire Brokerage (%)" labelClassname="!text-[#686868]">
					<FleetInput
						min={0}
						max={100}
						value={offHireGroup?.brokerageRation}
						type="number"
						onChange={(value: number) => {
							onCommit?.({
								brokerageRation: value,
								brokerage: getRetainDecimalsNumber((value * offHireGroup?.offHireAmount) / 100, 3)
							});
						}}
					/>
				</FleetFormItem>
				<FleetFormItem label="Off- Hire Brokerage ($)" labelClassname="!text-[#686868]">
					<FleetInput value={offHireGroup?.brokerage} type="number" disabled />
				</FleetFormItem> */}
			</div>
			<div className="w-full grid grid-cols-[1fr_1fr_1fr_1fr] grid-rows-[56px] gap-2">
				<div className="grid grid-cols-[110px_1fr] gap-[4px]">
					<FleetFormItem label="Off- Hire C/V/E Amount ($)" labelClassname="!text-[#686868]">
						<FleetInput
							value={offHireGroup?.cveRatio}
							type="number"
							onChange={(value: number) => {
								const cve = getCveAmount({
									cveRatio: value,
									unit: offHireGroup?.unit,
									ttlDay: +offHireGroup?.offHireTtlDays
								})
								onCommit?.({
									cve: getRetainDecimalsNumber(cve, 3),
									cveRatio: value
								})
							}}
						/>
					</FleetFormItem>
					<FleetTabbar
						list={["PD", "PM", "PY"]}
						value={offHireGroup?.unit}
						onChange={(value: "PM" | "PY" | "PD") => {
							onCommit?.({
								cveRatio: ""
							})
							const cve = getCveAmount({
								cveRatio: 0,
								unit: value,
								ttlDay: +offHireGroup?.offHireTtlDays
							})
							onCommit?.({
								cve: getRetainDecimalsNumber(cve, 3),
								unit: value
							})
						}}
						className=" self-end"
					/>
				</div>
				<FleetFormItem label="Off- Hire C/V/E Amount ($)" labelClassname="!text-[#686868]">
					<FleetInput value={offHireGroup?.cve} type="number" disabled />
				</FleetFormItem>
			</div>
			<div className="w-full grid grid-cols-[1fr_1fr_1fr_1fr] grid-rows-[56px] gap-2">
				<FleetFormItem label="Brokerage Rate(%)" labelClassname="!text-[#686868]">
					<FleetInput
						min={0}
						max={100}
						value={offHireGroup?.hireBrokerageRate}
						type="number"
						onChange={(value: number) => {
							onCommit?.({
								hireBrokerageRate: value,
								hireBrokerage: getRetainDecimalsNumber(
									(value * offHireGroup?.offHireAmount) / 100,
									3
								)
							})
						}}
					/>
				</FleetFormItem>
				<FleetFormItem label="Brokerage($)" labelClassname="!text-[#686868]">
					<FleetInput value={offHireGroup?.hireBrokerage} type="number" disabled />
				</FleetFormItem>
			</div>
		</div>
	)
}

export default InvStatementOffHireTimeCalculate
