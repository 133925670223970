import request from "@/service"
import { type ApiParameter } from "@/api"

export const appVersion =
	process.env.NODE_ENV === "development" ? "/gateway/voyage/v1" : "/gateway/voyage/v1"
const enum ApiMap {
	getCurrentPlanParameter
}
type ApiUrl = keyof typeof ApiMap
const currentPlanApi: ApiParameter<ApiUrl> = {
	getCurrentPlanParameter: {
		method: "get",
		url: appVersion + "/move/voyage/port"
	}
}
export const getCurrentPlanApi = (id: string) => {
	return request.request({
		...currentPlanApi.getCurrentPlanParameter,
		url: `${currentPlanApi.getCurrentPlanParameter.url}/${id}`
	})
}
