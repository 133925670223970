import { useEffect, useRef, useState } from "react"
import { PreOperationDetailsProps, PreOperationTabChangeEvent, PreOperationTabKeys } from "../type"
import { VoyageManageHandoverFormRefType } from "@/pages/voyagemanage/business/preOp&movement/business/handoverForm/type"
import { useAppDispatch } from "@/hook"
import { useNavigate } from "react-router-dom"
import useReminder from "@/hook/useReminder"
import {
	savePreopTcvcHandoverFormAction,
	savePreopTctcHandoverFormAction,
	toMovementAction,
	updatePreopRemarkAction
} from "@/action/voyageManage/preOperation"
import { VoyageManageVoyageRemarkCommonRefType } from "@/pages/voyagemanage/business/preOp&movement/business/voyageRemark/type"
import { commonCheck } from "@/tools/check"
import { useTranslation } from "react-i18next"
import {
	HandoverTctcFormItemType,
	HandoverTcvcFormItemType
} from "@/pages/voyagemanage/business/preOp&movement"

const useIndex = (
	voyageId: number,
	voyageType: PreOperationDetailsProps["voyageType"],
	onAdd: PreOperationDetailsProps["onAdd"],
	onUpdate?: PreOperationDetailsProps["onUpdate"]
) => {
	const dispatch = useAppDispatch()
	const navigate = useNavigate()
	const { reminder } = useReminder()
	const { t } = useTranslation()
	const [loading, setLoading] = useState(false)
	const [activeTab, setActiveTab] = useState<PreOperationTabKeys>("handoverForm")
	const handoverFormRef = useRef<VoyageManageHandoverFormRefType>()
	const voyageRemarkRef = useRef<VoyageManageVoyageRemarkCommonRefType>()

	const handleActiveTabChange: PreOperationTabChangeEvent = (value) => {
		if (activeTab === value) return
		if (!voyageId && value === "voyageRemark") {
			reminder(
				"warning",
				"You need to save the voyage information before proceeding with this operation！"
			)
			return
		}
		setActiveTab(value)
	}

	const savePreopHandoverFormFront = () => {
		setLoading(true)
	}

	const savePreopHandoverFormSuccess = (response) => {
		setLoading(false)
		reminder("success", response?.msg)
		handoverFormRef?.current?.reset()
		switch (true) {
			case !voyageId && !!response?.data:
				onAdd?.(response?.data)
				break
			case voyageId && !!response?.data:
				onUpdate?.(voyageId, response?.data)
				break
			default:
				break
		}
		// !voyageId && response?.data && onAdd?.(response?.data)
	}

	const savePreopHandoverFormFailed = (error) => {
		setLoading(false)
		reminder("error", error?.msg ? error?.msg + ": " + error?.data : error?.data)
	}

	const savePreopTcvcHandoverForm = (needToMovement?: boolean) => {
		const params = handoverFormRef?.current?.combineParams() as HandoverTcvcFormItemType
		if (!needToMovement) {
			const { checked, checkKey } = commonCheck(
				{
					voyageNo: params?.voyageNo,
					vesselName: params?.headFixture?.vesselName
				},
				["vesselName", "voyageNo"]
			)
			if (!checked) {
				reminder(
					"error",
					`Field ${t(`voyageManagement.preOperation.${checkKey}`, {
						defaultValue: t(`common.${checkKey}`)
					})} is required`
				)
				return
			}
		}
		savePreopTcvcHandoverFormAction(
			{ ...params },
			savePreopHandoverFormFront,
			needToMovement
				? (response) => {
						setLoading(false)
						reminder("success", response?.msg)
						!voyageId && onAdd?.(response?.data)
						toMovement(response?.data?.id)
					}
				: savePreopHandoverFormSuccess,
			savePreopHandoverFormFailed,
			dispatch,
			navigate
		)
	}

	const savePreopTctcHandoverForm = (needToMovement?: boolean) => {
		const params = handoverFormRef?.current?.combineParams() as HandoverTctcFormItemType
		console.log("params", params)
		if (!needToMovement) {
			const { checked, checkKey } = commonCheck(
				{
					voyageNo: params?.voyageNo,
					vesselName: params?.vesselName
				},
				["vesselName", "voyageNo"]
			)
			if (!checked) {
				reminder(
					"error",
					`Field ${t(`voyageManagement.preOperation.${checkKey}`, {
						defaultValue: t(`common.${checkKey}`)
					})} is required`
				)
				return
			}
		}
		savePreopTctcHandoverFormAction(
			{ ...params },
			savePreopHandoverFormFront,
			needToMovement
				? (response) => {
						setLoading(false)
						reminder("success", response?.msg)
						!voyageId && onAdd?.(response?.data)
						toMovement(response?.data?.id)
					}
				: savePreopHandoverFormSuccess,
			savePreopHandoverFormFailed,
			dispatch,
			navigate
		)
	}

	const savePreopHandoverForm = (needToMovement?: boolean) => {
		if (needToMovement && !handoverFormRef?.current?.check()) return
		switch (true) {
			case voyageType === "TCVC":
				savePreopTcvcHandoverForm(needToMovement)
				break
			case voyageType === "TCTC":
				savePreopTctcHandoverForm(needToMovement)
				break
			default:
				break
		}
	}

	const updatePreopRemark = (needToMovement?: boolean) => {
		const params = voyageRemarkRef?.current?.combineParams()
		updatePreopRemarkAction(
			{
				...params,
				id: voyageId
			},
			savePreopHandoverFormFront,
			needToMovement
				? (response) => {
						setLoading(false)
						reminder("success", response?.msg)
						toMovement(response?.data?.id)
					}
				: savePreopHandoverFormSuccess,
			savePreopHandoverFormFailed,
			dispatch,
			navigate
		)
	}

	const toMovementSuccess = (response) => {
		reminder("success", response?.msg)
		setLoading(false)
		navigate("/layout/voyagemanage")
	}

	const toMovement = (id?: number) => {
		toMovementAction(
			{
				voyageId: id ?? voyageId
			},
			savePreopHandoverFormFront,
			toMovementSuccess,
			savePreopHandoverFormFailed,
			dispatch,
			navigate
		)
	}

	const handleToMovement = () => {
		switch (activeTab) {
			case "handoverForm":
				savePreopHandoverForm(true)
				break
			case "voyageRemark":
				if (!voyageId) {
					reminder("info", "Please save this newly created voyage first")
					return
				}
				updatePreopRemark(true)
				break
			default:
				break
		}
	}

	const handleSave = () => {
		switch (activeTab) {
			case "handoverForm":
				savePreopHandoverForm()
				break
			case "voyageRemark":
				updatePreopRemark()
				break
			default:
				break
		}
	}

	useEffect(() => {
		if (activeTab === "handoverForm") {
			handoverFormRef?.current?.reset()
		}
		if (activeTab === "voyageRemark") {
			voyageRemarkRef?.current?.reset()
		}
	}, [voyageId, activeTab])

	return {
		loading,
		activeTab,
		handoverFormRef,
		voyageRemarkRef,
		handleActiveTabChange,
		handleSave,
		handleToMovement
	}
}

export default useIndex
