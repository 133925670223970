import React, { FC, useMemo } from "react"
import { InvStatementOffHireProps } from "./type"
import { Button, Icon } from "@/_components"
import { InvStatementOffHireTimeCalculate } from "../timeCalculate"
import { InvStatementOtherCalculate } from "../otherCalculate"
import { InvStatementBunker } from "../../../../common"
import { formatThousandthNumber } from "@/tools/amount"

const InvStatementOffHire: FC<InvStatementOffHireProps> = ({
	currentIndex,
	onOffHireOpen,
	item,
	onCommit,
	onDelete,
	onGroupAdd,
	onGroupCommit,
	onGroupDelete
}) => {
	const disableBunkers = useMemo(() => {
		return [...new Set(item?.bunkerPriceList?.map((item) => item?.bunkerType))]
	}, [item?.bunkerPriceList])

	const bunkerSummary = useMemo(() => {
		return item?.bunkerPriceList?.reduce((prev, curr) => {
			return prev + curr?.total
		}, 0)
	}, [item?.bunkerPriceList])

	const otherSummary = useMemo(() => {
		return item?.otherCalDetailList?.reduce((prev, curr) => {
			return prev + +curr?.totalAmount
		}, 0)
	}, [item?.otherCalDetailList])

	return (
		<div className="w-full flex flex-col gap-[16px]">
			<div className="flex gap-4 items-center before:inline-block before:content-[''] before:h-[1px] before:w-full before:bg-[#D2D2D2] after:inline-block after:content-[''] after:h-[1px] after:w-full after:bg-[#D2D2D2] whitespace-nowrap">
				<span className="text-[#707070] text-[12px] leading-[24px] font-medium ">
					Off-Hire Party {currentIndex + 1}
				</span>
				{currentIndex > 0 && (
					<Icon
						className="whitespace-nowrap !overflow-visible"
						type="fleet-delete"
						color="#FF0D0D"
						size={14}
						onClick={onDelete}
					/>
				)}
			</div>
			<div className="w-full gap-2 flex flex-col">
				<InvStatementOffHireTimeCalculate
					onCommit={(params) => {
						onCommit?.(params)
					}}
					onOpen={onOffHireOpen}
					offHireGroup={item}
				/>
				<div className="w-full flex flex-col gap-2 border-b border-solid border-[#e7e9ec] pb-2">
					<InvStatementBunker
						title="Bunker Calculation Details"
						labelClassname="text-[#151515] font-medium"
						items={item?.bunkerPriceList}
						onCommit={(current, params) => {
							const index = item?.bunkerPriceList?.findIndex(
								(bunkerItem) =>
									bunkerItem?.bunkerType === current?.bunkerType &&
									bunkerItem?.type === current?.type
							)
							console.log("index", index)
							onGroupCommit?.("bunkerPriceList", index, params)
						}}
						onDelete={(current) => {
							const index = item?.bunkerPriceList?.findIndex(
								(bunkerItem) =>
									bunkerItem?.bunkerType === current?.bunkerType &&
									bunkerItem?.type === current?.type
							)
							onGroupDelete?.("bunkerPriceList", index)
						}}
						disableBunkers={disableBunkers}
					/>
					<Button
						prefixIcon="fleet-plus"
						type="primary"
						size="small"
						onClick={() => {
							onGroupAdd?.("bunkerPriceList")
						}}
					>
						Add row
					</Button>
				</div>
				<InvStatementOtherCalculate
					onAdd={() => {
						onGroupAdd?.("otherCalDetailList")
					}}
					onCommit={(index, params) => {
						onGroupCommit?.("otherCalDetailList", index, params)
					}}
					onDelete={(index) => {
						onGroupDelete?.("otherCalDetailList", index)
					}}
					items={item?.otherCalDetailList}
				/>
				<div className="flex w-full flex-col gap-2">
					<ul className="w-full flex flex-col gap-1">
						<li className="text-[#595959] text-[14px] font-medium leading-[22px]">
							Time Calculation Summary
						</li>
						<li className="text-[14px] leading-[22px] font-normal">
							<span className="text-[#A3A3A3]">TTL Off-Hire Amount: </span>
							<span className="text-[#7D7D7D]">{`${formatThousandthNumber(+item?.offHireDaily)} $ * ${formatThousandthNumber(+item?.offHireTtlDays)} Days = ${formatThousandthNumber(+item?.offHireAmount)} $`}</span>
						</li>
						<li className="text-[14px] leading-[22px] font-normal">
							<span className="text-[#A3A3A3]">Off-Hire C/V/E: </span>
							<span className="text-[#7D7D7D]">
								{item?.unit === "PM"
									? `${formatThousandthNumber(+item?.cveRatio)} $ * ${item?.offHireTtlDays} Days / 365 * 12 = ${formatThousandthNumber(item?.cve)} $`
									: item?.unit === "PY"
										? `${formatThousandthNumber(+item?.cveRatio)} $ * ${item?.offHireTtlDays} Days / 365 = ${formatThousandthNumber(item?.cve)} $`
										: `${formatThousandthNumber(+item?.cveRatio)} $ * ${item?.offHireTtlDays} Days = ${formatThousandthNumber(item?.cve)} $`}
							</span>
						</li>
						<li className="text-[14px] leading-[22px] font-normal">
							<span className="text-[#A3A3A3]">Off-Hire Add.Commissions: </span>
							<span className="text-[#7D7D7D]">{`${formatThousandthNumber(+item?.offHireAmount)} $  * ${item?.addCommRation} %= ${formatThousandthNumber(item?.addComm)} $ `}</span>
						</li>
						<li className="text-[14px] leading-[22px] font-normal">
							<span className="text-[#A3A3A3]">Off-Hire Brokerage: </span>
							<span className="text-[#7D7D7D]">{`${formatThousandthNumber(+item?.offHireAmount)} $  * ${item?.hireBrokerageRate || 0} %= ${formatThousandthNumber(item?.hireBrokerage || 0, 2)} $ `}</span>
						</li>
					</ul>
					<ul className="w-full flex flex-col gap-1">
						<li className="text-[#595959] text-[14px] font-medium leading-[22px]">
							Bunker Calculation Summary
						</li>
						<li className="text-[#595959] text-[14px] font-medium leading-[22px]">
							<span className="text-[#A3A3A3]">TTL Off-Hire Bunker Amount: </span>
							<span className="text-[#7D7D7D]">{`${formatThousandthNumber(bunkerSummary)} $ `}</span>
						</li>
					</ul>
					<ul className="w-full flex flex-col gap-1">
						<li className="text-[#595959] text-[14px] font-medium leading-[22px]">
							Other Calculation Summary
						</li>
						<li className="text-[#595959] text-[14px] font-medium leading-[22px]">
							<span className="text-[#A3A3A3]">TTL Other Expenses Amount: </span>
							<span className="text-[#7D7D7D]">{`${formatThousandthNumber(otherSummary)} $ `}</span>
						</li>
					</ul>
					{/* <ul className="w-full flex flex-col gap-1">
						<li className="text-[#595959] text-[12px] font-medium leading-[22px]">
							Other Calculation Summary
						</li>
						{item?.otherCalDetailList?.map((item, index) => {
							return (
								<li key={index} className="text-[#595959] text-[12px] font-medium leading-[22px]">
									<span className="text-[#A3A3A3]">{`${item?.title || "--"}: `}</span>
									<span className="text-[#7D7D7D]">{`${formatThousandthNumber(+item?.totalAmount, 2)} $`}</span>
								</li>
							);
						})}
					</ul> */}
				</div>
			</div>
		</div>
	)
}

export default InvStatementOffHire
