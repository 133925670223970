import { type ApiParameter } from "@/api"
import { appVersion } from "@/api/constant"
import request from "@/service"
import { PageParams } from "../estimation"
import { InvoicesVoyageStatusType } from "@/pages/voyagemanage/business/invoices/module"

const enum ApiMap {
	getInvoicesVoyageGroupParameter,
	getInvFreightGroupParameter,
	getInitialFreightInvoicesParameter,
	getLastFreightInvoicesParameter,
	getFreightInvoicesParameter,
	saveFreightInvoicesParameter,
	updateFreightInvoicesParameter,
	getFreightInvHistoryParameter,
	getInvStatementGroupParameter,
	getInitialStatementInvParameter,
	getLastStatementInvParameter,
	getStatementInvoicesParameter,
	saveStatementInvoicesParameter,
	updateStatementInvoicesParameter,
	getStatementHistoryParameter,
	getAccountGroupParameter,
	downloadFreightInvParameter,
	downloadStatementInvParameter,
	deleteInvFreightItemParameter,
	deleteInvStatementItemParameter
}

type ApiUrl = keyof typeof ApiMap

const invoicesApi: ApiParameter<ApiUrl> = {
	getInvoicesVoyageGroupParameter: {
		method: "get",
		url: appVersion + "/invoices/search"
	},
	getInvFreightGroupParameter: {
		method: "get",
		url: appVersion + "/inv/freight/list"
	},
	getInitialFreightInvoicesParameter: {
		method: "get",
		url: appVersion + "/inv/freight/first"
	},
	getLastFreightInvoicesParameter: {
		method: "get",
		url: appVersion + "/inv/freight/last"
	},
	getFreightInvoicesParameter: {
		method: "get",
		url: appVersion + "/inv/freight"
	},
	saveFreightInvoicesParameter: {
		method: "post",
		url: appVersion + "/inv/freight"
	},
	updateFreightInvoicesParameter: {
		method: "put",
		url: appVersion + "/inv/freight"
	},
	getFreightInvHistoryParameter: {
		method: "get",
		url: appVersion + "/inv/freight/history"
	},
	getInvStatementGroupParameter: {
		method: "get",
		url: appVersion + "/inv/hire/list"
	},
	getInitialStatementInvParameter: {
		method: "get",
		url: appVersion + "/inv/hire/first"
	},
	getLastStatementInvParameter: {
		method: "get",
		url: appVersion + "/inv/hire/last"
	},
	getStatementInvoicesParameter: {
		method: "get",
		url: appVersion + "/inv/hire"
	},
	saveStatementInvoicesParameter: {
		method: "post",
		url: appVersion + "/inv/hire"
	},
	updateStatementInvoicesParameter: {
		method: "put",
		url: appVersion + "/inv/hire"
	},
	getStatementHistoryParameter: {
		method: "get",
		url: appVersion + "/inv/hire/history"
	},
	getAccountGroupParameter: {
		method: "get",
		url: appVersion + "/account/list"
	},
	downloadFreightInvParameter: {
		method: "get",
		url: appVersion + "/inv/freight/excel"
	},
	downloadStatementInvParameter: {
		method: "get",
		url: appVersion + "/inv/hire/excel"
	},
	deleteInvFreightItemParameter: {
		method: "delete",
		url: appVersion + "/inv/freight"
	},
	deleteInvStatementItemParameter: {
		method: "delete",
		url: appVersion + "/inv/hire"
	}
}

export type getInvoicesVoyageGroupParams = {
	keyword: string
	state?: InvoicesVoyageStatusType
} & PageParams

export type getInvFreightGroupParams = {
	voyageId: string | number
}

export type getInvoicesGroupParams = {
	id: string | number
}

export type getInitialFreightInvoicesParams = {
	voyageId: string | number
}

export type getLastFreightInvoicesParams = getInitialFreightInvoicesParams

export type getFreightInvoicesParams = {
	invoiceId: string | number
}

export type saveFreightInvoicesParams = {}

export type updateFreightInvoicesParams = saveFreightInvoicesParams

export type getInvStatementGroupParams = {
	voyageId: string
	type?: "Rent" | "Relet"
}

export type getInitialStatementInvParams = {
	voyageId: string
	type?: "Rent" | "Relet"
}

export type getLastStatementInvParams = getInitialStatementInvParams

export type getStatementInvoicesParams = {
	invoiceId: string | number
}

export type saveStatementInvoicesParams = { type?: "Rent" | "Relet" }

export type updateStatementInvoicesParams = saveStatementInvoicesParams

export type getFreightInvHistoryParams = {
	invId: string | number
	period: number
}

export type getStatementHistoryParams = getFreightInvHistoryParams

export type getAccountGroupParams = {
	companyName: string
}

export type downloadFreightInvParams = getInvoicesGroupParams

export type downloadStatementInvParams = getInvoicesGroupParams

export const getInvoicesVoyageGroupApi = (params: getInvoicesVoyageGroupParams) => {
	return request.request({
		...invoicesApi.getInvoicesVoyageGroupParameter,
		params
	})
}

export const getInvFreightGroupApi = (params: getInvFreightGroupParams) => {
	return request.request({
		...invoicesApi.getInvFreightGroupParameter,
		url: `${invoicesApi.getInvFreightGroupParameter.url}/${params?.voyageId}`
	})
}

export const getInitialFreightInvoicesApi = (params: getInitialFreightInvoicesParams) => {
	console.log("para", params)
	return request.request({
		...invoicesApi.getInitialFreightInvoicesParameter,
		url: `${invoicesApi?.getInitialFreightInvoicesParameter?.url}/${params?.voyageId}`
	})
}

export const getLastFreightInvoicesApi = (params: getLastFreightInvoicesParams) => {
	console.log("para", params)
	return request.request({
		...invoicesApi.getLastFreightInvoicesParameter,
		url: `${invoicesApi?.getLastFreightInvoicesParameter?.url}/${params?.voyageId}`
	})
}

export const getFreightInvoicesApi = (params: getFreightInvoicesParams) => {
	console.log("para", params)
	return request.request({
		...invoicesApi.getLastFreightInvoicesParameter,
		url: `${invoicesApi?.getFreightInvoicesParameter?.url}/${params?.invoiceId}`
	})
}

export const saveFreightInvoicesApi = (params: saveFreightInvoicesParams) => {
	return request.request({
		...invoicesApi.saveFreightInvoicesParameter,
		data: params
	})
}

export const updateFreightInvoicesApi = (params: updateFreightInvoicesParams) => {
	return request.request({
		...invoicesApi.updateFreightInvoicesParameter,
		data: params
	})
}

export const getInvStatementGroupApi = (params: getInvStatementGroupParams) => {
	return request.request({
		...invoicesApi.getInvStatementGroupParameter,
		url: `${invoicesApi.getInvStatementGroupParameter.url}/${params?.voyageId}`,
		params: { type: params?.type }
	})
}

export const getInitialStatementInvApi = (params: getInitialStatementInvParams) => {
	console.log("para", params)
	return request.request({
		...invoicesApi.getInitialStatementInvParameter,
		url: `${invoicesApi?.getInitialStatementInvParameter?.url}/${params?.voyageId}`,
		params: { type: params?.type }
	})
}

export const getLastStatementInvApi = (params: getLastStatementInvParams) => {
	return request.request({
		...invoicesApi.getLastStatementInvParameter,
		url: `${invoicesApi?.getLastStatementInvParameter?.url}/${params?.voyageId}`,
		params: { type: params?.type }
	})
}

export const getStatementInvoicesApi = (params: getStatementInvoicesParams) => {
	return request.request({
		...invoicesApi.getStatementInvoicesParameter,
		url: `${invoicesApi?.getStatementInvoicesParameter?.url}/${params?.invoiceId}`
	})
}

export const saveStatementInvoicesApi = (params: saveStatementInvoicesParams) => {
	return request.request({
		...invoicesApi.saveStatementInvoicesParameter,
		data: params
	})
}

export const updateStatementInvoicesApi = (params: updateStatementInvoicesParams) => {
	return request.request({
		...invoicesApi.updateStatementInvoicesParameter,
		data: params
	})
}

export const getFreightInvHistoryApi = (params: getFreightInvHistoryParams) => {
	return request.request({
		...invoicesApi.getFreightInvHistoryParameter,
		params
	})
}

export const getStatementHistoryApi = (params: getStatementHistoryParams) => {
	return request.request({
		...invoicesApi.getStatementHistoryParameter,
		params
	})
}

export const getAccountGroupApi = (params: getAccountGroupParams) => {
	return request.request({
		...invoicesApi.getAccountGroupParameter,
		params
	})
}

export const downloadFreightInvApi = (params: downloadFreightInvParams) => {
	return request.request({
		...invoicesApi.downloadFreightInvParameter,
		url: `${invoicesApi.downloadFreightInvParameter.url}/${params?.id}`,
		responseType: "blob"
	})
}

export const downloadStatementInvApi = (params: downloadStatementInvParams) => {
	return request.request({
		...invoicesApi.downloadStatementInvParameter,
		url: `${invoicesApi.downloadStatementInvParameter.url}/${params?.id}`,
		responseType: "blob"
	})
}

export const deleteInvFreightItemApi = (params: getInvFreightGroupParams) => {
	return request.request({
		...invoicesApi.deleteInvFreightItemParameter,
		url: `${invoicesApi?.deleteInvFreightItemParameter?.url}/${params?.voyageId}`
	})
}

export const deleteInvStatementItemApi = (params: getInvFreightGroupParams) => {
	return request.request({
		...invoicesApi.deleteInvStatementItemParameter,
		url: `${invoicesApi?.deleteInvStatementItemParameter?.url}/${params?.voyageId}`
	})
}
