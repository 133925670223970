import { type ParameterType } from "@/api"
import { loginOutAction } from "@/store/userSlice"

import {
	getInvoicesVoyageGroupApi,
	getInvoicesVoyageGroupParams,
	getInitialFreightInvoicesApi,
	getInitialFreightInvoicesParams,
	saveFreightInvoicesApi,
	saveFreightInvoicesParams,
	getInvFreightGroupApi,
	getInvFreightGroupParams,
	getLastFreightInvoicesApi,
	getLastFreightInvoicesParams,
	getFreightInvoicesParams,
	getFreightInvoicesApi,
	updateFreightInvoicesApi,
	updateFreightInvoicesParams,
	getInvStatementGroupApi,
	getInvStatementGroupParams,
	getInitialStatementInvApi,
	getInitialStatementInvParams,
	getLastStatementInvApi,
	getLastStatementInvParams,
	getStatementInvoicesApi,
	getStatementInvoicesParams,
	saveStatementInvoicesApi,
	saveStatementInvoicesParams,
	updateStatementInvoicesParams,
	updateStatementInvoicesApi,
	getFreightInvHistoryApi,
	getFreightInvHistoryParams,
	getStatementHistoryApi,
	getStatementHistoryParams,
	getAccountGroupApi,
	getAccountGroupParams,
	downloadFreightInvApi,
	downloadFreightInvParams,
	downloadStatementInvApi,
	downloadStatementInvParams,
	deleteInvFreightItemApi,
	deleteInvStatementItemApi
} from "@/api/voyageManage/invoices"

export const getInvoicesVoyageGroupAction: ParameterType<getInvoicesVoyageGroupParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.()
	getInvoicesVoyageGroupApi(params)
		.then((response) => {
			successCallback?.(response)
		})
		.catch((err) => {
			errorCallback?.(err)
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")))
			}
		})
}

export const getInvFreightGroupAction: ParameterType<getInvFreightGroupParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.()
	getInvFreightGroupApi(params)
		.then((response) => {
			successCallback?.(response)
		})
		.catch((err) => {
			errorCallback?.(err)
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")))
			}
		})
}

export const getInitialFreightInvoicesAction: ParameterType<getInitialFreightInvoicesParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.()
	getInitialFreightInvoicesApi(params)
		.then((response) => {
			successCallback?.(response)
		})
		.catch((err) => {
			errorCallback?.(err)
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")))
			}
		})
}

export const getLastFreightInvoicesAction: ParameterType<getLastFreightInvoicesParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.()
	getLastFreightInvoicesApi(params)
		.then((response) => {
			successCallback?.(response)
		})
		.catch((err) => {
			errorCallback?.(err)
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")))
			}
		})
}

export const getFreightInvoicesAction: ParameterType<getFreightInvoicesParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.()
	getFreightInvoicesApi(params)
		.then((response) => {
			successCallback?.(response)
		})
		.catch((err) => {
			errorCallback?.(err)
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")))
			}
		})
}

export const updateFreightInvoicesAction: ParameterType<updateFreightInvoicesParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.()
	updateFreightInvoicesApi(params)
		.then((response) => {
			successCallback?.(response)
		})
		.catch((err) => {
			errorCallback?.(err)
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")))
			}
		})
}

export const saveFreightInvoicesAction: ParameterType<saveFreightInvoicesParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.()
	saveFreightInvoicesApi(params)
		.then((response) => {
			successCallback?.(response)
		})
		.catch((err) => {
			errorCallback?.(err)
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")))
			}
		})
}

export const getInvStatementGroupAction: ParameterType<getInvStatementGroupParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.()
	getInvStatementGroupApi(params)
		.then((response) => {
			successCallback?.(response)
		})
		.catch((err) => {
			errorCallback?.(err)
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")))
			}
		})
}

export const getInitialStatementInvAction: ParameterType<getInitialStatementInvParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.()
	getInitialStatementInvApi(params)
		.then((response) => {
			successCallback?.(response)
		})
		.catch((err) => {
			errorCallback?.(err)
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")))
			}
		})
}

export const getLastStatementInvAction: ParameterType<getLastStatementInvParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.()
	getLastStatementInvApi(params)
		.then((response) => {
			successCallback?.(response)
		})
		.catch((err) => {
			errorCallback?.(err)
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")))
			}
		})
}

export const getStatementInvoicesAction: ParameterType<getStatementInvoicesParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.()
	getStatementInvoicesApi(params)
		.then((response) => {
			successCallback?.(response)
		})
		.catch((err) => {
			errorCallback?.(err)
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")))
			}
		})
}

export const saveStatementInvoicesAction: ParameterType<saveStatementInvoicesParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.()
	saveStatementInvoicesApi(params)
		.then((response) => {
			successCallback?.(response)
		})
		.catch((err) => {
			errorCallback?.(err)
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")))
			}
		})
}

export const updateStatementInvoicesAction: ParameterType<updateStatementInvoicesParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.()
	updateStatementInvoicesApi(params)
		.then((response) => {
			successCallback?.(response)
		})
		.catch((err) => {
			errorCallback?.(err)
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")))
			}
		})
}

export const getFreightInvHistoryAction: ParameterType<getFreightInvHistoryParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.()
	getFreightInvHistoryApi(params)
		.then((response) => {
			successCallback?.(response)
		})
		.catch((err) => {
			errorCallback?.(err)
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")))
			}
		})
}

export const getStatementHistoryAction: ParameterType<getFreightInvHistoryParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.()
	getStatementHistoryApi(params)
		.then((response) => {
			successCallback?.(response)
		})
		.catch((err) => {
			errorCallback?.(err)
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")))
			}
		})
}

export const getAccountGroupAction: ParameterType<getAccountGroupParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.()
	getAccountGroupApi(params)
		.then((response) => {
			successCallback?.(response)
		})
		.catch((err) => {
			errorCallback?.(err)
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")))
			}
		})
}

export const downloadFreightInvAction: ParameterType<downloadFreightInvParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.()
	downloadFreightInvApi(params)
		.then((response) => {
			successCallback?.(response)
		})
		.catch((err) => {
			errorCallback?.(err)
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")))
			}
		})
}

export const downloadStatementInvAction: ParameterType<downloadStatementInvParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.()
	downloadStatementInvApi(params)
		.then((response) => {
			successCallback?.(response)
		})
		.catch((err) => {
			errorCallback?.(err)
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")))
			}
		})
}

export const deleteInvFreightItemAction: ParameterType<getInvFreightGroupParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.()
	deleteInvFreightItemApi(params)
		.then((response) => {
			successCallback?.(response)
		})
		.catch((err) => {
			errorCallback?.(err)
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")))
			}
		})
}

export const deleteInvStatementItemAction: ParameterType<getInvFreightGroupParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.()
	deleteInvStatementItemApi(params)
		.then((response) => {
			successCallback?.(response)
		})
		.catch((err) => {
			errorCallback?.(err)
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")))
			}
		})
}
