import { useInvFieldNames } from "@/pages/voyagemanage/business/invoices/hooks"
import { useMemo } from "react"
import { InvCommonOtherExpensesProps } from "../type"

const useIndex = (otherItem: InvCommonOtherExpensesProps["otherItem"]) => {
	const { dataSource, delIds, change, add, remove, init } = useInvFieldNames()

	const summary = useMemo(() => {
		if (!otherItem?.addOther)
			return {
				less: 0,
				plus: 0
			}
		return dataSource?.reduce(
			(prev, curr) => {
				return {
					less: prev?.less + Math.abs(Math.min(0, +curr?.fieldVal)),
					plus: prev?.plus + Math.abs(Math.max(0, +curr?.fieldVal))
				}
			},
			{
				less: 0,
				plus: 0
			}
		)
	}, [dataSource, otherItem?.addOther])

	return {
		summary,
		dataSource,
		delIds,
		change,
		add,
		remove,
		init
	}
}

export default useIndex
