import { type ParameterType } from "@/api"
import { loginOutAction } from "@/store/userSlice"

import {
	getMovementGroupApi,
	getMovementGroupParams,
	getMovementApi,
	getMovementParams,
	deleteMovementApi,
	deleteMovementParams,
	getMovementStowagePlanApi,
	getMovementStowagePlanParams,
	saveMovementStowagePlanApi,
	saveMovementStowagePlanParams,
	getMovementLaytimeApi,
	getMovementLaytimeGroupApi,
	getMovementLaytimeGroupParams,
	getMovementLaytimeParams,
	deleteMovementLaytimeApi,
	deleteMovementLaytimeParams,
	saveMovementLaytimeGroupApi,
	saveMovementLaytimeGroupParams,
	saveMovementDetailApi,
	saveMovementDetailParams,
	editMovementVoyageStatusApi,
	editMovementVoyageStatusParams,
	getMovementFilePathParams,
	getMovementFilePathApi,
	setMovementMainPlanApi,
	setMovementMainPlanParams,
	updateMovementPlanApi,
	updateMovementPlanPayload,
	downloadLaytimeAttachApi,
	downloadLaytimeAttachParams,
	getMovementPlanDetailsApi,
	getMovementPlanDetailsParmas,
	downloadMovementPnlApi,
	downloadMovementPnlParams,
	getMovementTaskListParams,
	getMovementVoyageTaskParams,
	getMovementTaskCommentListParams,
	saveMovementTaskCommentParams,
	deleteMovementTaskCommentParams,
	getMovementTaskListApi,
	getMovementVoyageTaskApi,
	getMovemenTaskCommentListApi,
	saveMovementTaskCommentApi,
	deleteMovementTaskCommentApi,
	updateMovementTaskItemApi,
	updateMovementTaskItemParams,
	initMovementDetailsApi,
	initMovementDetailsParams,
	saveMovementTaskConfigParams,
	saveMovementTaskConfigApi,
	getMovementTctcApi,
	getMovementTctcParams,
	getMovementDivisionApi,
	getBillDetailDataParams,
	getBillDetailDataApi,
	saveBillDetailDataApi,
	saveBillDetailDataParams,
	uploadBlPortFileParams,
	uploadBlPortFileApi,
	deleteBlPortFileApi,
	deleteBlPortFileParams,
	downloadBlPortFileApi,
	downloadBlPortFileParams,
	getInsureFormDataApi,
	getInsureFormDataParams,
	saveInsureFormDataParams,
	saveInsureFormDataApi,
	getDepartmentDataApi,
	getOperatorListParams,
	getOperatorListApi,
	getCharterListApi,
	getBlTableDataApi,
	getBlTableDataParams,
	getInsureTableDataApi,
	getInsureTableDataParams,
	downloadInsureTableDataApi,
	downloadBlTableDataApi,
	getCurrentPlanApi,
	uploadEstPlanApi
} from "@/api/voyageManage/movement"

export const getMovementGroupAction: ParameterType<getMovementGroupParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.()
	getMovementGroupApi(params)
		.then((response) => {
			successCallback?.(response)
		})
		.catch((err) => {
			errorCallback?.(err)
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")))
			}
		})
}

export const deleteMovementAction: ParameterType<deleteMovementParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.()
	deleteMovementApi(params)
		.then((response) => {
			successCallback?.(response)
		})
		.catch((err) => {
			errorCallback?.(err)
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")))
			}
		})
}

export const getMovementAction: ParameterType<getMovementParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.()
	getMovementApi(params)
		.then((response) => {
			successCallback?.(response)
		})
		.catch((err) => {
			errorCallback?.(err)
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")))
			}
		})
}

export const getMovementStowagePlanActopn: ParameterType<getMovementStowagePlanParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.()
	getMovementStowagePlanApi(params)
		.then((response) => {
			successCallback?.(response)
		})
		.catch((err) => {
			errorCallback?.(err)
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")))
			}
		})
}

export const saveMovementStowagePlanAction: ParameterType<saveMovementStowagePlanParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.()
	saveMovementStowagePlanApi(params)
		.then((response) => {
			successCallback?.(response)
		})
		.catch((err) => {
			errorCallback?.(err)
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")))
			}
		})
}

export const getMovementLaytimeAction: ParameterType<getMovementLaytimeParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.()
	getMovementLaytimeApi(params)
		.then((response) => {
			successCallback?.(response)
		})
		.catch((err) => {
			errorCallback?.(err)
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")))
			}
		})
}

export const getMovementLaytimeGroupAction: ParameterType<getMovementLaytimeGroupParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.()
	getMovementLaytimeGroupApi(params)
		.then((response) => {
			successCallback?.(response)
		})
		.catch((err) => {
			errorCallback?.(err)
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")))
			}
		})
}

export const saveMovementLaytimeGroupAction: ParameterType<saveMovementLaytimeGroupParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.()
	saveMovementLaytimeGroupApi(params)
		.then((response) => {
			successCallback?.(response)
		})
		.catch((err) => {
			errorCallback?.(err)
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")))
			}
		})
}

export const deleteMovementLaytimeAction: ParameterType<deleteMovementLaytimeParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.()
	deleteMovementLaytimeApi(params)
		.then((response) => {
			successCallback?.(response)
		})
		.catch((err) => {
			errorCallback?.(err)
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")))
			}
		})
}

export const saveMovementDetailAction: ParameterType<saveMovementDetailParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.()
	saveMovementDetailApi(params)
		.then((response) => {
			successCallback?.(response)
		})
		.catch((err) => {
			errorCallback?.(err)
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")))
			}
		})
}

export const editMovementVoyageStatusAction: ParameterType<editMovementVoyageStatusParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.()
	editMovementVoyageStatusApi(params)
		.then((response) => {
			successCallback?.(response)
		})
		.catch((err) => {
			errorCallback?.(err)
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")))
			}
		})
}

export const getMovementFilePathAction: ParameterType<getMovementFilePathParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.()
	getMovementFilePathApi(params)
		.then((response) => {
			successCallback?.(response)
		})
		.catch((err) => {
			errorCallback?.(err)
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")))
			}
		})
}

export const setMovementMainPlanAction: ParameterType<setMovementMainPlanParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.()
	setMovementMainPlanApi(params)
		.then((response) => {
			successCallback?.(response)
		})
		.catch((err) => {
			errorCallback?.(err)
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")))
			}
		})
}

export const updateMovementPlanAction: ParameterType<updateMovementPlanPayload> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.()
	updateMovementPlanApi(params)
		.then((response) => {
			successCallback?.(response)
		})
		.catch((err) => {
			errorCallback?.(err)
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")))
			}
		})
}

export const downloadLaytimeAttachAction: ParameterType<downloadLaytimeAttachParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.()
	downloadLaytimeAttachApi(params)
		.then((response) => {
			successCallback?.(response)
		})
		.catch((err) => {
			errorCallback?.(err)
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")))
			}
		})
}

export const getMovementPlanDetailsAction: ParameterType<getMovementPlanDetailsParmas> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.()
	getMovementPlanDetailsApi(params)
		.then((response) => {
			successCallback?.(response)
		})
		.catch((err) => {
			errorCallback?.(err)
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")))
			}
		})
}

export const downloadMovementPnlAction: ParameterType<downloadMovementPnlParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.()
	downloadMovementPnlApi(params)
		.then((response) => {
			successCallback?.(response)
		})
		.catch((err) => {
			errorCallback?.(err)
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")))
			}
		})
}

export const getMovementTaskListAction: ParameterType<getMovementTaskListParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.()
	getMovementTaskListApi(params)
		.then((response) => {
			successCallback?.(response)
		})
		.catch((err) => {
			errorCallback?.(err)
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")))
			}
		})
}

export const getMovementVoyageTaskAction: ParameterType<getMovementVoyageTaskParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.()
	getMovementVoyageTaskApi(params)
		.then((response) => {
			successCallback?.(response)
		})
		.catch((err) => {
			errorCallback?.(err)
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")))
			}
		})
}

export const getMovementTaskCommentListAction: ParameterType<getMovementTaskCommentListParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.()
	getMovemenTaskCommentListApi(params)
		.then((response) => {
			successCallback?.(response)
		})
		.catch((err) => {
			errorCallback?.(err)
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")))
			}
		})
}

export const saveMovementTaskCommentAction: ParameterType<saveMovementTaskCommentParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.()
	saveMovementTaskCommentApi(params)
		.then((response) => {
			successCallback?.(response)
		})
		.catch((err) => {
			errorCallback?.(err)
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")))
			}
		})
}

export const deleteMovementTaskCommentAction: ParameterType<deleteMovementTaskCommentParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.()
	deleteMovementTaskCommentApi(params)
		.then((response) => {
			successCallback?.(response)
		})
		.catch((err) => {
			errorCallback?.(err)
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")))
			}
		})
}

export const updateMovementTaskItemAction: ParameterType<updateMovementTaskItemParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.()
	updateMovementTaskItemApi(params)
		.then((response) => {
			successCallback?.(response)
		})
		.catch((err) => {
			errorCallback?.(err)
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")))
			}
		})
}

export const initMovementDetailsAction: ParameterType<initMovementDetailsParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.()
	initMovementDetailsApi(params)
		.then((response) => {
			successCallback?.(response)
		})
		.catch((err) => {
			errorCallback?.(err)
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")))
			}
		})
}

export const saveMovementTaskConfigAction: ParameterType<saveMovementTaskConfigParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.()
	saveMovementTaskConfigApi(params)
		.then((response) => {
			successCallback?.(response)
		})
		.catch((err) => {
			errorCallback?.(err)
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")))
			}
		})
}

export const getMovementTctcAction: ParameterType<getMovementTctcParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.()
	getMovementTctcApi(params)
		.then((response) => {
			successCallback?.(response)
		})
		.catch((err) => {
			errorCallback?.(err)
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")))
			}
		})
}

export const getMovementDivisionAction: ParameterType<null> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.()
	getMovementDivisionApi()
		.then((response) => {
			successCallback?.(response)
		})
		.catch((err) => {
			errorCallback?.(err)
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")))
			}
		})
}

export const getBillDetailDataAction: ParameterType<getBillDetailDataParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.()
	getBillDetailDataApi(params)
		.then((response) => {
			successCallback?.(response)
		})
		.catch((err) => {
			errorCallback?.(err)
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")))
			}
		})
}

export const saveBillDetailDataAction: ParameterType<saveBillDetailDataParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.()
	saveBillDetailDataApi(params)
		.then((response) => {
			successCallback?.(response)
		})
		.catch((err) => {
			errorCallback?.(err)
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")))
			}
		})
}

export const uploadBlPortFileAction: ParameterType<uploadBlPortFileParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.()
	uploadBlPortFileApi(params)
		.then((response) => {
			successCallback?.(response)
		})
		.catch((err) => {
			errorCallback?.(err)
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")))
			}
		})
}

export const deleteBlPortFileAction: ParameterType<deleteBlPortFileParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.()
	deleteBlPortFileApi(params)
		.then((response) => {
			successCallback?.(response)
		})
		.catch((err) => {
			errorCallback?.(err)
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")))
			}
		})
}

export const downloadBlPortFileAction: ParameterType<downloadBlPortFileParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.()
	downloadBlPortFileApi(params)
		.then((response) => {
			successCallback?.(response)
		})
		.catch((err) => {
			errorCallback?.(err)
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")))
			}
		})
}

export const getInsureFormDataAction: ParameterType<getInsureFormDataParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.()
	getInsureFormDataApi(params)
		.then((response) => {
			successCallback?.(response)
		})
		.catch((err) => {
			errorCallback?.(err)
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")))
			}
		})
}

export const saveInsureFormDataAction: ParameterType<saveInsureFormDataParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.()
	saveInsureFormDataApi(params)
		.then((response) => {
			successCallback?.(response)
		})
		.catch((err) => {
			errorCallback?.(err)
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")))
			}
		})
}

export const getDepartmentDataAction: ParameterType<null> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.()
	getDepartmentDataApi()
		.then((response) => {
			successCallback?.(response)
		})
		.catch((err) => {
			errorCallback?.(err)
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")))
			}
		})
}

export const getOperatorListAction: ParameterType<getOperatorListParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.()
	getOperatorListApi(params)
		.then((response) => {
			successCallback?.(response)
		})
		.catch((err) => {
			errorCallback?.(err)
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")))
			}
		})
}

export const getCharterListAction: ParameterType<null> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.()
	getCharterListApi()
		.then((response) => {
			successCallback?.(response)
		})
		.catch((err) => {
			errorCallback?.(err)
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")))
			}
		})
}

export const getBlTableDataAction: ParameterType<getBlTableDataParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.()
	getBlTableDataApi(params)
		.then((response) => {
			successCallback?.(response)
		})
		.catch((err) => {
			errorCallback?.(err)
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")))
			}
		})
}

export const getInsureTableDataAction: ParameterType<getInsureTableDataParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.()
	getInsureTableDataApi(params)
		.then((response) => {
			successCallback?.(response)
		})
		.catch((err) => {
			errorCallback?.(err)
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")))
			}
		})
}

export const downloadInsureTableDataAction: ParameterType<getInsureTableDataParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.()
	downloadInsureTableDataApi(params)
		.then((response) => {
			successCallback?.(response)
		})
		.catch((err) => {
			errorCallback?.(err)
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")))
			}
		})
}

export const downloadBlTableDataAction: ParameterType<getBlTableDataParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.()
	downloadBlTableDataApi(params)
		.then((response) => {
			successCallback?.(response)
		})
		.catch((err) => {
			errorCallback?.(err)
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")))
			}
		})
}

export const getCurrentPlanAction: ParameterType<string> = (
	id,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.()
	getCurrentPlanApi(id)
		.then((response) => {
			successCallback?.(response)
		})
		.catch((err) => {
			errorCallback?.(err)
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")))
			}
		})
}

export const uploadEstPlanAction: ParameterType<FormData> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.()
	uploadEstPlanApi(params)
		.then((response) => {
			successCallback?.(response)
		})
		.catch((err) => {
			errorCallback?.(err)
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")))
			}
		})
}
