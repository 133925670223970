import { InvStatementHireItemType } from "./type"
import { InvCommonFieldInitEvent } from "@/pages/voyagemanage/business/invoices/module"

export const initialStatementHireItem: InvCommonFieldInitEvent<InvStatementHireItemType> = (
	type,
	item
) => {
	return {
		onHireEndDay: item?.onHireEndDay ?? null,
		onHireStartDay: item?.onHireStartDay ?? null,
		totalHire: item?.totalHire ?? 0,
		dailyHire: item?.dailyHire ?? "",
		ttlOnHireDay: item?.ttlOnHireDay ?? 0,
		// ttlOffHireDay: item?.ttlOffHireDay ?? 0,
		addCommRate: item?.addCommRate ?? "",
		brokerageRate: item?.brokerageRate ?? "",
		hireBrokerage: item?.hireBrokerage ?? 0,
		hireBrokerageRate: item?.hireBrokerageRate ?? "",
		brokerage: item?.brokerage ?? 0,
		addComm: item?.addComm ?? 0,
		hireCve: item?.hireCve ?? 0,
		hireCveRatio: item?.hireCveRatio ?? "",
		hireCveUnit: item?.hireCveUnit ?? "PM"
	}
}

export const checkKeys: (keyof InvStatementHireItemType)[] = [
	"onHireStartDay",
	"onHireEndDay",
	"dailyHire"
]
