import React, { Fragment } from "react"
import { FleetButton, FleetIcon, FleetSelect } from "@/components"
import commonStyle from "../../index.module.less"
import useIndex from "./hooks/useIndex"
import { Button } from "@/_components"
import { MovementTctcProps } from "./type"
import { movementTctcDialogOptions } from "./source"
import { useNavigate } from "react-router-dom"
import { useAppDispatch } from "@/hook"
import { setFirstSelected } from "@/store/globalSlice"
import { MovementVoaygeStatusOptions } from "../../source"
import classnames from "classnames"
import { MovementTctcBunkerPlan, MovementTctcVesselBasics } from "./components"
import {
	MovementCommission,
	MovementDisputeCharge,
	MovementFinancialData,
	MovementHandover,
	MovementHandoverFormSummary,
	MovementOffHire,
	MovementSettings,
	MovementSummary,
	MovementTask,
	MovementVoyageRemarks
} from "../../common"
import { CommonOtherExpenses, CommonOtherIncome } from "@/common"

const MovementTctcDetails: React.FC<MovementTctcProps> = ({
	voyageId,
	loading,
	onLoadingChange
}) => {
	const dispatch = useAppDispatch()
	const navigate = useNavigate()
	const {
		dialogGroup,
		handleDialogChange,
		otherItem,
		handleOtherItemChange,
		handleStatusChange,
		rentVesselBasics,
		handleRentVesselBasicCommit,
		handleRentVesselBasicChange,
		reletVesselBasics,
		handleReletVesselBasicCommit,
		handleReletVesselBasicChange,
		settingsItem,
		handleSettingsCommit,
		handleSettingsChange,
		saveMovementTctc,
		totalRevenues,
		totalProfitAndLoss,
		totalCost,
		totalDisputeCharge,
		handlePnlDownload,
		refresh,
		calculate,
		dialogRef,
		handleCommissionComfirm
	} = useIndex(voyageId, onLoadingChange)

	return (
		<Fragment>
			<div className="movementDetails-header">
				<div className="header-actions">
					<Button
						type="icon"
						size="small"
						onClick={() => {
							navigate("/layout/voyagemanage")
						}}
					>
						<FleetIcon type="FleetLpointerIcon" outerLayerClassname="rback-icon" />
					</Button>
					{movementTctcDialogOptions?.map((option, index) => {
						return (
							<Button
								size="small"
								key={index}
								type="secondary"
								onClick={() => {
									if (option?.disabled) return
									if (option?.value === "commission") {
										dialogRef?.current?.openModal(otherItem?.commissionList)
										return
									}
									handleDialogChange(option?.value, false)
								}}
								disabled={option?.disabled}
							>
								{option?.label}
							</Button>
						)
					})}
				</div>
				<div className="header-toolbar">
					<Button
						className="toolbar-secondary"
						size="small"
						prefixIcon="fleet-save"
						type="secondary"
						onClick={saveMovementTctc}
					>
						Save
					</Button>
					<span className="toolbar-line"></span>
					<FleetButton
						className="toolbar-secondary"
						size="small"
						icon="FleetInvoivesIcon"
						type="secondary"
						onClick={() => {
							dispatch(setFirstSelected("/layout/voyagemanage/invoices"))
							navigate(`/layout/voyagemanage/invoices?voyageId=${voyageId}&voyageType=TCVC`)
						}}
					>
						Invoives
					</FleetButton>
					<FleetButton
						size="small"
						icon="FleetRouteingIcon"
						onClick={() => {
							dispatch(setFirstSelected("/layout/monitoring"))
							navigate(
								`/layout/monitoring?id=${voyageId}&voyageNo=${otherItem?.voyageNo}&voyageType=TCVC`
							)
						}}
					>
						Routeing
					</FleetButton>
					<FleetSelect
						popupMatchSelectWidth={false}
						className="status-select"
						options={MovementVoaygeStatusOptions}
						value={[otherItem?.status]}
						optionRender={(options) => {
							return (
								<div className={commonStyle["statusSelect-warp"]}>
									<FleetIcon type={options?.data?.icon} outerLayerClassname="statusSelect-icon" />
									<span className={classnames("statusSelect-text", options?.data?.value)}>
										{options?.data?.label}
									</span>
								</div>
							)
						}}
						onChange={handleStatusChange}
					/>
				</div>
			</div>
			<div className="movementDetails-container fleet-nonScrolBar">
				<div className="movementDetails-scrollArea">
					<div className="movementDetails-voyageDetails">
						<div className="voyageDetails-container">
							<MovementTctcVesselBasics
								item={rentVesselBasics}
								onChange={handleRentVesselBasicChange}
								onCommit={handleRentVesselBasicCommit}
								type="rent"
								otherItem={otherItem}
							/>
							<MovementTctcVesselBasics
								item={reletVesselBasics}
								onChange={handleReletVesselBasicChange}
								onCommit={handleReletVesselBasicCommit}
								type="relet"
								otherItem={{ voyageNo: otherItem?.voyageNo }}
							/>
							<MovementSettings
								item={settingsItem}
								onChange={handleSettingsChange}
								onCommit={handleSettingsCommit}
								voyageType="TCTC"
							/>
							<MovementSummary voyageType="TCTC" item={calculate} />
							<MovementVoyageRemarks
								voyageType="TCTC"
								item={{
									highlightedPoint: otherItem?.highlightedPoint,
									voyageRemark: otherItem?.voyageRemark
								}}
								onChange={handleOtherItemChange}
							/>
						</div>
						<MovementFinancialData
							voyageType="TCTC"
							totalCostItem={totalCost}
							totalDisputeChargeItem={totalDisputeCharge}
							totalRevenuesItem={totalRevenues}
							onPnlDowload={handlePnlDownload}
							totalProfitItem={totalProfitAndLoss}
						/>
					</div>
					<MovementHandoverFormSummary voyageId={voyageId} voyageType="TCTC" />
				</div>
			</div>
			<MovementOffHire
				voyageId={voyageId}
				voyageType="TCTC"
				hide={dialogGroup?.offHire}
				onClose={() => handleDialogChange("offHire", true)}
			/>
			<CommonOtherExpenses
				type="movement"
				primaryId={voyageId}
				voyageType="TCTC"
				hide={dialogGroup?.otherExpenses}
				onConfirm={(items) => handleOtherItemChange?.("otherExpense", items)}
				onClose={() => handleDialogChange("otherExpenses", true)}
			/>
			<MovementHandover
				voyageId={voyageId}
				hide={dialogGroup?.handoverForm}
				onClose={() => handleDialogChange("handoverForm", true)}
				voyageType="TCTC"
			/>
			<CommonOtherIncome
				type="movement"
				voyageId={voyageId}
				voyageType="TCTC"
				hide={dialogGroup?.otherIncome}
				onConfirm={(voyageType, item) => {
					handleOtherItemChange?.("otherIncome", item)
				}}
				onClose={() => handleDialogChange("otherIncome", true)}
			/>
			<MovementDisputeCharge
				voyageId={voyageId}
				voyageType="TCTC"
				hide={dialogGroup?.disputeCharge}
				onConfirm={handleOtherItemChange}
				onClose={() => handleDialogChange("disputeCharge", true)}
			/>
			<MovementTctcBunkerPlan
				voyageId={voyageId}
				hide={dialogGroup?.bunkerPlan}
				onClose={() => handleDialogChange("bunkerPlan", true)}
				onConfirm={handleOtherItemChange}
			/>
			<MovementCommission
				ref={dialogRef}
				option={{
					voyageId: voyageId,
					voyageType: "TCTC"
				}}
				onConfirm={handleCommissionComfirm}
			/>
			<MovementTask
				activeVoyageType="TCTC"
				voyageId={voyageId}
				refresh={refresh}
				onOpenDialog={handleDialogChange}
			/>
		</Fragment>
	)
}

export default MovementTctcDetails
