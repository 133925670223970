import React, { FC } from "react"
import style from "./index.module.less"
import classnames from "classnames"
import charterBaseStyle from "../index.module.less"
import MovementTable from "../../common/table"
import { FleetInput } from "@/components"
import { MovementPortRotationProps } from "./type"
import useCalcIndex from "./hooks/useCalcIndex"
import { Button } from "@/_components"
import { FleetTabbar } from "@/components/Tabs"

const MovementPortRotation: FC<MovementPortRotationProps> = ({
	activeType,
	actualItems,
	estItems,
	onActualChange,
	onActualCommit,
	onActualDelete,
	onActualAdd,
	onActualMove,
	vesselSource,
	onEstOtherChange,
	estOtherItem,
	onEstChange,
	onEstCommit,
	onEstDelete,
	onEstAdd,
	onEstPortCommit
}) => {
	const { columns, dataSource, summary } = useCalcIndex(
		activeType,
		actualItems,
		estItems,
		estOtherItem
	)

	return (
		<div
			className={classnames(
				charterBaseStyle["movement-charterBase--container"],
				style["movement-portRotation"]
			)}
		>
			<div className="portRotation-container">
				<div className="portRotation-container--toolbars">
					<div className="movement-details--header">
						<span className="fleet-title">Port Rotation</span>
					</div>
					<div className="toolbars">
						{activeType === "est" && (
							<>
								<div className="toolbars-item">
									<span>Ballast Dis Margin (%)</span>
									<FleetInput
										disabled={estOtherItem?.ballastMarginFlag === 1}
										value={estOtherItem?.ballastMarginDistance}
										type="number"
										min={0}
										max={100}
										className="toolbars-input"
										onChange={(val: number) => {
											onEstOtherChange?.("ballastMarginDistance", val)
										}}
									/>
								</div>
								<div className="toolbars-item">
									<span>Ballast Day Margin</span>
									<FleetInput
										type="number"
										disabled={estOtherItem?.ballastMarginFlag === 0}
										value={estOtherItem?.ballastMarginDay}
										onChange={(val: number) => {
											onEstOtherChange?.("ballastMarginDay", val)
										}}
										className="toolbars-input"
									/>
								</div>

								<div className="toolbars-item">
									<FleetTabbar
										value={estOtherItem?.ballastMarginFlag === 0 ? "Dis" : "Day"}
										list={["Dis", "Day"]}
										onChange={(val: "Dis" | "Day") => {
											onEstOtherChange?.("ballastMarginFlag", val === "Dis" ? 0 : 1)
										}}
									/>
								</div>
								<div className="toolbars-item">
									<span>Laden Dis Margin (%)</span>
									<FleetInput
										type="number"
										min={0}
										max={100}
										disabled={estOtherItem?.ladenMarginFlag === 1}
										value={estOtherItem?.ladenMarginDistance}
										onChange={(val: number) => {
											onEstOtherChange?.("ladenMarginDistance", val)
										}}
										className="toolbars-input"
									/>
								</div>
								<div className="toolbars-item">
									<span>Laden Day Margin</span>
									<FleetInput
										type="number"
										disabled={estOtherItem?.ladenMarginFlag === 0}
										value={estOtherItem?.ladenMarginDay}
										onChange={(val: number) => {
											onEstOtherChange?.("ladenMarginDay", val)
										}}
										className="toolbars-input"
									/>
								</div>
								<div className="toolbars-item">
									<FleetTabbar
										value={estOtherItem?.ladenMarginFlag === 0 ? "Dis" : "Day"}
										list={["Dis", "Day"]}
										onChange={(val: "Dis" | "Day") => {
											onEstOtherChange?.("ladenMarginFlag", val === "Dis" ? 0 : 1)
										}}
									/>
								</div>
							</>
						)}
						{/* <div className="toolbars-item">
            <FleetFormItem type="row" label="Time zone">
              <FleetTabbar
                list={["LT", "UTC"]}
              />
            </FleetFormItem>
          </div> */}
						<Button
							type="secondary"
							size="small"
							className="add-port-button"
							onClick={() => {
								if (activeType === "actual") {
									onActualAdd?.()
									return
								}
								onEstAdd?.()
							}}
						>
							Add Port
						</Button>
					</div>
				</div>
				<MovementTable
					className={classnames("movement-charterBase--table", "portRotation-container--portGroup")}
					columns={columns}
					summary={summary}
					otherSource={{
						vesselSource: vesselSource,
						total: dataSource?.length
					}}
					dataSource={dataSource}
					actions={{
						onChange: activeType === "actual" ? onActualChange : onEstChange,
						onCommit: activeType === "actual" ? onActualCommit : onEstCommit,
						onDelete: activeType === "actual" ? onActualDelete : onEstDelete,
						onMove: onActualMove,
						onPortCommit: onEstPortCommit
					}}
				/>
			</div>
		</div>
	)
}

export default MovementPortRotation
