import React, { FC } from "react"
import style from "./index.module.less"
import classnames from "classnames"
import { InvFreightSettlementProps, InvFreightSettlementType } from "./type"
import { InvFreightPort } from "./components"
import { FleetVerifyInput } from "@/components"
import { FleetTabbar } from "@/components/Tabs"
import { calcFreightValues } from "./tools"
import { InvoiceAddTo } from "@/pages/voyagemanage/business/invoices/module"
import { formatThousandthNumber } from "@/tools/amount"

const InvFreightSettlement: FC<InvFreightSettlementProps> = ({
	item,
	onCommit,
	loadPortItems,
	dischargePortItems,
	onPortItemChange,
	onPortItemCommit,
	otherItem,
	onOtherItemChange
}) => {
	return (
		<div className={classnames("invoicesDetail-form", style["invFreight-settlement"])}>
			<div className="invoicesForm-header">
				<span>Settlement</span>
				<span>Full cost settlement</span>
			</div>
			<InvoiceAddTo
				type="addFreightIncome"
				value={otherItem?.addFreightIncome}
				onChange={onOtherItemChange}
			>
				<span className="invoicesForm-subheading">Freight Income</span>
			</InvoiceAddTo>
			<div className="invoicesForm-container">
				<div className="invoicesForm-freightRate">
					<FleetVerifyInput
						label={`${item?.freightType === "FR" ? "Freight Rate" : "Lumpsum"}`}
						value={item?.freightRate}
						needVerity
						type="number"
						onChange={(val: number) => {
							const params = calcFreightValues(item, "freightRate", val)
							onCommit?.(params)
						}}
					/>
					<FleetTabbar
						// disabled
						list={["FR", "LP"]}
						value={item?.freightType}
						onChange={(val: InvFreightSettlementType["freightType"]) => {
							const params = calcFreightValues(item, "freightType", val)
							onCommit?.(params)
						}}
					/>
				</div>
				<FleetVerifyInput
					value={item?.ttlCargoQuantity}
					type="number"
					needVerity
					disabled={otherItem?.addCargo}
					onChange={(val: number) => {
						onCommit?.({ ttlCargoQuantity: val })
					}}
					label="TTL Cargo Quantity (MT)"
				/>
				<FleetVerifyInput
					label="Payment Ratio(%)"
					value={item?.freightPercent}
					needVerity
					type="number"
					max={100}
					min={0}
					onChange={(val: number) => {
						const params = calcFreightValues(item, "freightPercent", val)
						onCommit?.(params)
					}}
				/>
				<FleetVerifyInput
					value={item?.freightIncome}
					needVerity
					disabled
					type="number"
					label="Freight Income ($)"
				/>
			</div>
			<span className="invoicesForm-subheading">Others</span>
			<div className="invoicesForm-container">
				<FleetVerifyInput
					value={item?.addCommRate}
					containerClassname="freight-unit"
					max={100}
					label="Add. Comm.（%）"
					min={0}
					type="number"
					onChange={(val: number) => {
						const addComm =
							(+item?.freightRate * +item?.ttlCargoQuantity * +item?.addCommPaymentRatio * +val) /
							10000
						onCommit?.({
							addCommRate: val,
							addComm: +addComm?.toFixed(3)
						})
					}}
				/>
				<FleetVerifyInput
					value={item?.addCommPaymentRatio}
					containerClassname="freight-unit"
					max={100}
					label="Add. Comm. Payment Ratio(%)"
					min={0}
					type="number"
					onChange={(val: number) => {
						const addComm =
							(+item?.freightRate * +item?.ttlCargoQuantity * +item?.addCommRate * +val) / 10000
						onCommit?.({
							addCommPaymentRatio: val,
							addComm: +addComm?.toFixed(3)
						})
					}}
				/>
				<FleetVerifyInput value={item?.addComm} type="number" disabled label="Add. Comm. ($)" />
			</div>
			<div className="invoicesForm-container">
				<FleetVerifyInput
					value={item?.freightBrokerageRate}
					containerClassname="freight-unit"
					label="Brokerage Rate（%）"
					max={100}
					min={0}
					type="number"
					onChange={(val: number) => {
						const freightBrokerage = (+item?.freightRate * +item?.ttlCargoQuantity * +val) / 100
						onCommit?.({
							freightBrokerageRate: val,
							freightBrokerage: +freightBrokerage?.toFixed(3)
						})
					}}
				/>
				<FleetVerifyInput
					value={item?.freightBrokerage}
					type="number"
					label="Brokerage ($)"
					disabled
				/>
			</div>
			<ul className="w-full flex flex-col gap-[4px]">
				<li className="text-[14px] leading-[22px] font-normal">
					<span className="text-[#A3A3A3]">Freight Income: </span>
					<span className="text-[#7D7D7D]">
						{item?.freightRate} $/MT * {formatThousandthNumber(+item?.ttlCargoQuantity)} MT *{" "}
						{item?.freightPercent}% = {formatThousandthNumber(item?.freightIncome)} $
					</span>
				</li>
				<li className="text-[14px] leading-[22px] font-normal">
					<span className="text-[#A3A3A3]">Add.Comm.: </span>
					<span className="text-[#7D7D7D]">
						{item?.freightRate} $/MT * {formatThousandthNumber(+item?.ttlCargoQuantity)} MT *{" "}
						{item?.addCommRate || 0}% *{item?.addCommPaymentRatio ?? 0}% ={" "}
						{formatThousandthNumber(item?.addComm)} $
					</span>
				</li>
				<li className="text-[14px] leading-[22px] font-normal">
					<span className="text-[#A3A3A3]">Brokerage: </span>
					<span className="text-[#7D7D7D]">
						{" "}
						{item?.freightRate} $/MT * {formatThousandthNumber(+item?.ttlCargoQuantity)} MT *{" "}
						{item?.freightBrokerageRate || 0}% = {formatThousandthNumber(item?.freightBrokerage)} $
					</span>
				</li>
			</ul>
			<InvFreightPort
				loadItems={loadPortItems}
				dischargeItems={dischargePortItems}
				onChange={onPortItemChange}
				onCommit={onPortItemCommit}
				otherItem={otherItem}
				onOtherItemChange={onOtherItemChange}
			/>
			{/* <span className="invoicesRate-computed">
				Tips: Ocean Freight= Cargo Quantity (N%)* Freight Rate;{" "}
			</span> */}
		</div>
	)
}

export default InvFreightSettlement
