export const voyageList = [
	{ label: "Arrival Day&Time", prop: "arrivalDateTime" },
	{ label: "Name of Berth", prop: "nameOfBerth" },
	{ label: "NOR TENDERED", prop: "norTendered" },
	{ label: "LOAD COMMENCED", prop: "loadDishCommenced" },
	{ label: "LOAD COMPLETED", prop: "loadDishCompleted" }
]

export enum PurposeMap {
	DL = "Delivery",
	RD = "Redelivery",
	LD = "Load",
	LB = "Load Bunker",
	LT = "Load Transit",
	DC = "Discharge",
	DB = "Discharge Bunker",
	DT = "Discharge Transit"
}
