import React, { FC, Fragment, useMemo } from "react"
import { FleetFormItem, FleetSelect, FleetVerifyInput } from "@/components"
import { invStatementBunkerOptions } from "./source"
import { InvStatementBunkerProps, InvStatementBunkerItemType } from "./type"
import { Icon } from "@/_components"
import { cn } from "@/tools"

const InvStatementBunker: FC<InvStatementBunkerProps> = ({
	title,
	items,
	disableBunkers,
	onCommit,
	onDelete,
	labelClassname
}) => {
	const options = useMemo(() => {
		return invStatementBunkerOptions?.filter((option) => !disableBunkers?.includes(option?.value))
	}, [disableBunkers])
	return (
		<div className="w-full flex flex-col gap-2">
			<span className={cn("text-[#a3a3a3] text-[12px] leading-[20px] font-normal", labelClassname)}>
				{title}
			</span>
			<div className="invoicesForm-container">
				{items?.map((item, index) => {
					return (
						<Fragment key={index}>
							<FleetFormItem label="Bunker Type" needVerity>
								<FleetSelect
									value={[item?.bunkerType]}
									options={options}
									onChange={(val: InvStatementBunkerItemType["bunkerType"]) => {
										onCommit?.(
											{ bunkerType: item?.bunkerType, type: item?.type },
											{
												bunkerType: val
											}
										)
									}}
								/>
							</FleetFormItem>
							<FleetVerifyInput
								value={item?.consumption}
								min={0}
								type="number"
								label={`${item?.bunkerType} On Board (MT)`}
								needVerity
								onChange={(val: number) => {
									const totalValue = val * +item?.price
									onCommit?.(
										{ bunkerType: item?.bunkerType, type: item?.type },
										{
											total: +totalValue?.toFixed(3),
											consumption: val
										}
									)
								}}
							/>
							<FleetVerifyInput
								value={item?.price}
								type="number"
								min={0}
								label={`${item?.bunkerType} Price`}
								needVerity
								onChange={(val: number) => {
									const totalValue = val * +item?.consumption
									onCommit?.(
										{ bunkerType: item?.bunkerType, type: item?.type },
										{
											price: val,
											total: +totalValue?.toFixed(3)
										}
									)
								}}
							/>
							<div className="grid grid-cols-[1fr_32px] gap-1">
								<FleetVerifyInput
									value={item?.total}
									type="number"
									disabled
									needVerity
									label="Total Value"
								/>
								<Icon
									type="fleet-minus"
									className="self-end opacity-40 cursor-pointer"
									size={32}
									onClick={() => {
										onDelete?.({
											bunkerType: item?.bunkerType,
											type: item?.type
										})
									}}
								/>
							</div>
						</Fragment>
					)
				})}
			</div>
		</div>
	)
}

export default InvStatementBunker
