import {
	LaytimeTreeNode,
	MovementCalculatorFormChangeEvent,
	MovementCalculatorRangeDateChangeEvent,
	MovementExceptFormChangeEvent,
	MovementExceptFormComiitEvent,
	MovementExceptFormType,
	MovementExceptFormeCrudEvent,
	MovementExceptRangeComiitEvent,
	MovementLaytimeCalculatorType,
	MovementLaytimeTreeNode,
	initialExceptForm
} from "../components/calculator/type"
import useReminder from "@/hook/useReminder"
import { uuid } from "@/tools"
import { log } from "console"
import dayjs, { Dayjs } from "dayjs"
import { MutableRefObject, useMemo, useRef, useState } from "react"

type LaytimeSourceTree = {
	[date: string]: MovementLaytimeTreeNode
}

export const convertArrayToNode = (laytimeGroup: MovementLaytimeCalculatorType[]) => {
	let exceptItem: MovementExceptFormType[] = [initialExceptForm()]
	let newLaytimeGroup: {
		[date: string]: MovementLaytimeTreeNode
	} = {}
	let currentExceptIndex = 0,
		uid = "",
		loopIndex = 0,
		workDate = "",
		currentRangeDate = Number.MIN_VALUE,
		currentLaytimeNode: any

	let rangeSource: [Dayjs | undefined, Dayjs | undefined] = [undefined, undefined]

	while (loopIndex <= laytimeGroup?.length - 1) {
		let laytimeItem = laytimeGroup?.[loopIndex]
		if (workDate !== laytimeItem?.workDate) {
			workDate = laytimeItem?.workDate
			uid = uuid()
			currentLaytimeNode = new LaytimeTreeNode(
				laytimeItem?.workFrom,
				laytimeItem?.workTo,
				laytimeItem?.status,
				uid
			)
			newLaytimeGroup[workDate] = currentLaytimeNode
		} else {
			currentLaytimeNode.next = new LaytimeTreeNode(
				laytimeItem?.workFrom,
				laytimeItem?.workTo,
				laytimeItem?.status,
				uid
			)
			currentLaytimeNode = currentLaytimeNode.next
		}

		if (laytimeItem?.status === 0) {
			exceptItem[currentExceptIndex++] = {
				date: dayjs(laytimeItem?.workDate),
				startTime: +laytimeItem?.workFrom,
				endTime: +laytimeItem?.workTo,
				status: laytimeItem?.status,
				uuid: uid,
				isSelect: laytimeItem?.isSelect
			}
		}

		loopIndex++
	}
	let startLaytime = laytimeGroup?.[0],
		endLaytime = laytimeGroup?.at(-1)
	rangeSource = [
		startLaytime
			? dayjs(startLaytime?.workDate).set("millisecond", +startLaytime?.workFrom)
			: undefined,
		endLaytime ? dayjs(endLaytime?.workDate).set("millisecond", +endLaytime?.workTo) : undefined
	]
	return {
		laytimeItem: newLaytimeGroup,
		exceptItem: exceptItem,
		rangeSource
	}
}

export const convertNodeToArray = (
	headNode: MovementLaytimeTreeNode,
	originLaytime: MovementLaytimeCalculatorType[],
	workDate: string,
	portIndex: number
) => {
	console.log("111headLaytimeNode", headNode)
	console.log("111activeLaytime", originLaytime)
	console.log("111currentDate", workDate)
	console.log("111activeIndex", portIndex)
	const arr: MovementLaytimeCalculatorType[] = []
	let current = headNode
	while (current !== null) {
		const { next, ...rest } = current
		const laytimeItem = originLaytime.find(
			(item) => item?.workFrom === rest?.startTime && item?.workTo === rest?.endTime
		)
		const diffInMilliseconds = rest?.endTime - rest?.startTime
		const hours = Math.floor(diffInMilliseconds / 3600000)
		const minutes = Math.floor((diffInMilliseconds % 3600000) / 60000)
		const isFullDays = diffInMilliseconds >= 86340000
		const newlaytimeItem: MovementLaytimeCalculatorType = {
			calcRate: laytimeItem?.calcRate ?? (rest?.status === 0 ? 0 : 100),
			remark: laytimeItem?.remark ?? "",
			isSelect: laytimeItem?.isSelect ?? 0,
			status: rest?.status,
			workDate: workDate,
			week: dayjs(workDate)?.format("ddd"),
			timeUsedD: rest?.status === 0 ? 0 : isFullDays ? 1 : 0,
			timeUsedH: rest?.status === 0 ? 0 : isFullDays ? 0 : hours,
			timeUsedM: rest?.status === 0 ? 0 : isFullDays ? 0 : minutes,
			workFrom: rest?.startTime,
			workTo: rest?.endTime,
			movePrId: portIndex
		}
		laytimeItem?.id && (newlaytimeItem.id = laytimeItem?.id)
		arr.push(newlaytimeItem)
		current = current?.next
	}
	return arr
}

const initLaytimeNode = (
	startTime: Dayjs,
	endTime: Dayjs,
	nodeTree: MutableRefObject<LaytimeSourceTree>,
	portIndex: number
) => {
	if (!startTime || !endTime) return []
	const days: MovementLaytimeCalculatorType[] = []
	let current = startTime
	while (current.isBefore(endTime) || current.isSame(endTime, "day")) {
		const workDate = current.format("YYYY-MM-DD")
		const workStartTime = current.isSame(startTime)
			? current?.startOf("minute")
			: current?.startOf("minute")?.set("hour", 0).set("minute", 0).set("second", 0)
		const workEndTime = current.isSame(endTime, "day")
			? endTime?.startOf("minute")
			: current?.startOf("minute")?.set("hour", 24).set("minute", 0)
		const diffInMilliseconds = workEndTime.diff(workStartTime, "millisecond")
		if (diffInMilliseconds <= 0) {
			current = current.add(1, "day")
			continue
		}
		const hours = Math.floor(diffInMilliseconds / 3600000)
		const minutes = Math.floor((diffInMilliseconds % 3600000) / 60000)
		const isFullDays = diffInMilliseconds >= 86340000
		const workFrom =
			(workStartTime.hour() * 60 + workStartTime.minute()) * 60 * 1000 +
			workStartTime.second() * 1000 +
			workStartTime.millisecond()
		const week = current.format("ddd")

		let newlaytimeItem: MovementLaytimeCalculatorType = {
			workDate: workDate,
			workFrom: workFrom,
			workTo: workFrom + diffInMilliseconds,
			calcRate: 100,
			timeUsedD: isFullDays ? 1 : 0,
			timeUsedH: isFullDays ? 0 : hours,
			timeUsedM: isFullDays ? 0 : minutes,
			remark: "",
			status: 1,
			week: week,
			movePrId: portIndex,
			isSelect: 0
		}
		days.push(newlaytimeItem)
		nodeTree.current = {
			...nodeTree.current,
			[workDate]: new LaytimeTreeNode(workFrom, workFrom + diffInMilliseconds, 1)
		}
		current = current.add(1, "day")
	}

	console.log("days", days)

	return days
}

const useLaytimeGroup = () => {
	// 当前港口标识
	const { reminder } = useReminder()
	const [activeIndex, setActiveIndex] = useState<number>(null)
	// const { username } = useSelector((state: UserStore) => state.userInfo);
	// 存储时间段数据
	const [laytimeDataSource, setLaytimeDataSource] = useState<MovementLaytimeCalculatorType[]>([])

	// 存储操作栏数据/排除的时间段数据
	const [exceptDate, setExceptDate] = useState<MovementExceptFormType[]>([])

	// 时间段数据链表，描述时间段的关联结构
	const laytimeSourceTree = useRef<LaytimeSourceTree>({})

	// 存储时间边界数据
	const [rangeSource, setRangeSource] = useState<[Dayjs | undefined, Dayjs | undefined]>([
		undefined,
		undefined
	])

	const handleTransforExcept = (rangeDate: [Dayjs, Dayjs], items: MovementExceptFormType[]) => {
		let currentItems: MovementExceptFormType[] = []
		console.log("rangeDate", rangeDate, items)
		for (let exceptItem of items) {
			if (
				exceptItem?.date?.isBefore(rangeDate?.[0], "day") ||
				exceptItem?.date?.isAfter(rangeDate?.[1], "day")
			) {
				continue
			}
			const startTime = exceptItem?.date?.add(exceptItem?.startTime, "millisecond"),
				endTime = exceptItem?.date?.add(exceptItem?.endTime, "millisecond")
			console.log("start", {
				start: rangeDate?.[0]?.isSame(startTime) || rangeDate?.[0]?.isBefore(startTime),
				end: rangeDate?.[1]?.isSame(endTime) || rangeDate?.[1]?.isAfter(endTime)
			})
			if (
				(rangeDate?.[0]?.isSame(startTime) || rangeDate?.[0]?.isBefore(startTime)) &&
				(rangeDate?.[1]?.isSame(endTime) || rangeDate?.[1]?.isAfter(endTime))
			) {
				currentItems?.push(exceptItem)
			}
		}
		return currentItems
	}

	const handleRangeChange: MovementCalculatorRangeDateChangeEvent = (id, vals) => {
		console.log("laytimeSourceTree", {
			laytimeSourceTree,
			vals,
			exceptDate
		})
		debugger
		// return;
		const originExpectDate = [...exceptDate]
		laytimeSourceTree.current = {}
		setRangeSource(vals ?? [undefined, undefined])
		const currentLaytime = initLaytimeNode(vals?.[0], vals?.[1], laytimeSourceTree, activeIndex)
		setLaytimeDataSource(currentLaytime)
		// setExceptDate([initialExceptForm()]);
		const tranferDate = handleTransforExcept(vals, originExpectDate)
		const { finalExceptDate, finalLaytime } = handleLaytimeNodeLoop(tranferDate, currentLaytime)
		setExceptDate(finalExceptDate?.length <= 0 ? [initialExceptForm()] : [...finalExceptDate])
		setLaytimeDataSource(finalLaytime?.length <= 0 ? currentLaytime : finalLaytime)
		// handleTransforExcept(vals, originExpectDate);
	}

	const handleExpectDateChange: MovementExceptFormChangeEvent = (index, key, val) => {
		if (key === "date") {
			const exceptItem = exceptDate?.[index]
			const rangeItem = rangeSource
			const value = val as Dayjs
			if (
				value.isSame(rangeItem?.[1], "day") &&
				rangeItem?.[1]?.hour() === 0 &&
				rangeItem?.[1]?.minute() === 0 &&
				rangeItem?.[1]?.second() === 0
			) {
				reminder("warning", "Out of selection range")
				return
			}

			if (
				(value.isAfter(rangeItem?.[0], "day") || value.isSame(rangeItem?.[0], "day")) &&
				(value.isBefore(rangeItem?.[1], "day") || value.isSame(rangeItem?.[1], "day"))
			) {
				exceptItem?.date &&
					setExceptDate((prev) => {
						prev.push({
							date: value,
							startTime: 0,
							endTime: 0,
							isSelect: 0
						})
						return [...prev]
					})
				!exceptItem?.date &&
					setExceptDate((prev) => {
						const currentExceptItem = prev?.[index]
						currentExceptItem[key] = val
						prev.splice(index, 1, { ...currentExceptItem })
						return [...prev]
					})
			} else {
				reminder("warning", "Out of selection range")
			}
			return
		}

		setExceptDate((prev) => {
			const currentItem = prev?.[index]
			prev?.splice(index, 1, {
				...currentItem,
				[key]: val
			})
			return [...prev]
		})
	}

	const handleLaytimeChange: MovementCalculatorFormChangeEvent = (index, key, value) => {
		switch (key) {
			case "remark":
				setLaytimeDataSource((prev) => {
					const laytimeItem = prev[index]
					laytimeItem[key] = value
					prev.splice(index, 1, laytimeItem)
					return [...prev]
				})
				break
			case "calcRate":
				setLaytimeDataSource((prev) => {
					const laytimeItem = prev[index]
					laytimeItem[key] = value
					const diffInMilliseconds =
						value == 0 ? 0 : (+laytimeItem?.workTo - +laytimeItem?.workFrom) * (+value / 100)
					const days = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24))
					console.log("diffInMilliseconds", diffInMilliseconds, days)
					const hours = Math.floor((diffInMilliseconds % 86400000) / 3600000)
					const minutes = Math.floor(((diffInMilliseconds % 86400000) % 3600000) / 60000)
					laytimeItem.timeUsedD = days >= 1 ? days : 0
					laytimeItem.timeUsedH = hours
					laytimeItem.timeUsedM = minutes
					console.log("currentLaytimeGroup", laytimeItem)
					prev.splice(index, 1, laytimeItem)
					return [...prev]
				})
			default:
				break
		}
	}

	const handleLaytimeNodeLoop = (
		currentExceptDates: MovementExceptFormType[],
		currentLaytime: MovementLaytimeCalculatorType[]
	) => {
		const finalExceptDate: MovementExceptFormType[] = []
		let finalLaytime: MovementLaytimeCalculatorType[] = [...currentLaytime]
		for (let index = 0; index <= currentExceptDates?.length - 1; index++) {
			const currentExceptItem = currentExceptDates?.[index]
			const currentExceptDate = currentExceptItem?.date?.format("YYYY-MM-DD")
			let currentLaytimeNode = laytimeSourceTree?.current?.[currentExceptDate]
			let headLaytimeNode = currentLaytimeNode
			let startTime = currentExceptItem?.startTime
			let endTime = currentExceptItem?.endTime
			let isLoop = true
			let isInsert = false
			let uid = currentExceptItem?.uuid
			debugger
			while (currentLaytimeNode && isLoop) {
				// debugger
				switch (true) {
					case currentLaytimeNode.status === 0 &&
						startTime == currentLaytimeNode?.startTime &&
						startTime < currentLaytimeNode?.endTime &&
						endTime < currentLaytimeNode?.endTime:
					case currentLaytimeNode.status === 0 &&
						startTime == currentLaytimeNode?.startTime &&
						startTime < currentLaytimeNode?.endTime &&
						endTime == currentLaytimeNode?.endTime:
					case currentLaytimeNode.status === 0 &&
						startTime > currentLaytimeNode?.startTime &&
						startTime < currentLaytimeNode?.endTime &&
						endTime == currentLaytimeNode?.endTime:
					case currentLaytimeNode.status === 0 &&
						startTime > currentLaytimeNode?.startTime &&
						startTime < currentLaytimeNode?.endTime &&
						endTime < currentLaytimeNode?.endTime:
						reminder("warning", "This period has been excluded")
						isLoop = false
						break
					case startTime < currentLaytimeNode?.startTime &&
						endTime > currentLaytimeNode?.startTime &&
						endTime < currentLaytimeNode?.endTime:
					case startTime > currentLaytimeNode?.startTime &&
						startTime < currentLaytimeNode?.endTime &&
						endTime > currentLaytimeNode?.endTime:
					case startTime < currentLaytimeNode?.startTime && endTime > currentLaytimeNode?.endTime:
					case !currentLaytimeNode.next && startTime < currentLaytimeNode?.startTime:
						reminder("warning", "Wrong time selected")
						isLoop = false
						break
					case currentLaytimeNode?.status === 1 &&
						startTime == currentLaytimeNode?.startTime &&
						endTime == currentLaytimeNode?.endTime:
						currentLaytimeNode.status = 0
						uid = uuid()
						currentLaytimeNode.uuid = uid
						isLoop = false
						isInsert = true
						break
					case currentLaytimeNode?.status === 1 &&
						startTime > currentLaytimeNode?.startTime &&
						endTime <= currentLaytimeNode?.endTime:
						const currentEndTime = currentLaytimeNode?.endTime
						currentLaytimeNode.endTime = startTime
						currentLaytimeNode.uuid = uid
						const newNode = new LaytimeTreeNode(startTime, endTime, 0, uid)
						newNode.next = new LaytimeTreeNode(
							endTime,
							currentEndTime,
							1,
							uid,
							currentLaytimeNode.next
						)
						currentLaytimeNode.next = newNode
						isLoop = false
						isInsert = true
						break
					case currentLaytimeNode?.status === 1 &&
						startTime == currentLaytimeNode?.startTime &&
						endTime < currentLaytimeNode?.endTime:
						const currentEndTime_1 = currentLaytimeNode?.endTime
						uid = uuid()
						currentLaytimeNode.startTime = startTime
						currentLaytimeNode.endTime = endTime
						currentLaytimeNode.uuid = uid
						currentLaytimeNode.status = 0
						const newNode_1 = new LaytimeTreeNode(endTime, currentEndTime_1, 1, uid)
						newNode_1.next = currentLaytimeNode.next
						currentLaytimeNode.next = newNode_1
						isLoop = false
						isInsert = true
						break
					case currentLaytimeNode?.status === 1 &&
						startTime > currentLaytimeNode?.startTime &&
						endTime == currentLaytimeNode?.endTime:
						const currentEndTime_2 = currentLaytimeNode?.endTime
						uid = uuid()
						currentLaytimeNode.endTime = startTime
						currentLaytimeNode.uuid = uid
						currentLaytimeNode.status = 1
						const newNode_2 = new LaytimeTreeNode(startTime, currentEndTime_2, 0, uid)
						newNode_2.next = currentLaytimeNode.next
						currentLaytimeNode.next = newNode_2
						isLoop = false
						isInsert = true
						break
					case endTime >= currentLaytimeNode?.endTime:
						currentLaytimeNode = currentLaytimeNode?.next
						break
					default:
						isLoop = false
						// console.warn("Not matched!");
						break
				}
			}
			if (isInsert) {
				finalExceptDate?.push({ ...currentExceptItem, uuid: uid, status: 0 })
				const activeLaytime = finalLaytime?.filter((item) => item?.workDate === currentExceptDate)
				const updateIndex = finalLaytime?.findIndex((item) => item.workDate === currentExceptDate)
				const resetLaytime = finalLaytime?.filter((item) => !(item?.workDate === currentExceptDate))
				resetLaytime?.splice(
					updateIndex,
					0,
					...convertNodeToArray(headLaytimeNode, activeLaytime, currentExceptDate, activeIndex)
				)
				finalLaytime = [...resetLaytime]
			}
		}
		return {
			finalLaytime,
			finalExceptDate
		}
	}

	const handleExpectDateCommit: MovementExceptRangeComiitEvent = (
		index,
		newExpectItem,
		originExpectItem
	) => {
		const currentExceptItem = exceptDate?.[index]
		const currentExceptDate = currentExceptItem?.date?.format("YYYY-MM-DD")
		let currentLaytimeNode = laytimeSourceTree?.current?.[currentExceptDate]
		let headLaytimeNode = currentLaytimeNode
		let startTime = newExpectItem?.startTime
		let endTime = newExpectItem?.endTime
		let isLoop = true
		let isInsert = false
		let uid = originExpectItem?.uuid
		while (currentLaytimeNode && isLoop) {
			//debugger

			switch (true) {
				case uid && uid == currentLaytimeNode?.uuid:
					let loopCurrentItem = currentLaytimeNode.next
					while (loopCurrentItem && loopCurrentItem?.uuid === uid && loopCurrentItem?.next) {
						loopCurrentItem = loopCurrentItem?.next
					}
					currentLaytimeNode.uuid = undefined
					currentLaytimeNode.status = 1
					currentLaytimeNode.next = loopCurrentItem?.next
					currentLaytimeNode.endTime = loopCurrentItem?.endTime
					break
				case currentLaytimeNode.status === 0 &&
					startTime == currentLaytimeNode?.startTime &&
					startTime < currentLaytimeNode?.endTime &&
					endTime < currentLaytimeNode?.endTime:
				case currentLaytimeNode.status === 0 &&
					startTime == currentLaytimeNode?.startTime &&
					startTime < currentLaytimeNode?.endTime &&
					endTime == currentLaytimeNode?.endTime:
				case currentLaytimeNode.status === 0 &&
					startTime > currentLaytimeNode?.startTime &&
					startTime < currentLaytimeNode?.endTime &&
					endTime == currentLaytimeNode?.endTime:
				case currentLaytimeNode.status === 0 &&
					startTime > currentLaytimeNode?.startTime &&
					startTime < currentLaytimeNode?.endTime &&
					endTime < currentLaytimeNode?.endTime:
					reminder("warning", "This period has been excluded")
					isLoop = false
					break
				case startTime < currentLaytimeNode?.startTime &&
					endTime > currentLaytimeNode?.startTime &&
					endTime < currentLaytimeNode?.endTime:
				case startTime > currentLaytimeNode?.startTime &&
					startTime < currentLaytimeNode?.endTime &&
					endTime > currentLaytimeNode?.endTime:
				case startTime < currentLaytimeNode?.startTime && endTime > currentLaytimeNode?.endTime:
				case !currentLaytimeNode.next && startTime < currentLaytimeNode?.startTime:
					reminder("warning", "Wrong time selected")
					isLoop = false
					break
				case currentLaytimeNode?.status === 1 &&
					startTime == currentLaytimeNode?.startTime &&
					endTime == currentLaytimeNode?.endTime:
					currentLaytimeNode.status = 0
					uid = uuid()
					currentLaytimeNode.uuid = uid
					isLoop = false
					isInsert = true
					break
				case currentLaytimeNode?.status === 1 &&
					startTime > currentLaytimeNode?.startTime &&
					endTime <= currentLaytimeNode?.endTime:
					const currentEndTime = currentLaytimeNode?.endTime
					currentLaytimeNode.endTime = startTime
					currentLaytimeNode.uuid = uid
					const newNode = new LaytimeTreeNode(startTime, endTime, 0, uid)
					newNode.next = new LaytimeTreeNode(
						endTime,
						currentEndTime,
						1,
						uid,
						currentLaytimeNode.next
					)
					currentLaytimeNode.next = newNode
					isLoop = false
					isInsert = true
					break
				case currentLaytimeNode?.status === 1 &&
					startTime == currentLaytimeNode?.startTime &&
					endTime <= currentLaytimeNode?.endTime:
					const currentEndTime_1 = currentLaytimeNode?.endTime
					uid = uuid()
					currentLaytimeNode.startTime = startTime
					currentLaytimeNode.endTime = endTime
					currentLaytimeNode.uuid = uid
					currentLaytimeNode.status = 0
					const newNode_1 = new LaytimeTreeNode(endTime, currentEndTime_1, 1, uid)
					newNode_1.next = currentLaytimeNode.next
					currentLaytimeNode.next = newNode_1
					isLoop = false
					isInsert = true
					break
				case currentLaytimeNode?.status === 1 &&
					startTime > currentLaytimeNode?.startTime &&
					endTime == currentLaytimeNode?.endTime:
					const currentEndTime_2 = currentLaytimeNode?.endTime
					uid = uuid()
					currentLaytimeNode.endTime = startTime
					currentLaytimeNode.uuid = uid
					currentLaytimeNode.status = 1
					const newNode_2 = new LaytimeTreeNode(startTime, currentEndTime_2, 0, uid)
					newNode_2.next = currentLaytimeNode.next
					currentLaytimeNode.next = newNode_2
					isLoop = false
					isInsert = true
					break
				case endTime >= currentLaytimeNode?.endTime:
					currentLaytimeNode = currentLaytimeNode?.next
					break
				default:
					isLoop = false
					console.warn("Not matched!")
					break
			}
		}

		if (isInsert) {
			setExceptDate((prev) => {
				prev.splice(index, 1, {
					...prev[index],
					...newExpectItem,
					uuid: uid,
					status: 0
				})
				return [...prev]
			})
			const activeLaytime = laytimeDataSource?.filter(
				(item) => item?.workDate === currentExceptDate
			)
			console.log("headLaytimeNode", headLaytimeNode)
			setLaytimeDataSource((prev) => {
				const updateIndex = prev?.findIndex((item) => item.workDate === currentExceptDate)
				const resetLaytime = prev?.filter((item) => !(item?.workDate === currentExceptDate))
				resetLaytime?.splice(
					updateIndex,
					0,
					...convertNodeToArray(headLaytimeNode, activeLaytime, currentExceptDate, activeIndex)
				)
				return [...resetLaytime]
			})
		}
	}

	const handleExpectDateCrud: MovementExceptFormeCrudEvent = (type, index) => {
		debugger
		switch (type) {
			case "add":
				setExceptDate((prev) => {
					prev.splice(index + 1, 0, { ...initialExceptForm(), isAdd: true })
					return [...prev]
				})
				break
			case "delete":
				const currentExceptItem = exceptDate?.[index]
				if (exceptDate?.length === 1) return
				if (!currentExceptItem?.isAdd) {
					setExceptDate((prev) => {
						prev.splice(index, 1)
						return [...prev]
					})
					const currentLaytime = initLaytimeNode(
						rangeSource?.[0],
						rangeSource?.[1],
						laytimeSourceTree,
						activeIndex
					)
					const { finalExceptDate, finalLaytime } = handleLaytimeNodeLoop(
						exceptDate,
						currentLaytime
					)
					setLaytimeDataSource(finalLaytime?.length <= 0 ? currentLaytime : finalLaytime)
					return
				}

				if (currentExceptItem?.date && currentExceptItem?.uuid) {
					let isFoundCurrent = false
					const currentDate = currentExceptItem?.date?.format("YYYY-MM-DD")
					const currentUid = currentExceptItem?.uuid
					let currentlaytime = laytimeSourceTree?.current?.[currentDate]
					const headId = currentlaytime?.uuid
					let headLaytimeNode = currentlaytime
					let loopCurrentItem = currentlaytime
					while (loopCurrentItem && loopCurrentItem?.next) {
						if (loopCurrentItem?.uuid === currentUid) {
							!isFoundCurrent && (currentlaytime = loopCurrentItem)
							isFoundCurrent = true
						} else if (isFoundCurrent && loopCurrentItem?.uuid !== currentUid) {
							break
						}
						loopCurrentItem = loopCurrentItem?.next
					}
					currentlaytime.uuid = undefined
					currentlaytime.status = 1
					currentlaytime.next = loopCurrentItem
					currentlaytime.endTime = loopCurrentItem?.startTime
					currentlaytime.next =
						headId === currentUid
							? loopCurrentItem?.status === 1
								? loopCurrentItem?.next
								: loopCurrentItem
							: loopCurrentItem.next
					currentlaytime.endTime =
						headId === currentUid
							? loopCurrentItem?.status === 1
								? loopCurrentItem?.endTime
								: loopCurrentItem?.startTime
							: loopCurrentItem?.endTime
					// currentlaytime.next = headId === currentUid ? loopCurrentItem : loopCurrentItem.next;
					// currentlaytime.endTime =
					// 	headId === currentUid ? loopCurrentItem?.startTime : loopCurrentItem?.endTime;
					//           currentlaytime.next = loopCurrentItem.next;
					// currentlaytime.endTime = loopCurrentItem?.endTime;

					setLaytimeDataSource((prev) => {
						const updateIndex = prev?.findIndex((item) => item.workDate === currentDate)
						const activeLaytime = laytimeDataSource?.filter(
							(item) => item?.workDate === currentDate
						)
						const resetLaytime = prev?.filter((item) => !(item?.workDate === currentDate))
						resetLaytime?.splice(
							updateIndex,
							0,
							...convertNodeToArray(headLaytimeNode, activeLaytime, currentDate, activeIndex)
						)

						return [...resetLaytime]
					})
				}
				setExceptDate((prev) => {
					prev.splice(index, 1)
					return [...prev]
				})
				break
			default:
				break
		}
	}

	const initLaytime = (
		portIndex: string | number,
		layTimeList: MovementLaytimeCalculatorType[]
	) => {
		let exceptSource: MovementExceptFormType[] = []
		let rangeData: [dayjs.Dayjs | undefined, dayjs.Dayjs | undefined] = [undefined, undefined]
		let laytimeGroup: LaytimeSourceTree = {}
		let laytimeSource: MovementLaytimeCalculatorType[] = []
		const { laytimeItem, exceptItem, rangeSource: rangeItem } = convertArrayToNode(layTimeList)

		exceptSource = exceptItem
		laytimeGroup = laytimeItem
		rangeData = rangeItem
		laytimeSource = layTimeList
		setExceptDate(exceptSource)
		setLaytimeDataSource(laytimeSource)
		setRangeSource(rangeData)
		laytimeSourceTree.current = laytimeGroup
	}

	return {
		activeIndex,
		setActiveIndex,
		laytimeDataSource,
		exceptDate,
		laytimeSourceTree,
		rangeSource,
		handleRangeChange,
		handleExpectDateChange,
		handleExpectDateCommit,
		handleExpectDateCrud,
		handleLaytimeChange,
		initLaytime
	}
}

export default useLaytimeGroup
