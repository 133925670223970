import { useMemo, useRef, useState } from "react"
import {
	RouteCommonAdditionalItemType,
	RouteCommonUnconnectVesselItemType,
	RouteCommonVesselLoadEvent,
	RouteCommonVesselTrackLoadEvent,
	RouteVoyageItemSelectEvent,
	RouteVoyageTabType
} from "../type"
import { initialAddtional } from "../source"
import {
	RouteCommonListProps,
	RouteCommonMapRefType,
	RouteCommonToolbarProps,
	RouteLegListProps,
	RouteCommonVesselCardProps,
	RouteAreaItemType,
	RouteCommonDetailsProps,
	RoutePortTabItemType,
	RouteCommonAreaVesselItemType,
	RouteCommonPortVesselItemType,
	RouteCommonAisItemType,
	RouteCommonTimelineLegendPanelProps
} from "../components"
import dayjs from "dayjs"
import { onRequest } from "@/action"
import { useAppDispatch } from "@/hook"
import { useNavigate } from "react-router-dom"
import useControlIndex from "./useControlIndex"
import { CommonCommitEvent } from "@/types/event"
import { RouteCommonProviderProps } from "../store"
import { getLastDate } from "../components/timelineLegendPanel/components/timeAxis/source"

const useIndex = (initTab: RouteVoyageTabType) => {
	const dispatch = useAppDispatch()
	const navigate = useNavigate()
	const mapRef = useRef<RouteCommonMapRefType>(null)

	const timerId = useRef<NodeJS.Timeout>(null)

	const [activeTab, setActiveTab] = useState<RouteVoyageTabType>(initTab)

	const [visibleRows, setVisibleRows] = useState<RouteVoyageTabType[]>([
		"ais",
		"area",
		"leg",
		"port"
	])

	const [activeDetailsOption, setActiveDetailsOption] = useState<
		RouteCommonDetailsProps["activeOption"]
	>({
		activeTab: null,
		isPortCallVisible: false,
		currentImo: null,
		portCallLabel: null,
		activeRoutePlan: null,
		selectDailyReports: []
	})

	const [legendVisible, setLegendVisible] = useState(true)

	const [activePortItem, setActivePortItem] = useState<
		RouteCommonProviderProps["value"]["activePortItem"]
	>({
		type: 2,
		portName: null,
		portCode: null,
		startDate: dayjs()?.format("YYYY-MM-DD"),
		endDate: dayjs()?.format("YYYY-MM-DD")
	})
	const [activeAreaItems, setActiveAreaItems] = useState<RouteAreaItemType[]>([])
	const [activeLegVessel, setActiveLegVessel] = useState<RouteLegListProps["activeItem"]>(null)
	const [activePortVessel, setActivePortVessel] = useState<RouteCommonPortVesselItemType>(null)
	const [activeAisVessel, setActiveAisVessel] = useState<RouteCommonAisItemType>(null)
	const [activeAreaVessel, setActiveAreaVessel] = useState<RouteCommonAreaVesselItemType>(null)
	const [additional, setAdditional] = useState<RouteCommonAdditionalItemType>({
		currentMode: null,
		currentTools: [],
		rangeOption: {
			startDate: null,
			endDate: null,
			type: null
		}
		// currentAisData: initialAisData()
	})

	const [hideGroups, setHideGroups] = useState<string[]>([])
	const handleGroupHide = (showGroups: string[], hideGroups: string[]) => {
		setHideGroups([...hideGroups])
		mapRef?.current?.onVesselGroupVisible?.(showGroups)
	}

	const handleAdditionalCommit: CommonCommitEvent<RouteCommonAdditionalItemType> = (item) => {
		setAdditional((prev) => ({ ...prev, ...item }))
	}

	const activeAdditional = useMemo(() => {
		if (!additional.currentMode) return null
		switch (additional.currentMode) {
			case "ais":
				return activeAisVessel
			case "port":
				return activePortVessel
			case "leg":
				return activeLegVessel
			case "area":
				return activeAreaVessel
			default:
				return null
		}
	}, [additional.currentMode, activeAreaVessel, activeAisVessel, activeLegVessel, activePortVessel])

	const { meteoStatus, handleMeteoStatusChange, handleMeteoStatusInit } = useControlIndex(mapRef)

	const handleAdditionalToolClose = (toolType: "ais" | "particular") => {
		setAdditional((prev) => {
			const currentTools = prev?.currentTools
			const currentIndx = currentTools?.findIndex((toolItem) => toolItem === toolType)
			currentTools?.splice(currentIndx, 1)
			return { ...prev, currentTools: [...currentTools] }
		})
	}

	const handleLegSelect: RouteCommonListProps["onLegSelect"] = (item) => {
		setActiveLegVessel(item)
		if (!item && "leg" === additional.currentMode) {
			handleAdditionalCommit(initialAddtional())
		}
	}

	const handleAisSelect: RouteCommonListProps["onAisSelect"] = (item) => {
		setActiveAisVessel(item)
		if (!item) {
			if ("ais" === additional.currentMode) {
				handleAdditionalCommit(initialAddtional())
			}
			handleVesselTrackLoad(
				"ais",
				{ current: null, route: { ais: null, trackPoints: [] } },
				"collect"
			)
		}
	}

	const handleAreaSelect = (item: RouteCommonAreaVesselItemType) => {
		setActiveAreaVessel(item)
		if (!item && additional.currentMode === "area") {
			handleAdditionalCommit(initialAddtional())
		}
	}

	const handlePortSelect = (item: RouteCommonPortVesselItemType) => {
		setActivePortVessel(item)
		if (!item && additional.currentMode === "port") {
			handleAdditionalCommit(initialAddtional())
		}
	}

	const handleAdditionalSelect: RouteCommonVesselCardProps["onSelect"] = (
		mode,
		toolType,
		rangeParams
	) => {
		console.log("handleAdditionalSelect", {
			mode,
			toolType,
			rangeParams
		})
		switch (true) {
			case mode === additional.currentMode && additional.currentTools?.includes(toolType):
				setAdditional((prev) => {
					const currentTools = [...prev.currentTools]
					const index = prev.currentTools.findIndex((item) => item === toolType)
					currentTools.splice(index, 1)
					return {
						...prev,
						currentMode: currentTools.length > 0 ? mode : null,
						currentTools
					}
				})
				break
			case mode === additional.currentMode &&
				!additional.currentTools?.includes(toolType) &&
				toolType === "ais":
				setAdditional((prev) => {
					const currentTools = [...prev.currentTools]
					currentTools.push(toolType)
					return {
						...prev,
						currentTools: [...currentTools],
						rangeOption: { ...rangeParams }
					}
				})
			case mode === additional.currentMode &&
				!additional.currentTools?.includes(toolType) &&
				toolType === "particular":
				setAdditional((prev) => {
					const currentTools = [...prev.currentTools]
					currentTools.push(toolType)
					return {
						...prev,
						currentTools: [...currentTools]
					}
				})
				break
			case mode && !additional.currentMode && toolType === "particular":
				setAdditional((prev) => {
					const currentTools = [...prev.currentTools]
					currentTools.push(toolType)
					return {
						...prev,
						currentMode: mode,
						currentTools
					}
				})
				break
			case mode && !additional.currentMode && toolType === "ais":
				setAdditional((prev) => {
					const currentTools = [...prev.currentTools]
					currentTools.push(toolType)
					return {
						...prev,
						rangeOption: { ...rangeParams },
						currentMode: mode,
						currentTools
					}
				})
				break
			case !!additional.currentMode && mode !== additional.currentMode:
				setAdditional((prev) => {
					const currentTools = [...prev.currentTools]
					if (!prev.currentTools.includes(toolType)) {
						currentTools.push(toolType)
					}
					if (rangeParams) {
						prev.rangeOption = { ...rangeParams }
					}
					return {
						...prev,
						currentMode: mode,
						currentTools
					}
				})
				break
			default:
				break
		}
	}

	const handleTabShow = (value: RouteVoyageTabType) => {
		const index = visibleRows?.findIndex((item) => item === value)
		setVisibleRows((prev) => {
			if (index === -1) {
				prev?.push(value)
			} else {
				prev?.splice(index, 1)
			}
			return [...prev]
		})
		// mapRef?.current?.onVesselListVisible?.(value, index === -1 ? "visible" : "none");
	}

	const handleAdditionalClose: RouteCommonVesselCardProps["onClose"] = (mode) => {
		switch (mode) {
			case "leg":
				handleLegSelect?.(null)
				break
			case "ais":
				handleAisSelect?.(null)
				break
			case "area":
				handleAreaSelect(null)
				break
			case "port":
				handlePortSelect(null)
				break
			default:
				break
		}
		handleVesselTrackLoad(mode, { current: null, route: { ais: null, trackPoints: [] } }, "collect")
	}

	const handleLegendVisibleChange = () => {
		setLegendVisible(!legendVisible)
	}

	const handleTabSelect = (value: RouteVoyageTabType) => {
		if (activeTab === value) return
		setActiveTab(value)
		switch (value) {
			case "leg":
				// setActiveDetailsType("routePlans");
				handleDetailsOptionCommit({
					activeTab: "routePlans",
					isPortCallVisible: false,
					currentImo: null,
					activeRoutePlan: null,
					selectDailyReports: []
				})
				break
			// case "ais":
			// 	setActiveDetailsType(null)
			// 	break
			case "area":
				// setActiveDetailsType("areaVessel");
				handleDetailsOptionCommit({
					activeTab: "areaVessel",
					isPortCallVisible: false,
					currentImo: null,
					activeRoutePlan: null,
					selectDailyReports: []
				})
				break
			case "port":
				// setActiveDetailsType("portVessel");
				handleDetailsOptionCommit({
					activeTab: "portVessel",
					isPortCallVisible: false,
					currentImo: null,
					activeRoutePlan: null,
					selectDailyReports: []
				})
				break
			default:
				// setActiveDetailsType(null);
				handleDetailsOptionCommit({
					isPortCallVisible: false,
					currentImo: null,
					activeRoutePlan: null,
					selectDailyReports: []
				})
				break
		}
	}

	const getMeteoCalendarEndTime = () => {
		console.log("meteoCalendar", mapRef?.current)
		const meteoCalendar = mapRef?.current?.getMeteoCalendar?.()
		return dayjs(meteoCalendar?.end)
	}

	const handleDetailsOptionCommit: CommonCommitEvent<RouteCommonDetailsProps["activeOption"]> = (
		item
	) => {
		setActiveDetailsOption((prev) => ({ ...prev, ...item }))
	}

	const handleDetailsTabSelect: RouteCommonDetailsProps["onDetailsTabSelect"] = (value) => {
		if (activeDetailsOption.activeTab === value) return
		// if (["areaVessel", "portVessel"]?.includes(value)) {
		// 	handleTabSelect(value === "areaVessel" ? "area" : "port");
		// 	return;
		// }
		handleDetailsOptionCommit({
			activeTab: value,
			isPortCallVisible: false,
			currentImo: null,
			activeRoutePlan: null,
			selectDailyReports: []
		})
	}

	const handleControllChange: RouteCommonToolbarProps["onControllChange"] = (layerIds, isShow) => {
		mapRef?.current?.controllChange?.(layerIds, isShow)
	}

	const handleMeteoTimeChange: RouteCommonTimelineLegendPanelProps["onMeteoTimeChange"] = (
		value
	) => {
		mapRef?.current?.meteoTimeChange(value)
	}

	const handleRun: RouteCommonTimelineLegendPanelProps["onRun"] = (
		type,
		startTime,
		callback,
		interval
	) => {
		if (type === "stop") {
			clearInterval(timerId?.current)
			timerId.current = null
			return
		}
		const meteoCalendar = mapRef?.current?.getMeteoCalendar()
		const endTime = dayjs(meteoCalendar.end)
		let currentTime = startTime
		if (timerId.current) {
			clearInterval(timerId?.current)
			timerId.current = null
		}
		timerId.current = setInterval(() => {
			const newTime = currentTime.add(interval, "h"),
				lastTime = getLastDate()
			console.log("ddd", {
				newTime,
				endTime
			})
			if (newTime?.isAfter(endTime) || newTime?.isSame(endTime)) {
				clearInterval(timerId?.current)
				timerId.current = null
				callback?.(endTime)
				console.log("meteoTimeChange endtime", newTime, lastTime)
				mapRef?.current?.meteoTimeChange(endTime.valueOf())
				return
			}
			if (
				newTime?.startOf("hour")?.isAfter(lastTime) ||
				newTime?.startOf("hour")?.isSame(lastTime)
			) {
				clearInterval(timerId?.current)
				timerId.current = null
				callback?.(lastTime)
				console.log("meteoTimeChange", newTime, lastTime)
				mapRef?.current?.meteoTimeChange(lastTime.valueOf())
				return
			}
			if (newTime.isBefore(endTime)) {
				currentTime = newTime
			} else {
				currentTime = startTime
			}
			console.log("onrun", { currentTime, newTime, endTime, startTime, lastTime })
			typeof callback === "function" && callback(currentTime)
			mapRef?.current?.meteoTimeChange(currentTime.valueOf())
		}, 1000)
	}

	const handlePortItemCommit: RouteCommonProviderProps["value"]["onPortItemCommit"] = (item) => {
		setActivePortItem((prev) => ({ ...prev, ...item }))
	}

	const handleVesselLoad: RouteCommonVesselLoadEvent = (type, items) => {
		if (type === "ais") {
			const restItems = items?.filter((item) => hideGroups?.includes(item?.groupId))
			setHideGroups?.(restItems?.map((item) => item?.groupId))
		}
		mapRef?.current?.onVesselLoad?.(type, items)
	}

	const handleVesselSelect: RouteVoyageItemSelectEvent<RouteCommonUnconnectVesselItemType> = (
		type,
		selectItem
	) => {}

	const handleVesselTrackLoad: RouteCommonVesselTrackLoadEvent = (type, item, source) => {
		mapRef?.current?.onVesselTrackLoad?.(type, item, source)
	}

	const getAisDataForImo: RouteCommonVesselCardProps["onTrackRefresh"] = (option, callback) => {
		onRequest(
			"getRouteCommonAisDataApi",
			{
				imo: option.imo,
				endDate: option?.endDate,
				startDate: option?.startDate,
				type: option?.type
			},
			callback?.front,
			callback?.success,
			callback?.error,
			dispatch,
			navigate
		)
	}

	return {
		activeTab,
		handleTabSelect,
		handleLegSelect,
		handleAisSelect,
		activeLegVessel,
		setActiveLegVessel,
		activeAisVessel,
		setActiveAisVessel,
		mapRef,
		meteoStatus,
		handleMeteoStatusChange,
		handleControllChange,
		handleMeteoTimeChange,
		handleRun,
		legendVisible,
		handleLegendVisibleChange,
		activePortVessel,
		setActivePortVessel,
		activeAdditional,
		additional,
		handleAdditionalSelect,
		handleAdditionalClose,
		handleAdditionalToolClose,
		setAdditional,
		activeAreaVessel,
		setActiveAreaVessel,
		activeDetailsOption,
		handleDetailsTabSelect,
		activeAreaItems,
		setActiveAreaItems,
		handleDetailsOptionCommit,
		activePortItem,
		handlePortItemCommit,
		handleVesselLoad,
		handlePortVesselSelect: handlePortSelect,
		handleAreaVesselSelect: handleAreaSelect,
		getAisDataForImo,
		handleVesselTrackLoad,
		handleAdditionalCommit,
		getMeteoCalendarEndTime,
		visibleRows,
		handleTabShow,
		hideGroups,
		handleGroupHide,
		handleMeteoStatusInit
	}
}

export default useIndex
