import React, { FC } from "react"
import style from "./index.module.less"
import classnames from "classnames"
import { MovementBunkerPortCostProps } from "./type"
import MovementTable from "../../../charterBase/common/table"
import { movementBunkerPortCostSummaryList, columns } from "./source"
import { formatThousandthNumber } from "@/tools/amount"

const MovementBunkerPortCost: FC<MovementBunkerPortCostProps> = ({
	items,
	summaryItem,
	onChange,
	onCommit,
	otherSource
}) => {
	return (
		<div className={classnames("bunkerPlan-commom", style["bunkerPlan-cost"])}>
			<span className="bunkerPlan-header--title">Bunker Cost Calculation</span>
			<div className="bunkerPlan-cost--container">
				<ul className="bunkerPlan-cost--thead">
					{["", "BOD", "BOR", "BOD-BOR", "Adjustment", "Bunkering Charge"]?.map((item, idx) => {
						return <li key={idx}>{item}</li>
					})}
				</ul>
				<MovementTable
					className="bunkerPlan-cost--table"
					columns={columns}
					dataSource={items}
					otherSource={otherSource}
					actions={{
						onChange,
						onCommit
					}}
				/>
				<ul className="bunkerPlan-cost--summary">
					{movementBunkerPortCostSummaryList?.map((item, index) => {
						return (
							<li>
								{summaryItem?.[item] || summaryItem?.[item] === 0
									? formatThousandthNumber(summaryItem?.[item], 3, 3)
									: item}
							</li>
						)
					})}
				</ul>
			</div>
		</div>
	)
}

export default MovementBunkerPortCost
