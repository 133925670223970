import { useAppDispatch } from "@/hook"
import { useNavigate } from "react-router-dom"
import useReminder from "@/hook/useReminder"
import { MutableRefObject, useEffect, useRef, useState } from "react"
import { getCurrentPlanAction } from "@/action/voyageManage/movement"
import { AisDataType, CurrentPlanDataType } from "../type"
import mapboxgl, { AnySourceImpl } from "mapbox-gl"
import { convertToDms } from "@/tools/graph"
import { loadImage } from "@/featrue/estimation/details/routeGraph/tool"
import { IconMap } from "@/components/Icon/icons"
import dayjs from "dayjs"
import { onRequest } from "@/action"
import { uuid } from "@/tools"
import { formatThousandthNumber } from "@/tools/amount"
import { dateToUtcString } from "@/tools/date"

const useVesselSource = () => {
	// 船历史轨迹/箭头指向 source
	const vesselTrackSource = useRef({
		type: "FeatureCollection",
		features: []
	})

	// 船图标source
	const vesselIconSource = useRef({
		type: "FeatureCollection",
		features: [
			{
				type: "Feature",
				geometry: {
					type: "Point",
					coordinates: []
				},
				properties: {
					bearing: 0
				}
			}
		]
	})

	// 船历史轨迹 source
	const vesselHisTrackSource = useRef({
		type: "FeatureCollection",
		features: []
	})

	// 历史轨迹起始点终止点 source
	const startEndPointSource = useRef({
		type: "FeatureCollection",
		features: [
			{
				type: "Feature",
				properties: {
					typeColor: "transparent",
					index: "start-outer",
					type: "outer"
				},
				geometry: {
					type: "Point",
					coordinates: []
				}
			},
			{
				type: "Feature",
				properties: {
					typeColor: "rgba(3, 240, 254, 0.60)",
					index: "start-inline",
					type: "inline"
				},
				geometry: {
					type: "Point",
					coordinates: []
				}
			},
			{
				type: "Feature",
				properties: {
					typeColor: "transparent",
					type: "outer",
					index: "end-outer"
				},
				geometry: {
					type: "Point",
					coordinates: []
				}
			},
			{
				type: "Feature",
				properties: {
					typeColor: "rgba(255, 134, 50, 0.60)",
					type: "inline",
					index: "end-inline"
				},
				geometry: {
					type: "Point",
					coordinates: []
				}
			}
		]
	})

	const vesselTrackPointsSource = useRef({
		type: "FeatureCollection",
		features: []
		// generateId: true
	})

	const vesselTrackPointLabelSource = useRef({
		type: "FeatureCollection",
		features: []
	})

	const vesselTrackPointsLineSource = useRef({
		type: "FeatureCollection",
		features: [
			{
				type: "Feature",
				geometry: {
					type: "LineString",
					coordinates: []
				},
				properties: {}
			}
		]
	})

	return {
		vesselTrackSource,
		vesselIconSource,
		vesselHisTrackSource,
		startEndPointSource,
		vesselTrackPointsSource,
		vesselTrackPointLabelSource,
		vesselTrackPointsLineSource
	}
}

const useVesselGlLayer = () => {
	const addVesselLayer = (mapboxgl: MutableRefObject<mapboxgl.Map>, before?: string) => {
		mapboxgl.current.addLayer(
			{
				id: "vesselTrackLayer",
				type: "line",
				source: "vesselTrackSource",
				layout: {
					"line-join": "round",
					"line-cap": "round"
				},
				paint: {
					"line-width": 1.34,
					// "line-color": "rgba(0, 240, 255, 1)",
					"line-color": "rgba(53,86,145,0.75)"
					// 'line-gradient': [
					//   'interpolate',
					//   ['linear'],
					//   ['line-progress'],
					//   0.25, "rgba(0, 240, 255, 1)",
					//   0.5, "rgba(255, 233, 38, 1)",
					//   0.75, "rgba(255, 134, 50, 1)",
					//   1, "rgba(255, 134, 50, 1)"
					// ]
				}
			},
			before
		)
	}

	const addVesselIconLayer = (mapboxgl: MutableRefObject<mapboxgl.Map>, before?: string) => {
		mapboxgl.current.addLayer(
			{
				id: "vesselIconLayer",
				type: "symbol",
				source: "vesselIconSource",
				layout: {
					// 'symbol-placement': 'line',
					// 'symbol-spacing': 100, // 图标间隔，默认为250
					"icon-image": "vessel-icon", //箭头图标
					"icon-size": 0.7,
					"icon-rotate": ["get", "bearing"]
					// "icon-rotation-alignment": "map",
					// "icon-allow-overlap": true,
					// "icon-ignore-placement": true,
					// 'icon-rotate': -90
				}
			},
			before
		)
	}

	const addVesselTrackArrowLayer = (mapboxgl: MutableRefObject<mapboxgl.Map>, before?: string) => {
		mapboxgl.current.addLayer(
			{
				id: "vesselTrackArrowLayer",
				type: "symbol",
				source: "vesselTrackSource",
				layout: {
					"symbol-placement": "line",
					"symbol-spacing": 100, // 图标间隔，默认为250
					"icon-image": "vessel-arrow-icon", //箭头图标
					"icon-size": 0.7,
					"icon-rotate": -90
				}
			},
			before
		)
	}

	const addStartEndPointLayer = (mapboxgl: MutableRefObject<mapboxgl.Map>, before?: string) => {
		mapboxgl.current.addLayer(
			{
				id: "startEndPointLayer",
				source: "startEndPointSource",
				type: "circle",
				paint: {
					"circle-radius": 4,
					"circle-color": "rgba(53,86,145,0.75)"
				}
			},
			before
		)
	}

	const addVesselTrackPointLineLayer = (
		mapboxgl: MutableRefObject<mapboxgl.Map>,
		before?: string
	) => {
		mapboxgl.current.addLayer(
			{
				id: "vesselTrackPointLineLayer",
				source: "vesselTrackPointsLineSource",
				type: "line",
				layout: {
					"line-join": "round",
					"line-cap": "round"
				},
				paint: {
					"line-color": "transparent",
					"line-width": 2
				}
			},
			before
		)
	}

	const addVesselTrackPointsLayer = (mapboxgl: MutableRefObject<mapboxgl.Map>, before?: string) => {
		mapboxgl.current.addLayer(
			{
				id: "vesselTrackPointsLayer",
				source: "vesselTrackPointsSource",
				type: "symbol",
				layout: {
					// 'symbol-placement': 'line',
					"symbol-spacing": 100, // 图标间隔，默认为250
					"icon-image": "vessel-point-icon", //
					"icon-size": 1
					// 'icon-rotate': -90
				}
			},
			before
		)
	}

	const addVesselTrackPointLabelLayer = (
		mapboxgl: MutableRefObject<mapboxgl.Map>,
		before?: string
	) => {
		mapboxgl.current.addLayer(
			{
				id: "vesselTrackPointLabelLayer",
				type: "symbol",
				source: "vesselTrackPointLabelSource",
				layout: {
					// 'symbol-placement': 'line',
					"symbol-spacing": 100, // 图标间隔，默认为250
					"icon-image": "vessel-bg-icon", //箭头图标
					"icon-size": 1,
					"text-field": ["concat", ["get", "time"], "Z ", ["get", "speed"], " knots"],
					"icon-offset": [100, 0],
					"text-offset": [10, 0],
					"text-max-width": 1000, // 设置一个足够大的宽度，确保文本不会换行
					// 文字大小
					"text-size": 10
				}
			},
			before
		)
	}

	return {
		addVesselLayer,
		addVesselIconLayer,
		addVesselTrackArrowLayer,
		addStartEndPointLayer,
		addVesselTrackPointsLayer,
		addVesselTrackPointLabelLayer,
		addVesselTrackPointLineLayer
	}
}

const useIndex = (voyageId: string, showMap: boolean) => {
	const dispatch = useAppDispatch()
	const navigate = useNavigate()
	const { reminder } = useReminder()
	const [currentPlanData, setCurrentPlan] = useState<CurrentPlanDataType>()
	const aisData = useRef<AisDataType>()
	const mapContainer = useRef<HTMLDivElement>(null)
	const mapbox = useRef(null)
	const coordinatesController = useRef<HTMLDivElement>(null)
	const loading = useRef(false)
	const renderQueue = useRef<(() => void)[]>([])
	const trackLabelState = useRef<
		{ id: string; visibility: "visible" | "none"; [propName: string]: any }[]
	>([])
	const [showDeatil, setShowDetail] = useState<boolean>(false)

	const {
		vesselTrackSource,
		vesselIconSource,
		startEndPointSource,
		vesselTrackPointsSource,
		vesselTrackPointLabelSource,
		vesselTrackPointsLineSource
	} = useVesselSource()

	const {
		addVesselLayer,
		addVesselIconLayer,
		addVesselTrackArrowLayer,
		addStartEndPointLayer,
		addVesselTrackPointsLayer,
		addVesselTrackPointLabelLayer,
		addVesselTrackPointLineLayer
	} = useVesselGlLayer()

	const getMovementCurrentPlan = () => {
		getCurrentPlanAction(
			voyageId,
			() => {},
			getMovementCurrentPlanSuccess,
			getMovementCurrentPlanFailed,
			dispatch,
			navigate
		)
	}

	const getMovementCurrentPlanSuccess = (response) => {
		if (showMap) {
			mapboxglInit()
			if (response?.data?.currentState?.imo) {
				getRouteCommonAisData(response?.data)
			}
		}
		setCurrentPlan(response?.data)
	}

	const getMovementCurrentPlanFailed = (error) => {
		reminder("error", error?.msg ? error?.msg + ": " + error?.data : error?.data)
	}

	const getRouteCommonAisData = (currentPlanData: CurrentPlanDataType) => {
		onRequest(
			"getRouteCommonAisDataApi",
			{
				imo: currentPlanData?.currentState?.imo,
				endDate: currentPlanData?.vesselBasics?.estRedeliveryDate
					? dayjs(currentPlanData?.vesselBasics?.estRedeliveryDate)?.format("YYYY-MM-DD HH:mm:ss")
					: null,
				startDate: currentPlanData?.vesselBasics?.deliveryTime
					? dayjs(currentPlanData?.vesselBasics?.deliveryTime)?.format("YYYY-MM-DD HH:mm:ss")
					: null
			},
			() => {},
			getMonitoringAisDataSuccess,
			getMovementCurrentPlanFailed,
			dispatch,
			navigate
		)
	}

	const getMonitoringAisDataSuccess = (response) => {
		aisData.current = response?.data
		renderQueue.current = []
		renderQueue.current.push(drawVesselTrack)
		drawVesselTrack()
		renderQueue.current.push(drwaVesselPosition)
		drwaVesselPosition()
		renderQueue.current.push(drawTrackPoints)
		drawTrackPoints()
	}

	const useLoadVesselImage = (mapboxgl: MutableRefObject<mapboxgl.Map>) => {
		loadImage(mapboxgl, IconMap.EstimationVesselArrowIcon, "vessel-arrow-icon")
		loadImage(mapboxgl, IconMap.EstimationVesselIcon, "vessel-icon")
		loadImage(mapboxgl, IconMap.EstimationVesselPointIcon, "vessel-point-icon")
		loadImage(mapboxgl, IconMap.EstimationVesselBgIcon, "vessel-bg-icon")
	}

	const useLoadPortImage = (mapboxgl: MutableRefObject<mapboxgl.Map>) => {
		loadImage(mapboxgl, IconMap.FleetGraphLineIcon, "port-track-icon")
	}

	const drawVesselTrack = () => {
		let coordinates = aisData.current?.route?.ais?.features[0].geometry?.coordinates?.flat()
		if (coordinates?.length > 0) {
			startEndPointSource.current.features[0].geometry.coordinates = coordinates?.[0]
			startEndPointSource.current.features[1].geometry.coordinates =
				coordinates?.[coordinates?.length - 1]

			mapbox.current.flyTo(
				{
					center: startEndPointSource.current.features[1].geometry.coordinates,
					zoom: 2,
					duration: 1200
				},
				{
					moveend: "FLY_END"
				}
			)
			vesselTrackSource.current = aisData.current?.route?.ais?.features[0]
			mapbox.current.getSource("startEndPointSource").setData(startEndPointSource.current)
			mapbox.current.getSource("vesselTrackSource").setData(vesselTrackSource.current)
		} else {
			startEndPointSource.current.features[0].geometry.coordinates = []
			startEndPointSource.current.features[1].geometry.coordinates = []
			vesselTrackSource.current = {
				type: "FeatureCollection",
				features: [
					{
						type: "Feature",
						geometry: {
							type: "LineString",
							coordinates: []
						}
					}
				]
			}
			mapbox.current.getSource("startEndPointSource").setData(startEndPointSource.current)
			mapbox.current.getSource("vesselTrackSource").setData(vesselTrackSource.current)
		}
	}

	const drwaVesselPosition = () => {
		if (aisData?.current?.current?.lat && aisData?.current?.current?.lon) {
			// 212.76 + +vesselPosition?.hdg;
			vesselIconSource.current.features[0].geometry.coordinates = [
				aisData?.current?.current?.lon,
				aisData?.current?.current?.lat
			]
			vesselIconSource.current.features[0].properties.bearing = +aisData?.current?.current?.heading
			mapbox.current.flyTo(
				{
					center: vesselIconSource.current.features[0].geometry.coordinates,
					zoom: 2,
					duration: 1500
				},
				{
					moveend: "FLY_END"
				}
			)
		} else {
			vesselIconSource.current.features[0].geometry.coordinates = []
			vesselIconSource.current.features[0].properties.bearing = 0
		}
		mapbox.current?.getSource("vesselIconSource")?.setData(vesselIconSource.current)
	}

	const drawTrackPoints = () => {
		let direction = true
		const coordinates: [number, number][] = []
		const features = []
		aisData?.current?.route?.trackPoints?.map((item, idx) => {
			coordinates.push([item.lon, item.lat])
			const id = uuid()
			features.push({
				type: "Feature",
				id: idx,
				geometry: {
					type: "Point",
					coordinates: [item.lon, item.lat]
				},
				// visibility: 'none',
				properties: {
					speed: formatThousandthNumber(item?.speed),
					time: dateToUtcString(item?.time),
					longitude: item?.lon,
					latitude: item?.lat,
					direction: direction ? -100 : 100,
					textDirection: direction ? -10 : 10,
					hidden: false,
					id
				}
			})

			direction = !direction
		})

		vesselTrackPointsLineSource.current.features[0].geometry.coordinates = coordinates
		vesselTrackPointLabelSource.current.features = features
		vesselTrackPointsSource.current.features = features
		mapbox?.current?.getSource("vesselTrackPointsSource")?.setData(vesselTrackPointsSource.current)
		mapbox?.current
			?.getSource("vesselTrackPointsLineSource")
			?.setData(vesselTrackPointsLineSource.current)
		mapbox?.current
			?.getSource("vesselTrackPointLabelSource")
			?.setData(vesselTrackPointLabelSource.current)
	}

	const mapboxglInit = () => {
		mapbox.current = new mapboxgl.Map({
			container: mapContainer.current,
			// style: "mapbox://styles/mapbox/streets-v11",
			style: "mapbox://styles/voyagecentury2018/cln1634sd003g01rc1alr5qmp",
			accessToken:
				"pk.eyJ1Ijoidm95YWdlY2VudHVyeTIwMTgiLCJhIjoiY2xmOTM0NDJyMXV1MjQwb2NrNzFvZWJ3dSJ9.zyBT0Gd6HYyeywftD_XKtg",
			center: { lng: 174.7265625, lat: 29.305561325527698 },
			zoom: 1.2
		})

		// 创建鼠标经纬度控件
		const coordinatesControl = {
			onAdd: function (map) {
				coordinatesController.current = document.createElement("div")
				coordinatesController.current.className = "coordinates-container"
				map.on("mousemove", this._updateCoordinates)
				return coordinatesController.current
			},
			onRemove: function (map) {
				map.off("mousemove", this._updateCoordinates)
				coordinatesController.current.parentNode.removeChild(coordinatesController.current)
				this._map = undefined
			},
			_updateCoordinates: function (e) {
				if (!mapbox.current) return
				const lngLat = e.lngLat
				coordinatesController.current.textContent = `Lat/Lng: ${convertToDms(lngLat.lat, lngLat.lng)}`
			}
		}

		mapbox.current.addControl(coordinatesControl)

		useLoadVesselImage(mapbox)
		useLoadPortImage(mapbox)

		mapbox.current.on("style.load", () => {
			// useVesselGlSource(mapbox);
			mapbox.current.addSource("vesselTrackSource", {
				type: "geojson",
				lineMetrics: true,
				//@ts-ignore
				data: vesselTrackSource.current
			})
			mapbox.current.addSource("vesselIconSource", {
				type: "geojson",
				//@ts-ignore
				data: vesselIconSource.current
			})
			mapbox.current.addSource("startEndPointSource", {
				type: "geojson",
				//@ts-ignore
				data: startEndPointSource.current
			})

			mapbox.current.addSource("vesselTrackPointsSource", {
				type: "geojson",
				//@ts-ignore
				data: vesselTrackPointsSource.current
				// id:"vesselTrackPointsSource",
				// generateId: true
			})

			mapbox.current.addSource("vesselTrackPointLabelSource", {
				type: "geojson",
				//@ts-ignore
				data: vesselTrackPointLabelSource.current
			})

			mapbox.current.addSource("vesselTrackPointsLineSource", {
				type: "geojson",
				//@ts-ignore
				data: vesselTrackPointsLineSource.current
			})

			addVesselLayer(mapbox)
			addVesselTrackArrowLayer(mapbox)
			addVesselTrackPointLineLayer(mapbox)
			addVesselTrackPointsLayer(mapbox)
			addVesselTrackPointLabelLayer(mapbox)
			addStartEndPointLayer(mapbox)
			addVesselIconLayer(mapbox)
		})

		mapbox.current.on("load", () => {
			loading.current = true
			while (renderQueue?.current?.length > 0) {
				const callback = renderQueue?.current?.shift()
				callback?.()
			}
		})
	}

	const handleShow = () => {
		setShowDetail(!showDeatil)
	}

	useEffect(() => {
		if (!mapbox.current && showDeatil) {
			mapboxglInit()
			getRouteCommonAisData(currentPlanData)
		}
	}, [showDeatil])

	useEffect(() => {
		if (voyageId || showMap) {
			getMovementCurrentPlan()
		}
		if (!showMap) {
			mapbox.current?.remove()
		}
	}, [showMap, voyageId])

	return {
		currentPlanData,
		mapContainer,
		showDeatil,
		handleShow
	}
}
export default useIndex
