import React, { FC, useMemo } from "react"
import { InvStatementBunkerPriceProps } from "./type"
import { InvoiceAddTo } from "@/pages/voyagemanage/business/invoices/module"
import { InvStatementBunker } from "../../common"
import { formatThousandthNumber } from "@/tools/amount"
import { Button } from "@/_components"
import { cn } from "@/tools"
import { getBunkerSummary } from "./tools"

const InvStatementBunkerPrice: FC<InvStatementBunkerPriceProps> = ({
	items,
	disableBunkers,
	onCommit,
	onDelete,
	otherItem,
	onOtherItemChange,
	onAdd
}) => {
	const dataSource = useMemo(() => {
		const bodItems = items?.filter((item) => item?.type === "BOD"),
			borItems = items?.filter((item) => item?.type === "BOR"),
			summary = getBunkerSummary(bodItems, borItems)
		return {
			bodItems,
			borItems,
			summary
		}
	}, [items])

	return (
		<div className="invoicesDetail-form">
			<div className="w-full flex gap-[8px] pb-[8px] border-b border-solid border-[#e7e9ec] flex-row justify-between items-center">
				<InvoiceAddTo
					type="addBunker"
					value={otherItem?.addBunker}
					onChange={(key, val) => {
						onOtherItemChange?.("addBunker", val)
					}}
				>
					<span>Bunker Price</span>
				</InvoiceAddTo>
				<Button
					prefixIcon="fleet-plus"
					type="primary"
					size="small"
					onClick={onAdd}
					disabled={disableBunkers?.length >= 4}
				>
					New Bunker Type
				</Button>
			</div>
			<div className="w-full flex flex-col gap-[16px]">
				<InvStatementBunker
					title="Bunker On Delivery (BOD)"
					items={dataSource?.bodItems}
					disableBunkers={disableBunkers}
					onCommit={onCommit}
					onDelete={onDelete}
				/>
				<InvStatementBunker
					title="Bunker On Redelivery (BOR)"
					items={dataSource?.borItems}
					disableBunkers={disableBunkers}
					onCommit={onCommit}
					onDelete={onDelete}
				/>
				<ul
					className={cn("w-full flex flex-col gap-[4px]", {
						"border-t border-[#E7E9EC] border-solid py-[16px]": dataSource?.borItems?.length > 0
					})}
				>
					<li className="text-[14px] leading-[22px] font-normal">
						<span className="text-[#A3A3A3]">Net Amount (BOR - BOD) </span>
					</li>
					{dataSource?.summary?.map((summaryItem, summaryIndex) => {
						return (
							<li key={summaryIndex} className="text-[14px] leading-[22px] font-normal">
								<span className="text-[#A3A3A3]">{summaryItem?.bunkerType}: </span>
								<span className="text-[#7D7D7D]">
									{formatThousandthNumber(summaryItem?.total)} $
								</span>
							</li>
						)
					})}
				</ul>
			</div>
		</div>
	)
}

export default InvStatementBunkerPrice
