import React, { FC } from "react"
import style from "./index.module.less"
import { InvoicesViewsProps } from "./type"
import useIndex from "./hooks/useIndex"
import { InvoicesViewsDetails, InvoicesViewsList } from "./components"
import { FleetNotification } from "@/common"

const InvoicesViews: FC<InvoicesViewsProps> = ({
	activeVoyageItem,
	activeInvType,
	onInvTypeSelect,
	newly,
	onNewlyCommit
}) => {
	const {
		loading,
		activeIndex,
		activeInvoice,
		hide,
		addIng,
		dataSource,
		handleInvItemSelect,
		handleInvItemDelete,
		handleInvTypeSelect,
		handleInvoicesAdd,
		handleItemSelectCancel,
		handleItemSelectConfirm,
		handleInvoicesInit,
		handleInvoicesUpate
	} = useIndex(activeVoyageItem, activeInvType, newly, onInvTypeSelect, onNewlyCommit)

	return (
		<div className={style["invoices-views"]}>
			<InvoicesViewsList
				items={dataSource}
				loading={loading}
				activeIndex={activeIndex}
				activeType={activeInvType}
				onItemSelect={handleInvItemSelect}
				onItemDelete={handleInvItemDelete}
				onTypeSelect={handleInvTypeSelect}
				onAdd={handleInvoicesAdd}
			/>
			<InvoicesViewsDetails
				activeInvType={activeInvType}
				activeInvoice={activeInvoice}
				activeVoyageItem={activeVoyageItem}
				onInvoiceAdd={handleInvoicesInit}
				onInvoiceUpdate={handleInvoicesUpate}
				newly={newly}
				totalInv={dataSource?.length}
			/>
			<FleetNotification
				title="Warning"
				content="The newly added invoices may have unsaved data, and switching to other invoices data will result in loss. Are you sure?"
				hide={hide}
				onClose={handleItemSelectCancel}
				onConfirm={handleItemSelectConfirm}
			/>
		</div>
	)
}

export default InvoicesViews
