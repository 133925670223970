import { FleetSelect } from "@/components"
import React, { FC } from "react"
import useIndex from "./hooks/useIndex"
import { CommonPortSelectProps } from "./type"
import { CommonPortPopup, LatitudeSelect } from "./components"
import { CommonLatitudeSelect } from "@/common"

const CommonPortSelect: FC<CommonPortSelectProps> = ({
	onFinish,
	isInput,
	activeItems,
	primaryKey,
	showKey,
	showCount,
	className,
	disabled,
	placeholder,
	mode
}) => {
	const {
		loading,
		list,
		open,
		loadMore,
		closePopup,
		focusPopup,
		searchValue,
		tagRender,
		handleEnter,
		handleSearch,
		handleChange,
		handleItemSelect,
		handleLatitudeClose,
		selectValue,
		status
	} = useIndex(activeItems, showKey, onFinish, primaryKey, disabled)

	return (
		<FleetSelect
			showSearch
			disabled={disabled}
			className={className}
			placeholder={placeholder}
			value={selectValue}
			showCount={showCount}
			popupMatchSelectWidth={false}
			onDropdownVisibleChange={closePopup}
			tagRender={tagRender}
			mode={mode ? mode : Array?.isArray(activeItems) ? "multiple" : undefined}
			open={open}
			// searchValue={searchValue}
			onInputKeyDown={handleEnter}
			onSearch={handleSearch}
			onFocus={() => focusPopup()}
			onChange={isInput ? handleChange : undefined}
			searchValue={status === "num" ? searchValue : undefined}
			dropdownRender={() =>
				status === "num" ? (
					<CommonLatitudeSelect onClose={handleLatitudeClose} open={open} />
				) : (
					<CommonPortPopup
						loadMore={loadMore}
						list={list}
						onSelect={handleItemSelect}
						loading={loading}
					/>
				)
			}
		/>
	)
}

export default CommonPortSelect
