import { type ApiParameter } from "@/api"
import { appVersion } from "@/api/constant"
import request from "@/service"
import {
	getMovementSeaReportsParams,
	saveMovementSeaReportsRowParams,
	analysisMovementSeaReportsParams,
	confirmSeaReportsWarningParams,
	deleteSeaReportsWarningParams,
	downloadSeaReportParams,
	updateToMovementParams,
	saveSegmentParams
} from "./type"

const enum ApiMap {
	getMovementSeaReportsParameter,
	saveMovementSeaReportsParameter,
	deleteMovementSeaReportsParameter,
	analysisMovementSeaReportsParameter,
	getSeaReportsWarningParameter,
	confirmSeaReportsWarningParameter,
	downloadSeaReportParameter,
	updateToMovementParameter,
	analysisSeaReportParameter,
	getSegmentParameter,
	saveSegmentParameter,
	deleteSegmentParameter,
	downloadSeaReportTemParameter
}

type ApiUrl = keyof typeof ApiMap

const movementSeaReportApi: ApiParameter<ApiUrl> = {
	getMovementSeaReportsParameter: {
		method: "get",
		url: appVersion + `/move/report/list`
	},
	saveMovementSeaReportsParameter: {
		method: "post",
		url: appVersion + `/move/report/save`
	},
	deleteMovementSeaReportsParameter: {
		method: "delete",
		url: appVersion + `/move/report`
	},
	analysisMovementSeaReportsParameter: {
		method: "post",
		url: appVersion + `/move/report/analyze`
	},
	getSeaReportsWarningParameter: {
		method: "get",
		url: appVersion + `/move/report/alarm/`
	},
	confirmSeaReportsWarningParameter: {
		method: "put",
		url: appVersion + `/move/report/alarm`
	},
	downloadSeaReportParameter: {
		method: "post",
		url: appVersion + `/move/report/download`
	},
	updateToMovementParameter: {
		method: "post",
		url: appVersion + `/move/report/updatetomovement`
	},
	analysisSeaReportParameter: {
		method: "post",
		url: appVersion + `/move/report/excel/analyze`
	},
	getSegmentParameter: {
		method: "get",
		url: appVersion + `/move/report/segment/list`
	},
	saveSegmentParameter: {
		method: "post",
		url: appVersion + `/move/report/segment/save`
	},
	deleteSegmentParameter: {
		method: "delete",
		url: appVersion + `/move/report/segment/batch`
	},
	downloadSeaReportTemParameter: {
		method: "get",
		url: appVersion + `/move/report/segment/batch`
	}
}

export const getSegmentApi = (params: { voyageId: string; type: "Ballast" | "Laden" }) => {
	return request.request({
		...movementSeaReportApi.getSegmentParameter,
		params
	})
}

export const saveSegmentApi = (data: saveSegmentParams) => {
	return request.request({
		...movementSeaReportApi.saveSegmentParameter,
		data
	})
}
export const deleteSegmentApi = (data: number[]) => {
	return request.request({
		...movementSeaReportApi.deleteSegmentParameter,
		data
	})
}
export const getMovementSeaReportsApi = (params: getMovementSeaReportsParams) => {
	return request.request({
		...movementSeaReportApi.getMovementSeaReportsParameter,
		params
	})
}

export const saveMovementSeaReportsApi = (data: saveMovementSeaReportsRowParams) => {
	return request.request({
		...movementSeaReportApi.saveMovementSeaReportsParameter,
		data
	})
}

export const deleteMovementSeaReportsApi = (data: deleteSeaReportsWarningParams) => {
	return request.request({
		...movementSeaReportApi.deleteMovementSeaReportsParameter,
		data
	})
}

export const analysisMovementSeaReportsApi = (data: analysisMovementSeaReportsParams) => {
	return request.request({
		...movementSeaReportApi.analysisMovementSeaReportsParameter,
		data
	})
}

export const getSeaReportsWarningApi = (id: string) => {
	return request.request({
		...movementSeaReportApi.getSeaReportsWarningParameter,
		url: movementSeaReportApi.getSeaReportsWarningParameter.url + `${id}`
	})
}

export const confirmSeaReportsWarningApi = (data: confirmSeaReportsWarningParams) => {
	return request.request({
		...movementSeaReportApi.confirmSeaReportsWarningParameter,
		data
	})
}

export const downloadSeaReportApi = (data: downloadSeaReportParams) => {
	return request.request({
		...movementSeaReportApi.downloadSeaReportParameter,
		data,
		responseType: "blob"
	})
}

export const updateToMovementApi = (data: updateToMovementParams) => {
	return request.request({
		...movementSeaReportApi.updateToMovementParameter,
		data
	})
}

export const analysisSeaReportApi = (data: FormData) => {
	return request.request({
		...movementSeaReportApi.analysisSeaReportParameter,
		data
	})
}

export const downloadSeaReportTemApi = () => {
	return request.request({
		...movementSeaReportApi.downloadSeaReportTemParameter
	})
}
