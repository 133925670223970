import { useMemo, useState } from "react"
import { useBunkerPrice, useHireIndex, useOffGroupIndex } from "../components"
import {
	InvStatementDescInitItemType,
	InvStatementDescItemType,
	InvStatementDescProps
} from "../type"
import { useOtherExpensesIndex } from "../../../../../common"
import { InvStatementOtherItemType, InvStatementProps } from "../../../type"

const useIndex = (
	otherItem: InvStatementDescProps["otherItem"] &
		Pick<InvStatementOtherItemType, "voyageId" | "id">,
	activeInvType: InvStatementProps["activeInvType"]
) => {
	const [dataSource, setDataSource] = useState<InvStatementDescItemType>({
		voyageNo: "",
		remitAmount: 0,
		amount: 0,
		actualAmount: 0
	})

	const {
		dataSource: hireItems,
		summary: hireSummary,
		commit: handleHireCommit,
		handleHireCheck,
		init: handleHireInit
	} = useHireIndex({ addHire: otherItem?.addHire })

	const {
		summary: offGroupSummary,
		dataSource: offGroup,
		deleteIds: offGroupDelIds,
		commit: handleOffGroupCommit,
		add: handleOffGroupAdd,
		remove: handleOffGroupDelete,
		init: handleOffGroupInit,
		check: handleOffGroupCheck,
		handleOffHireOpen,
		handleOffHireConfirm,
		handleGroupAdd,
		handleGroupCommit,
		handleGroupDelete,
		handleGroupCombineParams
	} = useOffGroupIndex(
		{
			addOffHire: otherItem?.addOffHire,
			id: otherItem?.id,
			voyageId: otherItem?.voyageId
		},
		activeInvType
	)

	const {
		summary: bunkerPriceSummary,
		dataSource: bunkerPriceItems,
		commit: handleBunkerPriceCommit,
		remove: handleBunkerPriceDelete,
		init: handleBunkerPriceInit,
		add: handleBunkerPriceAdd,
		check: handleBunkerPriceCheck,
		handleBunkerPriceCombineParams,
		disableBunkers
	} = useBunkerPrice({
		addBunker: otherItem?.addBunker,
		voyageId: otherItem?.voyageId,
		id: otherItem?.id
	})

	const {
		summary: otherExpensesSummary,
		dataSource: otherExpenses,
		delIds: otherExpenseDelIds,
		change: handleOtherExpensesChange,
		add: handleOtherExpensesAdd,
		remove: handleOtherExpensesDelete,
		init: handleOtherExpensesInit
	} = useOtherExpensesIndex({ addOther: otherItem?.addOther })

	console.log("summary", {
		hireSummary,
		bunkerPriceSummary,
		otherExpensesSummary
		// fixedExpensesSumary
	})

	const summary = useMemo(() => {
		return {
			less:
				hireSummary?.less +
				bunkerPriceSummary?.less +
				otherExpensesSummary?.less +
				offGroupSummary?.less,
			plus:
				hireSummary?.plus +
				bunkerPriceSummary?.plus +
				otherExpensesSummary?.plus +
				offGroupSummary?.plus
			// fixedExpensesSumary
		}
	}, [hireSummary, bunkerPriceSummary, otherExpensesSummary, offGroupSummary])

	const check = () => {
		const { checkKey: offGroupCheckKey, checked: offGroupChecked } = handleOffGroupCheck()
		if (!offGroupChecked) return { checked: offGroupChecked, checkKey: offGroupCheckKey }
		const { checked: bunkerPriceChecked, checkKey: bunkerPriceCheckKey } = handleBunkerPriceCheck()
		if (!bunkerPriceChecked) return { checked: bunkerPriceChecked, checkKey: bunkerPriceCheckKey }

		const { checked: hireChecked, checkKey: hireCheckKey } = handleHireCheck()
		if (!hireChecked) return { checked: hireChecked, checkKey: hireCheckKey }

		return {
			checked: true,
			checkKey: null
		}
	}

	const init = (type: "init" | "details", item: Partial<InvStatementDescInitItemType>) => {
		handleHireInit(type, item)
		handleBunkerPriceInit(type, item?.bunkerPriceList)
		handleOtherExpensesInit(type, item?.otherFieldList)
		handleOffGroupInit(type, item?.offHireGroupList)
		setDataSource({
			voyageNo: item?.voyageNo ?? "",
			remitAmount: item?.remitAmount ?? 0,
			amount: item?.amount ?? 0,
			actualAmount: item?.actualAmount ?? 0
		})
	}

	return {
		descItem: {
			...dataSource,
			amount:
				hireSummary?.plus +
				bunkerPriceSummary?.plus +
				// fixedExpensesSumary -
				bunkerPriceSummary?.less -
				hireSummary?.less
		},
		summary,
		hireItems,
		handleHireCommit,
		bunkerPriceItems,
		handleBunkerPriceCommit,
		handleBunkerPriceDelete,
		handleBunkerPriceAdd,
		disableBunkers,
		otherExpenses,
		otherExpenseDelIds,
		handleOtherExpensesChange,
		handleOtherExpensesAdd,
		handleOtherExpensesDelete,
		check,
		init,
		handleHireInit,
		offGroup,
		offGroupDelIds,
		handleOffGroupCommit,
		handleOffGroupAdd,
		handleOffGroupDelete,
		handleOffGroupInit,
		handleOffHireOpen,
		handleOffHireConfirm,
		handleGroupAdd,
		handleGroupCommit,
		handleGroupDelete,
		handleGroupCombineParams,
		handleBunkerPriceCombineParams
	}
}

export default useIndex
