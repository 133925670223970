import { ExcludeKeys, ExtendsKeys, GroupOptionItemType, GroupOptionsType } from "@/types"
import { ColumnsType } from "antd/es/table/interface"
import { MovementKeys, MovementType } from "./index"
import React from "react"
import { dateFormat } from "@/tools/date"
import { formatThousandthNumber } from "@/tools/amount"
import { Popover } from "@/_components"
import { MovementTemporaryTasks } from "@/pages/voyagemanage/business/movement/list/components"
import classnames from "classnames"

/**
 * 表格列筛选分类名
 */
export type MovementCategoryKeys = "Basic" | "Voyage" | "Setup"

/**
 * 表格列
 */

export type MovementGroupColumnKeys =
	| ExtendsKeys<
			MovementKeys,
			| "actualCommenceDate"
			| "actualCompleteDate"
			| "cargoName"
			| "cargoQuantity"
			| "cargoUnit"
			| "chartererName"
			| "cpDate"
			| "cpNo"
			| "dischargingEta"
			| "dischargingEtb"
			| "dischargingPort"
			| "estRedeliveryDate"
			| "fixtureDate"
			| "fixtureNo"
			| "id"
			| "layCanFrom"
			| "layCanTo"
			| "loadingEta"
			| "loadingEtb"
			| "loadingPort"
			| "ownerName"
			| "redeliveryPortName"
			| "status"
			| "vesselName"
			| "voyageNo"
			| "voyageType"
			| "charteredBy"
			| "createdBy"
			| "operatedName"
			| "updatedDate"
			| "counterparty"
			| "option"
			| "totalCost"
			| "totalProfit"
			| "totalRevenue"
			| "taskList"
	  >
	| "chartererByName"
	| "createUserName"
	| "operatedByName"

export type DataType = {
	key: React.Key
} & Record<MovementGroupColumnKeys, any>

export const columns: ColumnsType<DataType> = [
	{
		dataIndex: "taskList",
		key: "taskList",
		width: 50,
		title: " ",
		align: "center",
		fixed: "left",
		render: (value, record, index) => {
			console.log("taskList", record)
			return (
				<Popover
					content={
						<MovementTemporaryTasks
							items={record?.taskList}
							currentVoyageId={record?.id}
							currentVoyageType={record?.voyageType}
						/>
					}
					trigger="click"
					disabled={!record?.taskList || record?.taskList?.length <= 0}
					children={
						<span
							className={classnames("font_family fleet-warn movement-task--warning", {
								"fleet-disabled": !record?.taskList || record?.taskList?.length <= 0
							})}
						></span>
					}
				/>
			)
		}
	},
	{
		dataIndex: "vesselName",
		width: 150,
		title: "Vessel Name",
		// title: i18n.t("vesselName",{name:"Vessel Name"}),
		key: "vesselName",
		align: "center",
		fixed: "left"
	},
	{
		dataIndex: "status",
		width: 150,
		title: "Status",
		// title: i18n.t("status",{name:"Status"}),
		key: "status",
		align: "center",
		fixed: "left"
	},
	{
		dataIndex: "cpDate",
		width: 150,
		// title: CargoBookLabelMap["fixtureDate"],
		title: "CP Date",
		key: "cpDate",
		align: "center"
	},
	{
		dataIndex: "cpNo",
		width: 150,
		title: "CP No.",
		key: "cpNo",
		align: "center"
	},
	{
		dataIndex: "voyageType",
		width: 150,
		// title: CargoBookLabelMap["cargoQuantity"],
		title: "Voyage Type",
		key: "voyageType",
		align: "center",
		render: (value, record, index) => {
			return <span className={classnames("movement-voyageType", value)}>{value}</span>
		}
	},
	{
		dataIndex: "voyageNo",
		width: 150,
		// title: CargoBookLabelMap["cargoUnit"],
		title: "Voyage No.",
		key: "voyageNo",
		align: "center"
	},
	{
		dataIndex: "fixtureNo",
		width: 200,
		// title: CargoBookLabelMap["layCanFrom"],
		title: "Fixture No.",
		key: "fixtureNo",
		align: "center"
	},
	{
		dataIndex: "fixtureDate",
		width: 200,
		// title: CargoBookLabelMap["layCanTo"],
		title: "Fixture Date",
		key: "fixtureDate",
		align: "center"
	},
	// {
	//   dataIndex: "loadPortCode",
	//   width: 150,
	//   title: CargoBookLabelMap["loadPortCode"],
	//   key: "loadPortCode",
	//   align: "center"
	// },
	{
		dataIndex: "cargoName",
		width: 150,
		// title: CargoBookLabelMap["loadPortName"],
		title: "Cargo Name",
		key: "cargoName",
		align: "center"
	},
	{
		dataIndex: "cargoQuantity",
		width: 200,
		// title: CargoBookLabelMap["discharingPortName"],
		title: "Cargo Quantity",
		key: "cargoQuantity",
		align: "center"
	},
	{
		dataIndex: "counterparty",
		width: 150,
		// title: CargoBookLabelMap["freightType"],
		title: "Counterparty",
		key: "counterparty",
		align: "center"
	},
	{
		dataIndex: "layCanFrom",
		width: 200,
		// title: CargoBookLabelMap["freightRate"],
		title: "Cargo Laycan From",
		key: "layCanFrom",
		align: "center",
		render: (value, record) => {
			return <span>{dateFormat("YY/mm/dd HH:MM", value)}</span>
		}
	},
	{
		dataIndex: "layCanTo",
		width: 150,
		// title: CargoBookLabelMap["commission"],
		title: "Cargo Laycan To",
		key: "layCanTo",
		align: "center",
		render: (value, record) => {
			return <span>{dateFormat("YY/mm/dd HH:MM", value)}</span>
		}
	},
	{
		dataIndex: "actualCommenceDate",
		width: 150,
		// title: CargoBookLabelMap["brokerName"],
		title: "Commence Date",
		key: "actualCommenceDate",
		align: "center"
	},
	{
		dataIndex: "actualCompleteDate",
		width: 150,
		// title: CargoBookLabelMap["brokerage"],
		title: "Complete Date",
		key: "actualCompleteDate",
		align: "center"
	},
	{
		dataIndex: "loadingPort",
		width: 160,
		title: "Load Port",
		key: "loadingPort",
		align: "center"
	},
	// {
	//   dataIndex: "loadingEta",
	//   width: 200,
	//   title: "Load ETA",
	//   key: "loadingEta",
	//   align: "center",
	//   render: (value, record) => {
	//     return <span>{dateFormat("YY/mm/dd HH:MM", value)}</span>
	//   }

	// },
	// {
	//   dataIndex: "loadingEtb",
	//   width: 150,
	//   title: "Load ETB",
	//   key: "loadingEtb",
	//   align: "center",
	//   render: (value, record) => {
	//     return <span>{dateFormat("YY/mm/dd HH:MM", value)}</span>
	//   }
	// },
	{
		dataIndex: "dischargingPort",
		width: 200,
		title: "Discharging Port",
		key: "dischargingPort",
		align: "center"
	},
	// {
	//   dataIndex: "dischargingEta",
	//   width: 150,
	//   title: "Discharging ETA",
	//   key: "dischargingEta",
	//   align: "center",
	//   render: (value, record) => {
	//     return <span>{dateFormat("YY/mm/dd HH:MM", value)}</span>
	//   }
	// },
	// {
	//   dataIndex: "dischargingEtb",
	//   width: 150,
	//   title: "Discharging ETB",
	//   key: "dischargingEtb",
	//   align: "center",
	//   render: (value, record) => {
	//     return <span>{dateFormat("YY/mm/dd HH:MM", value)}</span>
	//   }
	// },
	{
		dataIndex: "redeliveryPortName",
		width: 150,
		title: "Redelivery Port",
		key: "redeliveryPortName",
		align: "center"
	},
	{
		dataIndex: "estRedeliveryDate",
		width: 150,
		title: "Est. Redel Date",
		key: "estRedeliveryDate",
		align: "center",
		render: (value, record) => {
			return <span>{dateFormat("YY/mm/dd HH:MM", value)}</span>
		}
	},
	{
		dataIndex: "totalRevenue",
		width: 160,
		title: "Total Revenue",
		key: "totalRevenue",
		align: "center",
		render: (value, record, index) => {
			return <span>{formatThousandthNumber(value)}</span>
		}
	},
	{
		dataIndex: "totalCost",
		width: 160,
		title: "Total Cost",
		key: "totalCost",
		align: "center",
		render: (value, record, index) => {
			return <span>{formatThousandthNumber(value)}</span>
		}
	},
	{
		dataIndex: "totalProfit",
		width: 160,
		title: "PNL",
		key: "totalProfit",
		align: "center",
		render: (value, record, index) => {
			return <span>{formatThousandthNumber(value)}</span>
		}
	},
	{
		dataIndex: "chartererName",
		width: 150,
		title: "Charterer Name",
		key: "chartererName",
		align: "center"
	},
	{
		dataIndex: "chartererByName",
		width: 150,
		title: "Charterer By",
		key: "chartererByName",
		align: "center"
	},
	{
		dataIndex: "createUserName",
		width: 150,
		title: "Created By",
		key: "createUserName",
		align: "center"
	},
	{
		dataIndex: "operatedByName",
		width: 150,
		title: "Operated By",
		key: "operatedByName",
		align: "center"
	},
	{
		dataIndex: "updatedDate",
		width: 150,
		title: "Updated Date",
		key: "updatedDate",
		align: "center"
	}
]

export type MovementGroupOptionItemKeys = ExtendsKeys<
	MovementGroupColumnKeys,
	| "vesselName"
	| "cpDate"
	| "cpNo"
	| "voyageType"
	| "status"
	| "voyageNo"
	| "fixtureNo"
	| "fixtureDate"
	| "cargoName"
	| "cargoQuantity"
	| "counterparty"
	| "layCanFrom"
	| "layCanTo"
	| "actualCommenceDate"
	| "estRedeliveryDate"
	| "redeliveryPortName"
	| "actualCompleteDate"
	| "loadingPort"
	| "loadingEta"
	| "loadingEtb"
	| "dischargingPort"
	| "dischargingEta"
	| "dischargingEtb"
	| "chartererName"
	| "chartererByName"
	| "operatedByName"
	| "createUserName"
	| "totalCost"
	| "totalProfit"
	| "totalRevenue"
	| "charteredBy"
	| "createdBy"
	| "operatedName"
	| "updatedDate"
	| "status"
	| "option"
	| "taskList"
>

export type MovementGroupOptionItemType = GroupOptionItemType<MovementGroupOptionItemKeys>

export type MovementGroupOptionsType = GroupOptionsType<
	MovementCategoryKeys,
	MovementGroupOptionItemKeys
>

export const MovementGroupOptions: MovementGroupOptionsType[] = [
	{
		key: "Basic",
		basicDisabled: true,
		list: [
			{
				value: "vesselName",
				label: "Vessel Name",
				disabled: true
			},
			{
				value: "taskList",
				label: "Tip",
				disabled: true
			},
			{
				value: "cpDate",
				label: "CP Date"
			},
			{
				value: "cpNo",
				label: "CP No."
			},
			{
				value: "voyageType",
				label: "Voyage Type"
			},
			{
				value: "voyageNo",
				label: "Voyage No.",
				disabled: true
			},
			{
				value: "fixtureNo",
				label: "Fixture No."
			},
			{
				value: "fixtureDate",
				label: "Fixture Date"
			},
			{
				value: "cargoName",
				label: "Cargo Name"
			},
			{
				value: "cargoQuantity",
				label: "Cargo Quantity"
			},

			{
				value: "counterparty",
				label: "Counterparty"
			},

			{
				value: "layCanFrom",
				label: "Cargo Laycan From"
			},

			{
				value: "layCanTo",
				label: "Cargo Laycan To"
			},

			{
				value: "actualCommenceDate",
				label: "Commence Date"
			},

			{
				value: "actualCompleteDate",
				label: "Complete Date"
			},
			{
				value: "totalCost",
				label: "Total Cost"
			},
			{
				value: "totalRevenue",
				label: "Total Revenue"
			},
			{
				value: "totalProfit",
				label: "Total Profit"
			}
		]
	},
	{
		key: "Voyage",
		basicDisabled: false,
		list: [
			{
				value: "loadingPort",
				label: "Load Port",
				disabled: true
			},
			// {
			//   "value": "loadingEta",
			//   "label": "Load Eta",
			//   disabled: true
			// },
			// {
			//   "value": "loadingEtb",
			//   "label": "Load Etb",
			//   disabled: true
			// },
			{
				value: "dischargingPort",
				label: "Discharging Port",
				disabled: true
			},
			// {
			//   "value": "dischargingEta",
			//   "label": "Discharging Eta",
			//   disabled: true
			// },
			// {
			//   "value": "dischargingEtb",
			//   "label": "Discharging Etb",
			//   disabled: true
			// },
			{
				value: "chartererName",
				label: "Charterer Name"
			},
			{
				value: "estRedeliveryDate",
				label: "Est. Redel Date"
			},
			{
				value: "redeliveryPortName",
				label: "Redelivery Port"
			}
		]
	},
	{
		key: "Setup",
		basicDisabled: true,
		list: [
			{
				value: "createUserName",
				label: "Created By"
			},
			{
				value: "chartererByName",
				label: "Chartered By"
			},
			{
				value: "operatedByName",
				label: "Operated By"
			},
			{
				value: "updatedDate",
				label: "Updated Date"
			},
			{
				value: "status",
				label: "Status",
				disabled: true
			}
		]
	}
]

export type MovementCategoryGroupType = Record<MovementCategoryKeys, MovementGroupColumnKeys[]>

export const initMovementCategoryGroup: MovementCategoryGroupType = {
	Basic: [
		"vesselName",
		"taskList",
		"voyageNo",
		"voyageType"
		// "totalCost",
		// "totalRevenue",
		// "totalProfit"
	],
	Voyage: [
		"loadingPort",
		// "loadingEta",
		// "loadingEtb",
		"dischargingPort",
		// "dischargingEta",
		// "dischargingEtb",
		"estRedeliveryDate",
		"redeliveryPortName"
	],
	Setup: ["status"]
}
