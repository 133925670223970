import { FleetOptionType } from "@/types/advancedType"
import { MovementTcvcOtherItemType } from "./type"
import i18n from "@/locale"
import { MovementDialogItemKeys } from "../../type"
import { CommonInitItemEvent } from "@/types/event"

export const movementTcvcDialogOptions: (FleetOptionType<string, MovementDialogItemKeys> & {
	disabled?: boolean
})[] = [
	{
		label: i18n.t("voyageManagement.movement.bunkerPlan"),
		value: "bunkerPlan"
	},
	// {
	// 	label: i18n.t("voyageManagement.movement.stowagePlan"),
	// 	value: "stowagePlan",
	// 	disabled: true
	// },
	// {
	// 	label: i18n.t("voyageManagement.movement.offHire"),
	// 	value: "offHire"
	// },
	{
		label: i18n.t("voyageManagement.movement.otherExpenses"),
		value: "otherExpenses"
	},
	{
		label: i18n.t("voyageManagement.movement.otherIncome"),
		value: "otherIncome"
	},
	{
		label: i18n.t("voyageManagement.movement.disputeCharge"),
		value: "disputeCharge"
	},
	{
		label: i18n.t("voyageManagement.movement.portExpenses"),
		value: "portExpenses"
	},
	{
		label: i18n.t("voyageManagement.movement.seaReports"),
		value: "seaReports"
		// disabled: true
	},
	{
		label: i18n.t("voyageManagement.movement.portLog"),
		value: "portLog"
		// disabled: true
	},
	{
		label: i18n.t("voyageManagement.movement.handoverForm"),
		value: "handoverForm"
	},
	{
		label: i18n.t("voyageManagement.movement.layTime"),
		value: "layTime"
	},
	{
		label: i18n.t("voyageManagement.movement.otherReports"),
		value: "otherReports"
	},
	{
		label: i18n.t("voyageManagement.movement.commission"),
		value: "commission"
	}
]

export const initialMovementBunkerCost: CommonInitItemEvent<
	MovementTcvcOtherItemType["bunkerPlan"]
> = (item) => {
	return {
		lsfoTotalPrice: item?.lsfoTotalPrice ?? 0,
		hsfoTotalPrice: item?.hsfoTotalPrice ?? 0,
		mdoTotalPrice: item?.mdoTotalPrice ?? 0,
		mgoTotalPrice: item?.mgoTotalPrice ?? 0,
		scrubberTotalPrice: item?.scrubberTotalPrice ?? 0,
		totalBunkerPlanExpense: item?.totalBunkerPlanExpense ?? 0,
		settledTime: item?.settledTime ?? null,
		status: item?.status ?? "Settled",
		bunkerPlanExpenseSettled: item?.bunkerPlanExpenseSettled ?? null,
		bunkerPlanSettled: item?.bunkerPlanSettled ?? null
	}
}

export const initialMovementTcvcOtherItem: CommonInitItemEvent<MovementTcvcOtherItemType> = (
	item
) => {
	return {
		id: item?.id ?? null,
		highlightedPoint: item?.highlightedPoint ?? "",
		layTimeSubtotal: item?.layTimeSubtotal ?? [],
		otherExpense: item?.otherExpense ?? [],
		otherIncome: item?.otherIncome ?? "",
		ownerAccount: item?.ownerAccount ?? "",
		portExpenseList: item?.portExpenseList ?? [],
		resultId: item?.resultId ?? null,
		stage: item?.stage ?? "Delivery",
		status: item?.status ?? "Commencing",
		totalDemAmount: item?.totalDemAmount ?? "",
		totalDesAmount: item?.totalDesAmount ?? "",
		voyageNo: item?.voyageNo ?? "",
		voyageRemark: item?.voyageRemark ?? "",
		disputeChargeList: item?.disputeChargeList ?? [],
		hireList: item?.hireList ?? [],
		freightList: item?.freightList ?? [],
		bunkerPlan: initialMovementBunkerCost(item?.bunkerPlan),
		mainPlan: item?.mainPlan ?? null,
		voyageType: item?.voyageType ?? "TCVC",
		ar: item?.ar ?? null,
		ap: item?.ap ?? null,
		totalInsure: item?.totalInsure ?? 0,
		demDesList: item?.demDesList ?? [],
		commissionList: item?.commissionList ?? [],
		insureArApDetail: item?.insureArApDetail ?? null,
		portExpenseDetailList: item?.portExpenseDetailList ?? []
	}
}
