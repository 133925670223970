import { FleetOptionType } from "@/types/advancedType"
import { InvFreightPortItemType } from "./type"

export const initialInvFreightPortItem = (
	type: "init" | "details",
	item?: Partial<InvFreightPortItemType>
): InvFreightPortItemType => {
	return {
		id: type === "details" && item?.id ? item?.id : null,
		invId: type === "details" && item?.invId ? item?.invId : null,
		invPeriod: item?.invPeriod ?? 1,
		portName: item?.portName ?? "",
		purpose: item?.purpose ?? "DC",
		rate: item?.rate ?? "",
		rateType: item?.rateType ?? "NOT",
		addComm: item?.addComm ?? "",
		addOnType: item?.addOnType ?? 0,
		freightBrokerageRate: item?.freightBrokerageRate ?? "",
		freightBrokerage: item?.freightBrokerage ?? 0
	}
}

export const rateTypeOptions: FleetOptionType<
	InvFreightPortItemType["rateType"],
	InvFreightPortItemType["rateType"]
>[] = [
	{ label: "NOT", value: "NOT" },
	{ label: "DEM", value: "DEM" },
	{ label: "DES", value: "DES" }
]

export const checkKeys: Partial<keyof InvFreightPortItemType>[] = ["portName", "rate", "rateType"]
