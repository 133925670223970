import { InvCommonFieldInitEvent } from "@/pages/voyagemanage/business/invoices/module"
import { InvStatementBunkerPriceProps } from "./type"
import { InvStatementBunkerProps } from "../../common"

export const allBunkers: InvStatementBunkerPriceProps["items"][number]["bunkerType"][] = [
	"HSFO",
	"LSFO",
	"LSMGO",
	"MDO"
]

export const initialBunkerPrice: InvCommonFieldInitEvent<
	InvStatementBunkerProps["items"][number]
> = (type, item) => {
	return {
		id: type === "details" && item?.id ? item?.id : null,
		invId: type === "details" && item?.invId ? item?.invId : null,
		voyageId: item?.voyageId ?? null,
		bunkerType: item?.bunkerType ?? "HSFO",
		type: item?.type ?? "BOD",
		consumption: item?.consumption ? Math.round(+item.consumption * 1000) / 1000 : "",
		price: item?.price ? Math.round(+item.price * 1000) / 1000 : "",
		total: item?.total ? Math.round(+item.total * 1000) / 1000 : 0,
		groupId: null
	}
}

export const checkKeys: (keyof InvStatementBunkerProps["items"][number])[] = [
	"consumption",
	"price",
	"bunkerType"
]
