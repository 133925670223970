import React, { FC } from "react"
import style from "./index.module.less"
import classnames from "classnames"
import { VoyageManageAttachAndContactProps } from "./type"
import { FleetImage, FleetLoading, FleetTooltip } from "@/components"
import { VoyageManageAttachAndContactTitleMap, contactOptions } from "./source"
import useIndex from "./hooks/useIndex"
import { CommonContactSelect, CommonRoleWrap, FleetPrompt, FleetToast } from "@/common"
import { Button, Icon, Popover } from "@/_components"
import { VoyageManageAttachUpload } from "./components"
import { log } from "console"

const VoyageManageAttachAndContact: FC<VoyageManageAttachAndContactProps> = ({
	voyageId,
	type,
	attachment,
	contact,
	onCommit,
	onDelete,
	onContactSelect,
	onAdd
}) => {
	const {
		hide,
		loading,
		previewContainerRef,
		docxPreview,
		previewLoading,
		handleUpload,
		handleAttachDelete,
		handleContactDelete,
		handlePreview,
		handleUploadClose,
		handleUploadOpen,
		handlePreviewClose
	} = useIndex(voyageId, type, onCommit, onDelete)

	return (
		<div className={style["voyagemanage-attachAndcontact"]}>
			<FleetLoading loading={loading} />
			<span className="attachAndcontact-title">{VoyageManageAttachAndContactTitleMap[type]}</span>
			<CommonRoleWrap roleStr="preOp:detail:upload">
				<Button
					type="secondary"
					prefixIcon="fleet-update1"
					size="small"
					disabled={loading}
					onClick={handleUploadOpen}
				>
					Click to Upload
				</Button>
			</CommonRoleWrap>

			<div className="attach-container">
				{attachment?.map((item, index) => {
					return (
						<div className="attach-item" key={index}>
							<FleetImage className="attach-icon" />
							<span className="attach-name">{item?.fileName}</span>
							<div className="attach-actions">
								<span
									className="font_family fleet-download"
									onClick={() => {
										handlePreview?.("download", item)
									}}
								></span>
								{item?.filePath?.indexOf("eml") == -1 && item?.filePath?.indexOf("xlsx") == -1 && (
									<span
										className="font_family fleet-show"
										onClick={() => {
											handlePreview?.("preview", item)
										}}
									></span>
								)}
								<FleetTooltip
									disabled={!voyageId}
									outerLayer={<span className="font_family fleet-delete"></span>}
									render={(setIsOpen) => {
										return (
											<FleetToast
												label="That file will be deleted. Are you sure?"
												onCancel={() => {
													setIsOpen(false)
												}}
												onConfirm={() => {
													setIsOpen(false)
													handleAttachDelete?.(item?.id)
												}}
											/>
										)
									}}
								/>
							</div>
						</div>
					)
				})}
			</div>
			<CommonRoleWrap roleStr="preOp:detail:addContact">
				<CommonContactSelect
					placeholder="Select a contact"
					className="contact-select-container"
					showKey="companyName"
					primaryKey="id"
					activeItems={{
						id: null,
						companyName: ""
					}}
					onFinish={(newItem, operateType) => {
						onContactSelect?.(type, newItem)
					}}
					contactActions={{
						onConfirm: (item) => {
							onAdd?.(item, type)
						}
					}}
				/>
			</CommonRoleWrap>

			<div className="contact-container">
				{contact?.map((item, index) => {
					return (
						<div className="contact-item" key={index}>
							<div className="contactItem-header">
								<div className="header-contactType">
									<span className="font_family fleet-avatar"></span>
									<span>{item?.businessType}</span>
								</div>
								<div className="header-actions">
									<FleetTooltip
										disabled={!voyageId || !item?.del}
										outerLayer={
											<span
												className={classnames("font_family fleet-delete", {
													"fleet-disabled": !item?.del
												})}
											></span>
										}
										render={(setIsOpen) => {
											return (
												<FleetToast
													label="That file will be deleted. Are you sure?"
													onCancel={() => {
														setIsOpen(false)
													}}
													onConfirm={() => {
														setIsOpen(false)
														handleContactDelete?.(item)
													}}
												/>
											)
										}}
									/>
								</div>
							</div>
							<ul className="contactItem-container">
								{contactOptions?.map((contactItem, contactIndex) => {
									return (
										<Popover
											trigger="hover"
											placement="top-start"
											children={
												<li key={contactItem?.value}>
													{contactItem?.label}: {item?.[contactItem?.value]}
												</li>
											}
											content={<span>{item?.[contactItem?.value]}</span>}
										/>
									)
								})}
							</ul>
						</div>
					)
				})}
			</div>
			<VoyageManageAttachUpload
				loading={loading}
				items={attachment}
				hide={hide}
				onConfirm={handleUpload}
				onClose={handleUploadClose}
				onDelete={handleAttachDelete}
			/>
			<FleetPrompt hide={!docxPreview} mode="fixed">
				<div className={style["voyageManage-docx-preview"]}>
					<FleetLoading loading={previewLoading} />
					<div className="docx-preview-header">
						<span className="header-title">Preview</span>
						<Icon type="fleet-close" className="header-close" onClick={handlePreviewClose} />
					</div>
					<div className="docx-preview-container">
						<div
							ref={previewContainerRef}
							style={{
								overflow: "auto"
							}}
						/>
					</div>
				</div>
			</FleetPrompt>
		</div>
	)
}

export default VoyageManageAttachAndContact
