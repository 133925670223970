import React, { FC } from "react"
import style from "./index.module.less"
import { MovementCharterBaseProps } from "./type"
import {
	MovementCargo,
	MovementCharterBasePerformance,
	MovementPlanList,
	MovementPortRotation
} from "./components"
import { FleetLoading } from "@/components"

const MovementCharterBase: FC<MovementCharterBaseProps> = ({
	estQueryLoading,
	activePlanIndex,
	activePlanType,
	portItems,
	cargoItems,
	planActions,
	planItems,
	onPortItemChange,
	onPortItemCommit,
	onPortItemDelete,
	onPortItemMove,
	onPortItemAdd,
	onCargoItemChange,
	onCargoItemCommit,
	estLoading,
	onEstVesselParChange,
	onEstCargoChange,
	onEstCargoCommit,
	estCargoItem,
	estPortItems,
	estOtherItem,
	estVesselItem,
	onEstOtherChange,
	onEstPortCommit,
	onEstPortItemAdd,
	onEstPortItemChange,
	onEstPortItemCommit,
	onEstPortItemDelete,
	onEstPortBatchCommit,
	onEstPortConsumCommit
}) => {
	return (
		<div className={style["movement-charterBase"]}>
			<FleetLoading loading={estLoading || estQueryLoading} />
			<div className="movement-details--header">
				<span className="fleet-title">Charter Base</span>
			</div>
			<div className="charterBase-container">
				<MovementPlanList
					planItems={planItems}
					onPlanSelect={planActions?.onSelect}
					onPlanAdd={planActions?.onAdd}
					onPlanUpload={planActions?.onUpload}
					onPlanSave={planActions?.onSave}
					onPlanSet={planActions?.onSet}
					activePlanIndex={activePlanIndex}
					activeType={activePlanType}
				/>
				{activePlanType === "est" && (
					<MovementCharterBasePerformance
						otherItem={estOtherItem}
						dataSource={estVesselItem}
						onChange={onEstVesselParChange}
						onEstBatchCommit={onEstPortBatchCommit}
						onEstConsumCommit={onEstPortConsumCommit}
					/>
				)}
				<MovementPortRotation
					actualItems={portItems}
					activeType={activePlanType}
					onActualChange={onPortItemChange}
					onActualCommit={onPortItemCommit}
					onActualDelete={onPortItemDelete}
					onActualAdd={onPortItemAdd}
					onActualMove={onPortItemMove}
					vesselSource={{
						portFuelIdle: estVesselItem?.portFuelIdle,
						portFuelMdo: estVesselItem?.portFuelMdo,
						portFuelWork: estVesselItem?.portFuelWork,
						portIdleMdo: estVesselItem?.portIdleMdo,
						portWorkMdo: estVesselItem?.portWorkMdo,
						ballastSpeed: estVesselItem?.ballastSpeed,
						ladenSpeed: estVesselItem?.ladenSpeed
					}}
					estItems={estPortItems}
					estOtherItem={estOtherItem}
					onEstOtherChange={onEstOtherChange}
					onEstChange={onEstPortItemChange}
					onEstCommit={onEstPortItemCommit}
					onEstPortCommit={onEstPortCommit}
					onEstAdd={onEstPortItemAdd}
					onEstDelete={onEstPortItemDelete}
					cargoSource={{
						cargoQuantity: estCargoItem?.cargoQuantity
					}}
				/>
				<MovementCargo
					actualItems={cargoItems}
					estItems={[estCargoItem]}
					onActualChange={onCargoItemChange}
					onActualCommit={onCargoItemCommit}
					onEstChange={(currentIndex, key, value) => {
						onEstCargoChange?.(key, value)
					}}
					onEstCommit={(currentIndex, item) => {
						onEstCargoCommit?.(item)
					}}
					activeType={activePlanType}
				/>
			</div>
		</div>
	)
}

export default MovementCharterBase
