import React, { FC, Fragment, useMemo } from "react"
import { InvFreightPortItemType, InvFreightPortProps } from "./type"
import { InvoiceAddTo } from "@/pages/voyagemanage/business/invoices/module"
import { FleetFormItem, FleetSelect, FleetVerifyInput } from "@/components"
import { CommonPortSelect } from "@/common"
import { rateTypeOptions } from "./source"
import { formatThousandthNumber } from "@/tools/amount"

const InvFreightPort: FC<InvFreightPortProps> = ({
	loadItems,
	dischargeItems,
	onChange,
	onCommit,
	otherItem,
	onOtherItemChange
}) => {
	return (
		<Fragment>
			<InvoiceAddTo type="addDesDem" value={otherItem?.addDesDem} onChange={onOtherItemChange}>
				<span className="invoicesForm-subheading">DES/DEM</span>
			</InvoiceAddTo>
			{loadItems?.map((loadItem, loadIndex) => {
				return (
					<div className="invoicesForm-container freightInv-desContainer" key={loadIndex}>
						<FleetFormItem label="Load Port" needVerity>
							<CommonPortSelect
								primaryKey="portName"
								showKey="portName"
								activeItems={{
									portName: loadItem?.portName
								}}
								onFinish={(newItem, type) => {
									if (newItem?.portName === loadItem?.portName) return
									onChange?.("load", loadIndex, "portName", newItem?.portName)
								}}
							/>
						</FleetFormItem>
						<FleetFormItem label="Type" needVerity>
							<FleetSelect
								value={[loadItem?.rateType]}
								options={rateTypeOptions}
								onChange={(val: InvFreightPortItemType["rateType"]) => {
									const params: Partial<
										Pick<
											InvFreightPortProps["loadItems"][number],
											"rate" | "rateType" | "addOnType"
										>
									> = {
										rateType: val
									}
									switch (true) {
										case val === "NOT":
											params.rate = 0
											params.addOnType = 0
											break
										case val === "DES":
											params.addOnType = 0
											break
										case val === "DEM":
											params.addOnType = +((+loadItem?.rate * +loadItem?.addComm) / 100)?.toFixed(3)
											break
										default:
											break
									}
									onCommit?.("load", loadIndex, params)
								}}
							/>
						</FleetFormItem>
						<FleetVerifyInput
							label="Amount($)"
							needVerity
							value={loadItem?.rate}
							type="number"
							onChange={(val: number) => {
								const addOnType =
									loadItem?.rateType === "DEM" ? (val * +loadItem?.addComm) / 100 : 0
								const freightBrokerage =
									loadItem?.rateType === "DEM" ? (+loadItem?.freightBrokerageRate / 100) * +val : 0
								onCommit?.("load", loadIndex, {
									rate: val,
									addOnType: +addOnType?.toFixed(3),
									freightBrokerage
								})
							}}
						/>
						{loadItem?.rateType === "DEM" && (
							<div className="flex gap-[8px] items-center">
								<FleetVerifyInput
									label="Add.Comm.(%)"
									min={0}
									max={100}
									value={loadItem?.addComm}
									className="desContainer-addCommm"
									type="number"
									onChange={(val: number) => {
										const addOnType = (+loadItem?.rate * +val) / 100
										onCommit?.("load", loadIndex, {
											addComm: val,
											addOnType: +addOnType?.toFixed(3)
										})
									}}
								/>
								<FleetVerifyInput
									label={`Add. on ${loadItem?.rateType?.toUpperCase()}($)`}
									disabled
									value={loadItem?.addOnType}
									type="number"
									className="desContainer-addCommm"
								/>
							</div>
						)}
						{loadItem?.rateType === "DEM" && (
							<div className="desContainer-brokerage">
								<FleetVerifyInput
									label="Load Brokerage Rate(%)"
									min={0}
									value={loadItem?.freightBrokerageRate}
									type="number"
									onChange={(val: number) => {
										const freightBrokerage = (val / 100) * +loadItem?.rate
										onCommit?.("load", loadIndex, {
											freightBrokerageRate: val,
											freightBrokerage: +freightBrokerage?.toFixed(3)
										})
									}}
								/>
								<FleetVerifyInput
									min={0}
									label="Load Brokerage($)"
									disabled
									value={loadItem?.freightBrokerage}
									type="number"
								/>
							</div>
						)}
					</div>
				)
			})}
			{dischargeItems?.map((dischargeItem, dischargeIndex) => {
				return (
					<div className="invoicesForm-container freightInv-desContainer" key={dischargeIndex}>
						<FleetFormItem label="Discharge Port" needVerity>
							<CommonPortSelect
								primaryKey="portName"
								showKey="portName"
								activeItems={{
									portName: dischargeItem?.portName
								}}
								onFinish={(newItem, type) => {
									if (newItem?.portName === dischargeItem?.portName) return
									onChange?.("discharge", dischargeIndex, "portName", newItem?.portName)
								}}
							/>
						</FleetFormItem>
						<FleetFormItem label="Type" needVerity>
							<FleetSelect
								value={[dischargeItem?.rateType]}
								options={rateTypeOptions}
								onChange={(val: InvFreightPortItemType["rateType"]) => {
									const params: Partial<
										Pick<
											InvFreightPortProps["loadItems"][number],
											"rate" | "rateType" | "addOnType"
										>
									> = {
										rateType: val
									}
									switch (true) {
										case val === "NOT":
											params.rate = 0
											params.addOnType = 0
											break
										case val === "DES":
											params.addOnType = 0
											break
										case val === "DEM":
											params.addOnType = +(
												(+dischargeItem?.rate * +dischargeItem?.addComm) /
												100
											)?.toFixed(3)
											break
										default:
											break
									}
									onCommit?.("discharge", dischargeIndex, params)
								}}
							/>
						</FleetFormItem>
						<FleetVerifyInput
							label="Amount($)"
							needVerity
							value={dischargeItem?.rate}
							type="number"
							onChange={(val: number) => {
								const addComm =
									dischargeItem?.rateType === "NOT" ? 0 : (val * +dischargeItem?.addComm) / 100
								const freightBrokerage =
									dischargeItem?.rateType === "DEM"
										? (+dischargeItem?.freightBrokerageRate / 100) * +val
										: 0
								onCommit?.("discharge", dischargeIndex, {
									rate: val,
									addOnType: +addComm?.toFixed(3),
									freightBrokerage: freightBrokerage
								})
							}}
						/>
						{dischargeItem?.rateType === "DEM" && (
							<div className="flex gap-[8px] items-center">
								<FleetVerifyInput
									label="Add.Comm.(%)"
									min={0}
									max={100}
									value={dischargeItem?.addComm}
									className="desContainer-addCommm"
									type="number"
									onChange={(val: number) => {
										const addComm =
											dischargeItem?.rateType === "NOT" ? 0 : (+dischargeItem?.rate * val) / 100
										onCommit?.("discharge", dischargeIndex, {
											addComm: val,
											addOnType: +addComm?.toFixed(3)
										})
									}}
								/>
								<FleetVerifyInput
									label={`Add. on ${dischargeItem?.rateType?.toUpperCase()}`}
									disabled
									value={dischargeItem?.addOnType}
									type="number"
									className="desContainer-addCommm"
								/>
							</div>
						)}

						{dischargeItem?.rateType === "DEM" && (
							<div className="desContainer-brokerage">
								<FleetVerifyInput
									min={0}
									label="Discharge Brokerage Rate(%)"
									value={dischargeItem?.freightBrokerageRate}
									type="number"
									onChange={(val: number) => {
										const freightBrokerage = (val / 100) * +dischargeItem?.rate
										onCommit?.("discharge", dischargeIndex, {
											freightBrokerageRate: val,
											freightBrokerage: +freightBrokerage?.toFixed(3)
										})
									}}
								/>
								<FleetVerifyInput
									min={0}
									label="Discharge Brokerage($)"
									disabled
									value={dischargeItem?.freightBrokerage}
									type="number"
								/>
							</div>
						)}
					</div>
				)
			})}
			<div className="flex flex-col border-t border-solid border-[#E7E9EC] pt-[16px]">
				{loadItems?.map((portItem, portIndex) => {
					if (portItem?.rateType === "NOT") return
					return (
						<ul className="w-full flex flex-col gap-[4px]">
							<li className="text-[14px] leading-[22px] font-normal">
								<span className="text-[#A3A3A3]">
									{portItem?.rateType?.toUpperCase()} at Load Port {portItem?.portName}:{" "}
								</span>
								<span className="text-[#7D7D7D]">{formatThousandthNumber(+portItem?.rate)} $</span>
							</li>
							{portItem?.rateType === "DEM" && (
								<span>
									<li className="text-[14px] leading-[22px] font-normal">
										<span className="text-[#A3A3A3]">
											DEMURRAGE ADD COMM AT {portItem?.rateType?.toUpperCase()}:{" "}
										</span>
										<span className="text-[#7D7D7D]">
											{formatThousandthNumber(+portItem?.rate)} $ * {portItem?.addComm}% =
											{formatThousandthNumber(-portItem?.addOnType)} $
										</span>
									</li>
									<li className="text-[14px] leading-[22px] font-normal">
										<span className="text-[#A3A3A3]">DEMURRAGE Brokerage : </span>
										<span className="text-[#7D7D7D]">
											{formatThousandthNumber(+portItem?.rate)} $ *{" "}
											{portItem?.freightBrokerageRate || 0}% =
											{formatThousandthNumber(-portItem?.freightBrokerage || -0)} $
										</span>
									</li>
								</span>
							)}
						</ul>
					)
				})}
				{dischargeItems?.map((portItem, portIndex) => {
					if (portItem?.rateType === "NOT") return
					return (
						<ul className="w-full flex flex-col gap-[4px]">
							<li className="text-[14px] leading-[22px] font-normal">
								<span className="text-[#A3A3A3]">
									{portItem?.rateType?.toUpperCase()} at Discharge Port {portItem?.portName}:{" "}
								</span>
								<span className="text-[#7D7D7D]">{formatThousandthNumber(+portItem?.rate)} $</span>
							</li>
							{portItem?.rateType === "DEM" && (
								<li>
									<li className="text-[14px] leading-[22px] font-normal">
										<span className="text-[#A3A3A3]">
											DEMURRAGE ADD COMM AT {portItem?.rateType?.toUpperCase()}:{" "}
										</span>
										<span className="text-[#7D7D7D]">
											{formatThousandthNumber(+portItem?.rate)} $ * {portItem?.addComm}% =
											{formatThousandthNumber(-portItem?.addOnType)} $
										</span>
									</li>
									<li className="text-[14px] leading-[22px] font-normal">
										<span className="text-[#A3A3A3]">DEMURRAGE Brokerage : </span>
										<span className="text-[#7D7D7D]">
											{formatThousandthNumber(+portItem?.rate)} $ *{" "}
											{portItem?.freightBrokerageRate || 0}% =
											{formatThousandthNumber(-portItem?.freightBrokerage || -0)} $
										</span>
									</li>
								</li>
							)}
						</ul>
					)
				})}
			</div>
		</Fragment>
	)
}

export default InvFreightPort
