import { CommonInitItemEvent } from "@/types/event"
import { InvFreightSettlementType } from "./type"

export const initialInvFreightSettlementItem: CommonInitItemEvent<InvFreightSettlementType> = (
	item
) => {
	return {
		freightIncome: item?.freightIncome ?? 0,
		freightPercent: item?.freightPercent ?? 100,
		freightRate: item?.freightRate ?? "",
		freightType: item?.freightType ?? "FR",
		remitAmount: item?.remitAmount ?? 0,
		ttlCargoQuantity: item?.ttlCargoQuantity ?? "",
		brokerageRate: item?.brokerageRate ?? "",
		brokerage: item?.brokerage ?? 0,
		freightBrokerage: item?.freightBrokerage ?? 0,
		freightBrokerageRate: item?.freightBrokerageRate ?? "",
		addCommRate: item?.addCommRate ?? "",
		addComm: item?.addComm ?? 0,
		fixtureDate: item?.fixtureDate ?? "",
		imo: item?.imo ?? null,
		mmsi: item?.mmsi ?? null,
		vesselName: item?.vesselName ?? "",
		voyageNo: item?.voyageNo ?? "",
		amount: item?.amount ?? 0,
		addCommPaymentRatio: item?.addCommPaymentRatio ?? item?.freightPercent ?? 100,
		brokeragePaymentRatio: item?.brokeragePaymentRatio ?? ""
	}
}

export const checkKeys: (keyof InvFreightSettlementType)[] = [
	"freightRate",
	"ttlCargoQuantity",
	"freightIncome",
	"freightPercent"
]
