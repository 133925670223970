import React, { FC } from "react"
import style from "./index.module.less"
import classnames from "classnames"
import { InvFreightPortDetailsProps } from "./type"
import { InvoiceAddTo } from "@/pages/voyagemanage/business/invoices/module"
import { FleetFormItem } from "@/components"
import { CommonPortSelect } from "@/common"

const InvFreightPortDetails: FC<InvFreightPortDetailsProps> = ({
	loadPortItems,
	dischargePortItems,
	onAdd,
	onDelete,
	otherItem,
	onOtherItemChange
}) => {
	return (
		<div className={classnames("invoicesDetail-form", style["invFreight-portDetails"])}>
			<div className="invoicesForm-header">
				<InvoiceAddTo type="addPort" value={otherItem?.addPort} onChange={onOtherItemChange}>
					<span>Port Details</span>
				</InvoiceAddTo>
				<span>Port information</span>
			</div>
			<div className="invoicesForm-container invoicesForm-ports">
				<FleetFormItem label="Load Port">
					<CommonPortSelect
						showKey="portName"
						primaryKey="portName"
						showCount={3}
						mode={"tags"}
						activeItems={loadPortItems}
						isInput={true}
						onFinish={(newItem, type) => {
							const currentIndex = loadPortItems?.findIndex(
								(selectItem) => selectItem?.portName === newItem?.portName
							)
							switch (true) {
								case type === "add":
									onAdd?.("load", { portName: newItem?.portName, purpose: "LD" })
									break
								case type === "del":
									onDelete?.("load", currentIndex)
									break
								default:
									break
							}
						}}
					/>
				</FleetFormItem>
			</div>
			<div className="invoicesForm-container invoicesForm-ports">
				<FleetFormItem label="Discharge Port">
					<CommonPortSelect
						showKey="portName"
						primaryKey="portName"
						mode={"tags"}
						showCount={3}
						activeItems={dischargePortItems}
						isInput={true}
						onFinish={(newItem, type) => {
							const currentIndex = dischargePortItems?.findIndex(
								(selectItem) => selectItem?.portName === newItem?.portName
							)
							switch (true) {
								case type === "add":
									onAdd?.("discharge", { portName: newItem?.portName, purpose: "DC" })
									break
								case type === "del":
									onDelete?.("discharge", currentIndex)
									break
								default:
									break
							}
						}}
					/>
				</FleetFormItem>
			</div>
		</div>
	)
}

export default InvFreightPortDetails
