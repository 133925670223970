import {
	deleteVoyageAttactAction,
	deleteVoyageContactAction,
	uploadVmVoyageAttachAction
} from "@/action/voyageManage/preOperation"
import {
	VoyageManageAttachAndContactProps,
	VoyageManageAttachmentItemType,
	VoyageManageContactItemType,
	VoyageManageVoyageRemarkCommitEvent,
	VoyageManageVoyageRemarkDeleteEvent
} from "../type"
import { useAppDispatch } from "@/hook"
import { useNavigate } from "react-router-dom"
import useReminder from "@/hook/useReminder"
import { useRef, useState } from "react"
import { getMovementFilePathAction } from "@/action/voyageManage/movement"
import * as docx from "docx-preview"
const useIndex = (
	voyageId: string,
	type: VoyageManageAttachAndContactProps["type"],
	onCommit: VoyageManageVoyageRemarkCommitEvent,
	onDelete: VoyageManageVoyageRemarkDeleteEvent
) => {
	const dispatch = useAppDispatch()
	const navigate = useNavigate()
	const { reminder } = useReminder()

	const [hide, setHide] = useState(true)
	const [loading, setLoading] = useState(false)
	const [docxPreview, setDocxPreview] = useState(false)
	const [previewLoading, setPreviewLoading] = useState(false)

	const previewContainerRef = useRef(null) // 预览容器的引用

	const uploadVmVoyageAttachActionFront = () => {
		setLoading(true)
	}

	const uploadVmVoyageAttachActionSuccess = (response) => {
		setLoading(false)
		reminder("success", "Attachment uploaded successfully")
		setHide(true)
		onCommit?.(type, "attachment", response?.data)
	}

	const uploadVmVoyageAttachActionFailed = (error) => {
		setLoading(false)
		reminder("error", error?.msg ? error?.msg + ": " + error?.data : error?.data)
	}

	const handleUpload = (files: File[]) => {
		if (!voyageId || loading || files?.length <= 0) return
		const formData = new FormData()
		for (let file of files) {
			formData.append("files", file)
		}
		uploadVmVoyageAttachAction(
			{
				voyageId,
				type,
				files: formData
			},
			uploadVmVoyageAttachActionFront,
			uploadVmVoyageAttachActionSuccess,
			uploadVmVoyageAttachActionFailed,
			dispatch,
			navigate
		)
	}

	const handleAttachDelete = (id: number) => {
		if (!voyageId) {
			reminder("error", "The voyage you have chosen is not a valid one")
			return
		}
		deleteVoyageAttactAction(
			{ id: id },
			uploadVmVoyageAttachActionFront,
			(response) => {
				onDelete?.(type, "attachment", id)
				setLoading(false)
				reminder("success", response?.msg)
			},
			uploadVmVoyageAttachActionFailed,
			dispatch,
			navigate
		)
	}

	const handleContactDelete = (item: VoyageManageContactItemType) => {
		if (!item?.del) return
		deleteVoyageContactAction(
			{
				id: item?.id
			},
			uploadVmVoyageAttachActionFront,
			(response) => {
				onDelete?.(type, "contact", item?.id)
				setLoading(false)
				reminder("success", response?.msg)
			},
			uploadVmVoyageAttachActionFailed,
			dispatch,
			navigate
		)
	}

	const handlePreview = (type: "preview" | "download", item: VoyageManageAttachmentItemType) => {
		getMovementFilePathAction(
			{
				path: item?.filePath
			},
			uploadVmVoyageAttachActionFront,
			async (response) => {
				setLoading(false)
				if (type === "download") {
					const lastSlashIndex = item?.fileName?.lastIndexOf("/")
					const filename = item?.fileName?.substring(lastSlashIndex + 1)
					downloadFile(response?.data, filename)
					// const link = document.createElement("a")
					// link.href = response?.data
					// link.download = response?.data || "file" // 设置下载文件名（跨域时可能不生效）
					// document.body.appendChild(link)
					// link.click()
					// document.body.removeChild(link)
				} else {
					const urlObj = new URL(response?.data)
					const pathname = urlObj.pathname.toLowerCase()
					if (pathname.endsWith(".docx")) {
						previewDocxFromUrl(response?.data)
					} else {
						window.open(response?.data)
					}
				}
			},
			uploadVmVoyageAttachActionFailed,
			dispatch,
			navigate
		)
	}

	const downloadFile = async (url, filename) => {
		try {
			// 1. 使用fetch获取文件数据
			const response = await fetch(url)
			const blob = await response.blob()

			// 2. 创建对象URL
			const objectUrl = URL.createObjectURL(blob)

			// 3. 创建隐藏的a标签
			const link = document.createElement("a")
			link.style.display = "none"
			link.href = objectUrl

			// 强制设置下载文件名（需包含正确扩展名）
			link.download =
				filename || url.split("/").pop() || `file_${Date.now()}.${blob.type.split("/")[1]}`

			document.body.appendChild(link)
			link.click()

			// 4. 清理资源
			setTimeout(() => {
				URL.revokeObjectURL(objectUrl)
				document.body.removeChild(link)
			}, 100)
		} catch (error) {
			console.error("下载失败:", error)
			// 降级方案：新窗口打开
			window.open(url, "_blank")
		}
	}

	const previewDocxFromUrl = async (url: string) => {
		setDocxPreview(true)
		setPreviewLoading(true)
		const container = previewContainerRef.current
		container.innerHTML = "" // 清空容器

		try {
			// 获取文件
			const response = await fetch(url)
			const blob = await response.blob()
			// 使用 docx-preview 渲染
			docx
				.renderAsync(blob, container)
				.then(() => {
					setPreviewLoading(false)
				})
				.catch((error) => {
					setPreviewLoading(false)
				})
		} catch (error) {
			setPreviewLoading(false)
		}
	}

	const handlePreviewClose = () => {
		setDocxPreview(false)
	}

	const handleUploadClose = () => {
		setHide(true)
	}

	const handleUploadOpen = () => {
		setHide(false)
	}

	return {
		hide,
		loading,
		previewContainerRef,
		docxPreview,
		previewLoading,
		handleUpload,
		handleAttachDelete,
		handleContactDelete,
		handlePreview,
		handleUploadClose,
		handleUploadOpen,
		handlePreviewClose
	}
}

export default useIndex
