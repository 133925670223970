import { MovementBunkerPortCostProps } from "./type"

export const getAdjustDiff = (
	record: MovementBunkerPortCostProps["items"][number],
	otherSource: MovementBunkerPortCostProps["otherSource"]
) => {
	const remaining = +record?.bodQuantity - +record?.borQuantity
	const rangeQuantity = (+otherSource?.borAbout * +record?.bodQuantity) / 100
	const adjustQty = remaining > 0 ? remaining - rangeQuantity : rangeQuantity + remaining
	return {
		remaining,
		rangeQuantity: remaining > 0 ? rangeQuantity : -rangeQuantity,
		adjustQty: adjustQty,
		adjustCost: adjustQty * +record?.adjustedPrice
	}
}

export const getOilCost = (
	record: MovementBunkerPortCostProps["items"][number],
	otherSource: MovementBunkerPortCostProps["otherSource"]
) => {
	const { adjustCost, rangeQuantity } = getAdjustDiff(record, otherSource)
	const bunkerCost = +record?.bunkerSummaryQuantity * +record?.bunkerSummaryPrice
	return bunkerCost
}
