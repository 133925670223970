import { useMemo } from "react"
import { MovementCargoActualItemType } from "../components/charterBase/components/cargo/type"
import { MovementPortActualItemType } from "../components/charterBase/components/portRotation/type"
import { MovementTcvcOtherItemType } from "../type"
import { MovementVesselBasicsType } from "../components/vesselBasics/type"
import { getPortType } from "../tools"
import { MovementFinancialDataItemType, initialFinacialItem } from "../../../common"
import { getInvPeriod } from "@/tools/inv"

const useEstimation = (
	vesselItem: MovementVesselBasicsType,
	cargoItem: MovementCargoActualItemType,
	portGroup: MovementPortActualItemType[],
	otherItem: MovementTcvcOtherItemType
) => {
	const portCalcutor = useMemo(() => {
		return portGroup?.reduce(
			(prev, curr) => {
				const portType = getPortType(curr?.purpose)
				switch (portType) {
					case "ballast":
						return {
							...prev,
							ballastPortWorkDays: prev?.ballastPortWorkDays + +curr?.workDays,
							ballastPortIdleDays: prev?.ballastPortIdleDays + +curr?.idleDays,
							ballastPortTotalDays: prev?.ballastPortTotalDays + +curr?.totalDays,
							ballastSeaDays: prev?.ballastSeaDays + +curr?.seaDay
						}
					case "laden":
						return {
							...prev,
							ladenPortWorkDays: prev?.ladenPortWorkDays + +curr?.workDays,
							ladenPortIdleDays: prev?.ladenPortIdleDays + +curr?.idleDays,
							ladenPortTotalDays: prev?.ladenPortTotalDays + +curr?.totalDays,
							ladenSeaDays: prev?.ladenSeaDays + +curr?.seaDay
						}
					default:
						return prev
				}
			},
			{
				ballastPortWorkDays: 0,
				ladenPortWorkDays: 0,
				ballastPortIdleDays: 0,
				ladenPortIdleDays: 0,
				ballastPortTotalDays: 0,
				ladenPortTotalDays: 0,
				ballastSeaDays: 0,
				ladenSeaDays: 0
			}
		)
	}, [portGroup])
	/** 运费率： 包干 / 每公吨 * 货数 */
	const originalFreightIncome = useMemo(() => {
		if (cargoItem?.freightType === "LP") {
			return +cargoItem?.freightRate
		}
		return +cargoItem?.freightRate * +cargoItem?.cargoQuantity
	}, [cargoItem?.freightType, cargoItem?.freightRate, cargoItem?.cargoQuantity])

	/** 折扣费用 = 运费 * 折扣率 */
	const freightAddCom = useMemo(() => {
		return (+cargoItem?.commission * +originalFreightIncome) / 100
	}, [cargoItem?.commission, originalFreightIncome])

	/** 中介费用 = 运费 * 中介费率 */
	const revenuesBrokerage = useMemo(() => {
		return (+cargoItem?.brokerage * +originalFreightIncome) / 100
	}, [cargoItem?.brokerage, originalFreightIncome])

	const freightIncome = useMemo(() => {
		return originalFreightIncome - freightAddCom - revenuesBrokerage
	}, [originalFreightIncome, freightAddCom, revenuesBrokerage])

	const otherCommission = useMemo(() => {
		return otherItem?.commissionList?.reduce(
			(prev, curr) => {
				prev.total += +curr?.itemValue
				prev.settleTotal += curr?.status === "Settled" ? curr?.settledAmount : 0
				prev.calcuate?.push(
					initialFinacialItem({
						desc: curr?.itemName,
						actual: +curr?.itemValue,
						status: curr?.status,
						time: curr?.status === "Settled" ? curr?.latestFinancialDate : null,
						posted: curr?.status === "Settled" ? curr?.settledAmount : 0,
						diffPosted:
							curr?.status === "Settled" && curr?.settledAmount
								? (curr?.settledAmount - +curr?.itemValue) / +curr?.itemValue
								: 0,
						payerName: curr?.status === "Settled" ? curr?.payeeName : null
					})
				)
				return prev
			},
			{
				total: 0,
				settleTotal: 0,
				calcuate: [] as MovementFinancialDataItemType[]
			}
		)
	}, [otherItem?.commissionList])

	const freightExpense = useMemo(() => {
		return otherItem?.freightList?.reduce(
			(prev, curr) => {
				prev.total += curr?.amount
				prev?.calcuate?.push(
					initialFinacialItem({
						desc: curr?.invFinal
							? `Final Freight Income`
							: !curr?.desDem
								? `${curr?.period} ${getInvPeriod(curr?.period)} Freight Income`
								: `${curr?.period} ${getInvPeriod(curr?.period)} DES/DEM`,
						actual: curr?.amount,
						posted: curr?.status === "Settled" ? curr?.settledAmount : null,
						diffPosted: curr?.amount ? (curr?.settledAmount - curr?.amount) / curr?.amount : 0
					})
				)
				return prev
			},
			{
				total: 0,
				calcuate: [] as MovementFinancialDataItemType[]
			}
		)
	}, [otherItem?.freightList])

	// const laytime = useMemo(() => {
	// 	return otherItem?.demDesList?.reduce(
	// 		(prev, curr) => {
	// 			const portType = getPortType(curr?.purpose) === "ballast" ? "LP" : "DP"
	// 			const desc = !curr?.demAmount ? `DES` : `DEM`
	// 			console.log("prev", prev, curr)
	// 			if (desc === "DES") {
	// 				prev.calcuate?.push({
	// 					desc: `${desc} ${portType}_${curr?.portName}`,
	// 					plan: null,
	// 					actual: -curr?.desAmount,
	// 					diffActual: null,
	// 					diffPosted: null,
	// 					posted: null
	// 				})
	// 				prev.desAmount += curr?.desAmount
	// 			} else {
	// 				prev.calcuate?.push({
	// 					desc: `${desc} ${portType}_${curr?.portName}`,
	// 					plan: null,
	// 					actual: curr?.demAmount,
	// 					diffActual: null,
	// 					diffPosted: null,
	// 					posted: null
	// 				})
	// 				prev.calcuate?.push({
	// 					desc: `Add.Comm. On DEM ${portType}_${curr?.portName}`,
	// 					plan: null,
	// 					actual: -(curr?.demAmount * +cargoItem?.commission) / 100,
	// 					diffActual: null,
	// 					diffPosted: null,
	// 					posted: null
	// 				})
	// 				prev.demAmout += curr?.demAmount - (curr?.demAmount * +cargoItem?.commission) / 100
	// 			}
	// 			prev.total += !curr?.demAmount
	// 				? -curr?.desAmount
	// 				: curr?.demAmount - (curr?.demAmount * +cargoItem?.commission) / 100
	// 			return prev
	// 		},
	// 		{
	// 			total: 0,
	// 			calcuate: [] as MovementFinancialDataItemType[],
	// 			desAmount: 0,
	// 			demAmout: 0
	// 		}
	// 	)
	// }, [cargoItem?.commission, otherItem?.demDesList])

	const laytime = useMemo(() => {
		return otherItem?.demDesList?.reduce(
			(prev, curr, index) => {
				const portType = getPortType(curr?.purpose) === "ballast" ? "LP" : "DP"
				const invDesc = getInvPeriod(curr?.invPeriod)
				console.log("prev", prev, curr)
				if (curr?.rateType === "DES") {
					prev.calcuate?.push({
						desc: `${curr?.invPeriod}${invDesc} ${curr?.rateType} ${portType}_${curr?.portName}`,
						plan: null,
						actual: -curr?.rate,
						diffActual: null,
						diffPosted: null,
						posted: null
					})
					prev.total -= curr?.rate
					prev.desAmount += curr?.rate
				} else {
					prev.calcuate?.push({
						desc: `${curr?.invPeriod}${invDesc} ${curr?.rateType} ${portType}_${curr?.portName}`,
						plan: null,
						actual: curr?.rate,
						diffActual: null,
						diffPosted: null,
						posted: null
					})
					prev.calcuate?.push({
						desc: `${curr?.invPeriod}${invDesc} Add on DEM ${portType}_${curr?.portName}`,
						plan: null,
						actual: -curr?.addOnType,
						diffActual: null,
						diffPosted: null,
						posted: null
					})
					prev.total += curr?.rate - curr?.addOnType
					prev.demAmount += curr?.rate - curr?.addOnType
				}
				return prev
			},
			{
				total: 0,
				calcuate: [] as MovementFinancialDataItemType[],
				desAmount: 0,
				demAmount: 0
			}
		)
	}, [otherItem?.demDesList])

	/**收入 */
	// const revenues = useMemo(() => {
	// 	return +freightIncome + +laytime?.total + +otherItem?.otherIncome
	// }, [freightIncome, laytime?.total, otherItem?.otherIncome])

	/**收入 */
	// const revenues = useMemo(() => {
	// 	return +freightIncome + +otherItem?.otherIncome
	// }, [freightIncome, otherItem?.otherIncome])

	const revenues = useMemo(() => {
		return +freightIncome + +otherItem?.otherIncome
	}, [freightIncome, otherItem?.otherIncome])

	const totalOtherExpense = useMemo(() => {
		return otherItem?.otherExpense?.reduce((prev, curr) => prev + +curr?.netExpense, 0)
	}, [otherItem?.otherExpense])

	const totalPortExpense = useMemo(() => {
		return otherItem?.portExpenseList?.reduce(
			(prev, curr) => prev + +curr?.prePortDisb + +curr?.settlement,
			0
		)
	}, [otherItem?.portExpenseList])

	const totalDisputeCharge = useMemo(() => {
		return otherItem?.disputeChargeList?.reduce((prev, curr) => prev + +curr?.finalAmount, 0)
	}, [otherItem?.disputeChargeList])

	const otherExpenses = useMemo(() => {
		const result = otherItem?.otherExpense?.reduce(
			(prev, curr) => {
				prev.total += +curr?.netExpense
				prev.ownersCount += +curr?.ownerAccount
				prev.calcuate?.push(
					initialFinacialItem({
						desc: curr?.itemName,
						actual: +curr?.netExpense,
						posted: curr?.status === "Settled" ? curr?.settledAmount : null,
						diffPosted:
							curr?.status === "Settled" && !!curr?.settledAmount
								? (curr?.settledAmount - +curr?.netExpense) / +curr?.netExpense
								: 0,
						time: curr?.status === "Settled" ? curr?.latestFinancialDate : null
					})
				)
				return prev
			},
			{
				total: 0,
				ownersCount: 0,
				calcuate: [] as MovementFinancialDataItemType[]
			}
		)
		result.calcuate?.push(
			initialFinacialItem({
				desc: "PNI",
				actual: otherItem?.insureArApDetail?.totalAmount,
				posted: otherItem?.insureArApDetail?.settledAmount,
				time:
					otherItem?.insureArApDetail?.status === "Settled"
						? otherItem?.insureArApDetail?.latestFinancialDate
						: null
			})
		)
		result.total += otherItem?.insureArApDetail?.totalAmount ?? 0
		return result
	}, [otherItem?.otherExpense, otherItem?.insureArApDetail])

	const disputeCharges = useMemo(() => {
		return otherItem?.disputeChargeList?.reduce(
			(prev, curr) => {
				prev.total += +curr?.finalAmount
				prev.calcuate?.push(
					initialFinacialItem({
						desc: curr?.itemName,
						actual: +curr?.finalAmount
					})
				)
				return prev
			},
			{
				total: 0,
				calcuate: [] as MovementFinancialDataItemType[]
			}
		)
	}, [otherItem?.disputeChargeList])

	const portExpenses = useMemo(() => {
		return otherItem?.portExpenseDetailList?.reduce(
			(prev, curr) => {
				const portType = getPortType(curr?.purpose) === "ballast" ? "L/P" : "D/P"
				const intermediateExpenses = curr?.midPriceList?.reduce(
					(prevMid, currMid) => prevMid + currMid?.amount,
					0
				)
				const intermediateFinacialItem = curr?.midPriceList?.map((midPrice, midIndex) => {
					return initialFinacialItem({
						desc: `(${portType}) Intermediate-Expense-${midIndex + 1}_${curr?.portName}`,
						actual: +midPrice?.amount,
						posted: midPrice?.settledAmount,
						portAgentName: curr?.portAgentName,
						time: midPrice?.latestFinancialDate
					})
				})
				console.log("intermediateFinacialItem", intermediateFinacialItem, curr, curr?.midPriceList)
				prev.total += +curr?.preAmount + intermediateExpenses + +curr?.settlementAmount
				prev.calcuate?.push(
					initialFinacialItem({
						desc: `(Pre-${portType}) Disb_${curr?.portName}`,
						actual: curr?.preAmount,
						posted: curr?.preSettledAmount,
						portAgentName: curr?.portAgentName,
						time: curr?.preLatestFinancialDate
					}),
					...(intermediateFinacialItem || []),
					initialFinacialItem({
						desc: `(${portType}) Settlement_${curr?.portName}`,
						actual: curr?.settlementAmount,
						posted: curr?.settlementSettledAmount,
						portAgentName: curr?.portAgentName,
						time: curr?.settlementLatestFinancialDate
					})
				)
				return prev
			},
			{
				total: 0,
				calcuate: [] as MovementFinancialDataItemType[]
			}
		)
	}, [otherItem?.portExpenseDetailList])

	console.log("portExpenses", portExpenses)

	const bunkerage = useMemo(() => {
		return otherItem?.portExpenseList?.reduce(
			(prev, curr) => {
				const portType = getPortType(curr?.purpose) === "ballast" ? "L/P" : "D/P"
				prev.total += +curr?.prePortDisb + +curr?.settlement
				prev.calcuate?.push(
					initialFinacialItem({
						desc: `(Pre-${portType}) Disb_${curr?.portName}`,
						actual: +curr?.prePortDisb
					}),
					initialFinacialItem({
						desc: `(${portType}) Settlement_${curr?.portName}`,
						actual: +curr?.settlement
					})
				)
				return prev
			},
			{
				total: 0,
				calcuate: [] as MovementFinancialDataItemType[]
			}
		)
	}, [otherItem?.portExpenseList])

	console.log("otherItem.portExpenses", portExpenses, otherItem?.portExpenseList)

	const hireExpense = useMemo(() => {
		return otherItem?.hireList?.reduce(
			(prev, curr, index, arr) => {
				prev.total += curr?.status === "Settled" ? curr?.amount : 0
				prev?.calcuate?.push(
					initialFinacialItem({
						desc: curr?.invFinal
							? `Final Hire`
							: `${curr?.period} ${getInvPeriod(curr?.period)} Hire`,
						actual: curr?.amount,
						time: curr?.status === "Settled" ? curr?.latestFinancialDate : null,
						posted: curr?.status === "Settled" ? curr?.settledAmount : null
					})
				)
				return prev
			},
			{
				total: 0,
				calcuate: [] as MovementFinancialDataItemType[]
			}
		)
	}, [otherItem?.hireList])

	const ifoCost = useMemo(() => {
		return (
			otherItem?.bunkerPlan?.hsfoTotalPrice +
			otherItem?.bunkerPlan?.lsfoTotalPrice +
			+otherItem?.bunkerPlan?.scrubberTotalPrice
		)
	}, [otherItem?.bunkerPlan])

	const mdoCost = useMemo(() => {
		return otherItem?.bunkerPlan?.mdoTotalPrice + otherItem?.bunkerPlan?.mgoTotalPrice
	}, [otherItem?.bunkerPlan])

	const totalBunkerCost = useMemo(() => {
		return ifoCost + mdoCost
	}, [ifoCost, mdoCost])

	const operationCost = useMemo(() => {
		return (
			ifoCost +
			mdoCost +
			otherExpenses?.total +
			totalPortExpense +
			otherItem?.bunkerPlan?.totalBunkerPlanExpense
		)
	}, [ifoCost, mdoCost, totalOtherExpense, otherExpenses, otherItem?.bunkerPlan])

	// const hireCost = useMemo(() => {
	// 	const totalDays =
	// 		((vesselItem?.estRedeliveryDate ?? 0) - (vesselItem?.deliveryTime ?? 0)) /
	// 		(1000 * 60 * 60 * 24)
	// 	return +vesselItem.dailyHire * totalDays
	// 	// return +vesselItem.dailyHire * (portCalcutor.ballastSeaDays + portCalcutor.ladenSeaDays + portCalcutor.ballastPortTotalDays + portCalcutor.ladenPortTotalDays)
	// }, [vesselItem.dailyHire, vesselItem?.estRedeliveryDate, vesselItem?.deliveryTime])

	const hireCost = useMemo(() => {
		return otherItem?.hireList?.reduce((prev, curr) => {
			return prev + curr?.amount
		}, 0)
		// return +vesselItem.dailyHire * (portCalcutor.ballastSeaDays + portCalcutor.ladenSeaDays + portCalcutor.ballastPortTotalDays + portCalcutor.ladenPortTotalDays)
	}, [otherItem?.hireList])

	const addCom = useMemo(() => {
		return (hireCost * +vesselItem.commission) / 100
	}, [hireCost, vesselItem.commission])

	const totalHireCostBrokerage = useMemo(() => {
		return (hireCost * +vesselItem.brokerage) / 100
	}, [hireCost, vesselItem.brokerage])

	const totalHireCost = useMemo(() => {
		return hireCost + otherCommission?.total
	}, [hireCost, otherCommission?.total])

	// const totalHireCost = useMemo(() => {
	// 	return hireCost - (addCom + totalHireCostBrokerage)
	// }, [hireCost, addCom, totalHireCostBrokerage])

	console.log("totalHireCost", {
		hireCost,
		addCom,
		totalHireCostBrokerage,
		totalHireCost,
		operationCost
	})

	// const totalCost = useMemo(() => {
	// 	return operationCost + totalHireCost - freightAddCom - revenuesBrokerage;
	// }, [operationCost, totalHireCost, freightAddCom, revenuesBrokerage]);

	const totalCost = useMemo(() => {
		return operationCost + totalHireCost
	}, [operationCost, totalHireCost])

	/** profit: 利润 = 收入 - 成本 */
	// const profit = useMemo(() => {
	// 	return revenues - totalCost - laytime?.total
	// }, [revenues, totalCost, laytime?.total])

	const profit = useMemo(() => {
		return revenues - totalCost
	}, [revenues, totalCost])

	const tce = useMemo(() => {
		const totalDays =
			portCalcutor.ballastSeaDays +
			portCalcutor.ladenSeaDays +
			portCalcutor.ballastPortTotalDays +
			portCalcutor.ladenPortTotalDays
		return totalDays ? (revenues - operationCost) / totalDays : 0
	}, [revenues, operationCost, portCalcutor])

	return {
		actualCalauate: {
			totalProfit: +profit.toFixed(3),
			tce: +tce.toFixed(3),
			totalRevenue: +revenues.toFixed(3),
			totalHireCost: +totalHireCost.toFixed(3),
			freightIncome: +freightIncome.toFixed(3),
			freightAddCommission: +freightAddCom.toFixed(3),
			freightBrokerage: +revenuesBrokerage.toFixed(3),
			operationCost: +operationCost.toFixed(3),
			ifoCost: +ifoCost.toFixed(3),
			mdoCost: +mdoCost.toFixed(3),
			portExpense: +totalPortExpense?.toFixed(3),
			otherExpense: +otherExpenses?.total?.toFixed(3),
			totalCost: +totalCost.toFixed(3),
			commission: +addCom.toFixed(3),
			bunkerCost: +totalBunkerCost.toFixed(3),
			brokerage: +totalHireCostBrokerage.toFixed(3),
			hireCost: +hireCost.toFixed(3),
			totalDisputeCharge: +totalDisputeCharge?.toFixed(3),
			totalSeaDays: portCalcutor?.ballastSeaDays + portCalcutor?.ladenSeaDays,
			desAmount: +laytime?.demAmount.toFixed(3),
			demAmount: +laytime?.desAmount.toFixed(3)
		},
		portCalcutor,
		otherExpenses,
		laytime,
		disputeCharges,
		portExpenses,
		hireExpense,
		freightExpense,
		otherCommission
	}
}

export default useEstimation
