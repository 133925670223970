import dayjs from "dayjs"
import React from "react"
import { FleetToast } from "@/common"
import { FleetDatePicker, FleetInput, FleetSelect, FleetTooltip } from "@/components"
import { PortPurposeType } from "@/types/common"
import { MovementCommonTableColumnsType } from "../../common/table/type"
import {
	MovementPortRotationProps,
	MovementPortActualItemType,
	MovementPortEstItemType
} from "./type"
import { portPurposeOptions } from "@/pages/voyagemanage/business/movement/source"
import { FleetOptionType } from "@/types/advancedType"
import { FleetCustomRadio } from "@/components/Radio"
import { calculateValues } from "@/pages/estimation/module"
import { calcSeaValues } from "@/tools/port"
import { CommonPortSelect } from "@/common"
import classnames from "classnames"

export const actualColumns: MovementCommonTableColumnsType<
	MovementPortActualItemType,
	keyof MovementPortActualItemType,
	{
		onChange: MovementPortRotationProps["onActualChange"]
		onCommit: MovementPortRotationProps["onActualCommit"]
		onDelete: MovementPortRotationProps["onActualDelete"]
		onMove: MovementPortRotationProps["onActualMove"]
	}
>[] = [
	{
		width: 48,
		label: "No.",
		dataIndex: "id",
		fixed: "left",
		render: (value, record, index) => {
			return <span>{index + 1}</span>
		}
	},
	{
		width: 160,
		fixed: "left",
		label: "Status",
		dataIndex: "purpose",
		render: (value, record, index, actions, otherSource) => {
			const purposeOptions =
				index === 0 || value === "DL" || value === "RD" || index === otherSource?.total - 1
					? portPurposeOptions
					: portPurposeOptions?.filter((purposeItem) => !["RD", "DL"]?.includes(purposeItem?.value))
			return (
				<FleetSelect
					value={[value]}
					popupMatchSelectWidth={false}
					className="port-select"
					options={purposeOptions}
					onChange={(val: PortPurposeType) => {
						actions?.onChange(index, "purpose", val)
					}}
				/>
			)
		}
	},
	{
		width: 160,
		fixed: "left",
		label: "Port Name",
		dataIndex: "portName",
		render: (value, record, index, actions) => {
			return (
				<CommonPortSelect
					className="port-select"
					showKey="portName"
					primaryKey="portCode"
					activeItems={{
						portCode: record?.portCode,
						portName: record?.portName
					}}
					onFinish={(newItem, type) => {
						actions?.onCommit?.(index, {
							portCode: newItem?.portCode ?? "",
							portName: newItem?.portName ?? ""
						})
					}}
				/>
			)
		}
	},
	{
		width: 120,
		label: "Distances",
		dataIndex: "distance",
		render: (value, record, index, actions) => {
			// if (index === 0 || record?.purpose === "DL") return <div className="portGroup-disabled"></div>
			return (
				<FleetInput
					value={value}
					type="number"
					className="port-input"
					onChange={(val: number) => {
						actions?.onChange?.(index, "distance", val)
					}}
				/>
			)
		}
	},
	{
		width: 97,
		label: "Sea Days",
		dataIndex: "seaDay",
		render: (value, record, index, actions) => {
			// if (index === 0 || record?.purpose === "DL") return <div className="portGroup-disabled"></div>
			return (
				<FleetInput
					value={value}
					type="number"
					className="port-input"
					onChange={(val: number) => {
						actions?.onChange?.(index, "seaDay", val)
					}}
				/>
			)
		}
	},
	{
		width: 98,
		label: "Port Work Day",
		dataIndex: "workDays",
		render: (value, record, index, actions) => {
			// if (index === 0 || record?.purpose === "DL") return <div className="portGroup-disabled"></div>
			return (
				<FleetInput
					value={value}
					type="number"
					className="port-input"
					onChange={(val: number) => {
						const totalDays = +record?.idleDays + +val
						actions?.onCommit?.(index, {
							workDays: val,
							totalDays: totalDays
						})
					}}
				/>
			)
		}
	},
	{
		width: 96,
		label: "Port Idle Day",
		dataIndex: "idleDays",
		render: (value, record, index, actions) => {
			// if (index === 0 || record?.purpose === "DL") return <div className="portGroup-disabled"></div>
			return (
				<FleetInput
					value={value}
					type="number"
					className="port-input"
					onChange={(val: number) => {
						const totalDays = +record?.workDays + +val
						actions?.onCommit?.(index, {
							idleDays: val,
							totalDays: totalDays
						})
					}}
				/>
			)
		}
	},
	{
		width: 95,
		label: "TTL Port Day",
		dataIndex: "totalDays",
		render: (value, record, index, actions) => {
			// if (index === 0 || record?.purpose === "DL") return <div className="portGroup-disabled"></div>
			return <span>{+record?.workDays + +record?.idleDays}</span>
		}
	},
	{
		width: 95,
		label: "Port Expenses",
		dataIndex: "portExpense",
		render: (value, record, index, actions) => {
			// if (index === 0 || record?.purpose === "DL") return <div className="portGroup-disabled"></div>
			return (
				<FleetInput
					value={value}
					type="number"
					className="port-input"
					onChange={(val: number) => {
						actions?.onChange?.(index, "portExpense", val)
					}}
				/>
			)
		}
	},
	{
		width: 192,
		label: "ETA",
		dataIndex: "eta",
		render: (value, record, index, actions) => {
			// if (index === 0 || record?.purpose === "DL") return <div className="portGroup-disabled"></div>
			return (
				<FleetDatePicker
					value={value ? dayjs(value) : null}
					className="date-picker"
					// disabled={index === 0 || value === "DL"}
					showTime={{
						format: "HH:mm"
					}}
					format={(value) => value?.format("YYYY-MM-DD HH:mm")}
					showHour
					showMinute
					showSecond={false}
					onChange={(val, timeStramp) => {
						const currentTime = timeStramp?.startOf?.("minute")?.valueOf?.()
						actions?.onChange?.(index, "eta", currentTime)
					}}
				/>
			)
		}
	},
	{
		width: 192,
		label: "ETD",
		dataIndex: "etd",
		render: (value, record, index, actions) => {
			// if (record?.purpose === "RD") return <div className="portGroup-disabled"></div>
			// if (index === 0 || value === "DL") return null
			return (
				<FleetDatePicker
					value={value ? dayjs(value) : null}
					className="date-picker"
					showTime={{
						format: "HH:mm"
					}}
					format={(value) => value?.format("YYYY-MM-DD HH:mm")}
					showHour
					showMinute
					showSecond={false}
					onChange={(val, timeStramp) => {
						const currentTime = timeStramp?.startOf?.("minute")?.valueOf?.()
						actions?.onChange?.(index, "etd", currentTime)
					}}
				/>
			)
		}
	},
	{
		width: 192,
		label: "ATA",
		dataIndex: "ata",
		render: (value, record, index, actions) => {
			// if (index === 0 || record?.purpose === "DL") return <div className="portGroup-disabled"></div>
			return (
				<FleetDatePicker
					value={value ? dayjs(value) : null}
					className="date-picker"
					// disabled={index === 0 || value === "DL"}
					showTime={{
						format: "HH:mm"
					}}
					format={(value) => value?.format("YYYY-MM-DD HH:mm")}
					showHour
					showMinute
					showSecond={false}
					onChange={(val, timeStramp) => {
						const currentTime = timeStramp?.startOf?.("minute")?.valueOf?.()
						actions?.onChange?.(index, "ata", currentTime)
					}}
				/>
			)
		}
	},
	{
		width: 192,
		label: "ATD",
		dataIndex: "atd",
		render: (value, record, index, actions) => {
			// if (record?.purpose === "RD") return <div className="portGroup-disabled"></div>
			// if (index === 0 || value === "DL") return null
			return (
				<FleetDatePicker
					value={value ? dayjs(value) : null}
					className="date-picker"
					showTime={{
						format: "HH:mm"
					}}
					format={(value) => value?.format("YYYY-MM-DD HH:mm")}
					showHour
					showMinute
					showSecond={false}
					onChange={(val, timeStramp) => {
						const currentTime = timeStramp?.startOf?.("minute")?.valueOf?.()
						actions?.onChange?.(index, "atd", currentTime)
					}}
				/>
			)
		}
	},
	{
		width: 118,
		label: "Sea HSFO Con.",
		dataIndex: "seaFuelHsFo",
		render: (value, record, index, actions, preRecord) => {
			// if (index === 0 || record?.purpose === "DL") return <div className="portGroup-disabled"></div>
			return (
				<FleetInput
					value={value}
					type="number"
					className="port-input"
					onChange={(val: number) => {
						actions?.onChange(index, "seaFuelHsFo", val)
					}}
				/>
			)
		}
	},
	{
		width: 118,
		label: "Sea LSFO Con.",
		dataIndex: "seaFuelLsFo",
		render: (value, record, index, actions, preRecord) => {
			// if (index === 0 || record?.purpose === "DL") return <div className="portGroup-disabled"></div>
			return (
				<FleetInput
					value={value}
					type="number"
					className="port-input"
					onChange={(val: number) => {
						actions?.onChange(index, "seaFuelLsFo", val)
					}}
				/>
			)
		}
	},
	{
		width: 118,
		label: "Sea MDO Con.",
		dataIndex: "seaFuelMdo",
		render: (value, record, index, actions, preRecord) => {
			// if (index === 0 || record?.purpose === "DL") return <div className="portGroup-disabled"></div>
			return (
				<FleetInput
					value={value}
					type="number"
					className="port-input"
					onChange={(val: number) => {
						actions?.onChange(index, "seaFuelMdo", val)
					}}
				/>
			)
		}
	},
	{
		width: 118,
		label: "Sea MGO Con.",
		dataIndex: "seaFuelMgo",
		render: (value, record, index, actions, preRecord) => {
			// if (index === 0 || record?.purpose === "DL") return <div className="portGroup-disabled"></div>
			return (
				<FleetInput
					value={value}
					type="number"
					className="port-input"
					onChange={(val: number) => {
						actions?.onChange(index, "seaFuelMgo", val)
					}}
				/>
			)
		}
	},
	{
		width: 118,
		label: "Port HSFO Con.",
		dataIndex: "portHsFo",
		render: (value, record, index, actions, preRecord) => {
			// if (index === 0 || record?.purpose === "DL") return <div className="portGroup-disabled"></div>
			return (
				<FleetInput
					value={value}
					type="number"
					className="port-input"
					onChange={(val: number) => {
						actions?.onChange(index, "portHsFo", val)
					}}
				/>
			)
		}
	},
	{
		width: 118,
		label: "Port LSFO Con.",
		dataIndex: "portLsFo",
		render: (value, record, index, actions, preRecord) => {
			// if (index === 0 || record?.purpose === "DL") return <div className="portGroup-disabled"></div>
			return (
				<FleetInput
					value={value}
					type="number"
					className="port-input"
					onChange={(val: number) => {
						actions?.onChange(index, "portLsFo", val)
					}}
				/>
			)
		}
	},
	{
		width: 118,
		label: "Port MDO Con.",
		dataIndex: "portMdo",
		render: (value, record, index, actions, preRecord) => {
			// if (index === 0 || record?.purpose === "DL") return <div className="portGroup-disabled"></div>
			return (
				<FleetInput
					value={value}
					type="number"
					className="port-input"
					onChange={(val: number) => {
						actions?.onChange(index, "portMdo", val)
					}}
				/>
			)
		}
	},
	{
		width: 118,
		label: "Port MGO Con.",
		dataIndex: "portMgo",
		render: (value, record, index, actions, preRecord) => {
			// if (index === 0 || record?.purpose === "DL") return <div className="portGroup-disabled"></div>
			return (
				<FleetInput
					value={value}
					type="number"
					className="port-input"
					onChange={(val: number) => {
						actions?.onChange(index, "portMgo", val)
					}}
				/>
			)
		}
	},
	{
		width: 118,
		label: "HSFO BROB",
		dataIndex: "hsfoBrob",
		render: (value, record, index, actions, preRecord) => {
			// if (index === 0 || value === "DL") return <span>{value}</span>
			return (
				<FleetInput
					value={value}
					type="number"
					className="port-input"
					onChange={(val: number) => {
						actions?.onChange(index, "hsfoBrob", val)
					}}
				/>
			)
		}
	},
	{
		width: 118,
		label: "LSFO BROB",
		dataIndex: "lsfoBrob",
		render: (value, record, index, actions, preRecord) => {
			// if (index === 0 || value === "DL") return <span>{value}</span>
			return (
				<FleetInput
					value={value}
					type="number"
					className="port-input"
					onChange={(val: number) => {
						actions?.onChange(index, "lsfoBrob", val)
					}}
				/>
			)
		}
	},
	{
		width: 118,
		label: "MDO BROB",
		dataIndex: "mdoBrob",
		render: (value, record, index, actions, preRecord) => {
			// if (index === 0 || value === "DL") return <span>{value}</span>
			return (
				<FleetInput
					value={value}
					type="number"
					className="port-input"
					onChange={(val: number) => {
						actions?.onChange(index, "mdoBrob", val)
					}}
				/>
			)
		}
	},
	{
		width: 118,
		label: "MGO BROB",
		dataIndex: "mgoBrob",
		render: (value, record, index, actions, preRecord) => {
			// if (index === 0 || value === "DL") return <span>{value}</span>
			return (
				<FleetInput
					value={value}
					type="number"
					className="port-input"
					onChange={(val: number) => {
						actions?.onChange(index, "mgoBrob", val)
					}}
				/>
			)
		}
	},
	{
		width: 65,
		label: "Action",
		dataIndex: "action",
		fixed: "right",
		render: (value, record, index, actions, otherSource) => {
			return (
				<div
					className={classnames("port-actions", {
						move: !["DL", "RD"]?.includes(record?.purpose)
					})}
				>
					<FleetTooltip
						outerLayer={<span className={classnames("font_family fleet-delete")}></span>}
						// disabled={record?.purpose === "DL"}
						render={(setIsOpen) => {
							return (
								<FleetToast
									label="That file will be deleted. Are you sure?"
									onCancel={() => {
										setIsOpen(false)
									}}
									onConfirm={() => {
										setIsOpen(false)
										actions?.onDelete?.(index)
									}}
								/>
							)
						}}
					/>
					{!["DL", "RD"]?.includes(record?.purpose) && (
						<div className="port-moveActions">
							<span
								className={classnames("font_family fleet-back", {
									"fleet-disabled": index === 1
								})}
								onClick={() => {
									actions?.onMove?.("up", index)
								}}
							></span>
							<span
								className={classnames("font_family fleet-back", {
									"fleet-disabled": index === otherSource?.total - 1
								})}
								onClick={() => {
									actions?.onMove?.("down", index)
								}}
							></span>
						</div>
					)}
				</div>
			)
		}
	}
]

export const estColumns: MovementCommonTableColumnsType<
	MovementPortEstItemType,
	keyof MovementPortEstItemType,
	{
		onChange: MovementPortRotationProps["onEstChange"]
		onCommit: MovementPortRotationProps["onEstCommit"]
		onPortCommit: MovementPortRotationProps["onEstCommit"]
		onDelete: MovementPortRotationProps["onEstDelete"]
	}
>[] = [
	{
		width: 48,
		label: "No.",
		dataIndex: "id",
		fixed: "left",
		render: (value, record, index) => {
			return <span>{index + 1}</span>
		}
	},
	{
		width: 160,
		fixed: "left",
		label: "Status",
		dataIndex: "purpose",
		render: (value, record, index, actions) => {
			return (
				<FleetSelect
					value={[value]}
					popupMatchSelectWidth={false}
					className="port-select"
					options={portPurposeOptions}
					onChange={(val: PortPurposeType) => {
						if (index !== 0 && val === "DL") {
							return
						}
						actions?.onChange(index, "purpose", val)
					}}
				/>
			)
		}
	},
	{
		width: 160,
		fixed: "left",
		label: "Port Name",
		dataIndex: "portName",
		render: (value, record, index, actions) => {
			return (
				<CommonPortSelect
					className="port-select"
					showKey="portName"
					primaryKey="portCode"
					activeItems={{
						portCode: record?.portCode,
						portName: record?.portName
					}}
					onFinish={(newItem, type) => {
						debugger
						actions?.onPortCommit?.(index, {
							portCode: newItem?.portCode ?? "",
							portName: newItem?.portName ?? ""
						})
					}}
				/>
			)
		}
	},
	{
		width: 120,
		label: "Distances",
		dataIndex: "distance",
		render: (value, record, index, actions, otherSource) => {
			// if (record?.purpose === "DL") return <div className="portGroup-disabled"></div>
			return (
				<FleetInput
					value={value}
					type="number"
					className="port-input"
					onChange={(val: number) => {
						// actions?.onChange?.(index, "distance", val);
						const params = calcSeaValues(record, otherSource?.vesselSource, "distance", val)
						actions?.onCommit?.(index, params)
					}}
				/>
			)
		}
	},
	{
		width: 97,
		label: "Inside.ECA",
		dataIndex: "ecaDistance",
		render: (value, record, index, actions, otherSource) => {
			// if (record?.purpose === "DL") return <div className="portGroup-disabled"></div>
			return (
				<FleetInput
					value={value}
					type="number"
					className="port-input"
					onChange={(val: number) => {
						// actions?.onChange?.(index, "ecaDistance", val);
						const params = calcSeaValues(record, otherSource?.vesselSource, "ecaDistance", val)
						actions?.onCommit?.(index, params)
					}}
				/>
			)
		}
	},
	{
		width: 97,
		label: "Sea Days",
		dataIndex: "seaDays",
		render: (value, record, index, actions, otherSource) => {
			// if (record?.purpose === "DL") return <div className="portGroup-disabled"></div>
			return (
				<FleetInput
					value={value}
					type="number"
					className="port-input"
					onChange={(val: number) => {
						// actions?.onChange?.(index, "seaDays", val);
						const params = calcSeaValues(record, otherSource?.vesselSource, "seaDays", val)
						actions?.onCommit?.(index, params)
					}}
				/>
			)
		}
	},
	{
		width: 97,
		label: "Inside ECA Day",
		dataIndex: "insideEcaDays",
		render: (value, record, index, actions, otherSource) => {
			// if (record?.purpose === "DL") return <div className="portGroup-disabled"></div>
			return (
				<FleetInput
					value={value}
					type="number"
					className="port-input"
					onChange={(val: number) => {
						// actions?.onChange?.(index, "insideEcaDays", val);
						const params = calcSeaValues(record, otherSource?.vesselSource, "insideEcaDays", val)
						actions?.onCommit?.(index, params)
					}}
				/>
			)
		}
	},
	{
		width: 97,
		label: "Cargo Quantity",
		dataIndex: "cargoQuantity",
		render: (value, record, index, actions, otherSource) => {
			// if (record?.purpose === "DL") return <div className="portGroup-disabled"></div>
			return (
				<FleetInput
					value={value}
					type="number"
					className="port-input"
					onChange={(val: number) => {
						const params = calculateValues(record, otherSource?.vesselSource, "cargoQuantity", val)
						actions?.onCommit?.(index, params)
					}}
				/>
			)
		}
	},
	{
		width: 97,
		label: "Load/Discharge Rate",
		dataIndex: "loadingDischargeRate",
		render: (value, record, index, actions, otherSource) => {
			// if (record?.purpose === "DL") return <div className="portGroup-disabled"></div>
			return (
				<FleetInput
					value={value}
					type="number"
					className="port-input"
					onChange={(val: number) => {
						const params = calculateValues(
							record,
							otherSource?.vesselSource,
							"loadingDischargeRate",
							val
						)
						actions?.onCommit?.(index, params)
					}}
				/>
			)
		}
	},
	{
		width: 98,
		label: "Port Work Day",
		dataIndex: "workDays",
		render: (value, record, index, actions, otherSource) => {
			// if (record?.purpose === "DL") return <div className="portGroup-disabled"></div>
			return (
				<FleetInput
					value={value}
					type="number"
					className="port-input"
					onChange={(val: number) => {
						const params = calculateValues(record, otherSource?.vesselSource, "workDays", val)
						actions?.onCommit?.(index, params)
					}}
				/>
			)
		}
	},
	{
		width: 96,
		label: "Port Idle Day",
		dataIndex: "idleDays",
		render: (value, record, index, actions, otherSource) => {
			// if (record?.purpose === "DL") return <div className="portGroup-disabled"></div>
			return (
				<FleetInput
					value={value}
					type="number"
					className="port-input"
					onChange={(val: number) => {
						const params = calculateValues(record, otherSource?.vesselSource, "idleDays", val)
						actions?.onCommit?.(index, params)
					}}
				/>
			)
		}
	},
	{
		width: 96,
		label: "Port Magrin",
		dataIndex: "portMarginDay",
		render: (value, record, index, actions, otherSource) => {
			// if (record?.purpose === "DL") return <div className="portGroup-disabled"></div>
			return (
				<FleetInput
					value={value}
					type="number"
					className="port-input"
					onChange={(val: number) => {
						const params = calculateValues(record, otherSource?.vesselSource, "portMarginDay", val)
						actions?.onCommit?.(index, params)
					}}
				/>
			)
		}
	},
	{
		width: 96,
		label: "Port Magrin Unit",
		dataIndex: "portMarginUnit",
		render: (value, record, index, actions, otherSource) => {
			// if (record?.purpose === "DL") return <div className="portGroup-disabled"></div>
			return (
				<FleetSelect
					value={[value]}
					className="port-select"
					options={portMarginUnitOptions}
					onChange={(val: MovementPortEstItemType["portMarginUnit"]) => {
						const params = calculateValues(record, otherSource?.vesselSource, "portMarginUnit", val)
						actions?.onCommit?.(index, params)
					}}
				/>
			)
		}
	},
	{
		width: 95,
		label: "TTL Port Day",
		dataIndex: "totalDays",
		render: (value, record, index, actions) => {
			// if (record?.purpose === "DL") return <div className="portGroup-disabled"></div>
			return <span>{+record?.totalDays}</span>
		}
	},
	{
		width: 96,
		label: "Crane？",
		dataIndex: "crane",
		render: (value, record, index, actions, otherSource) => {
			// if (record?.purpose === "DL") return <div className="portGroup-disabled"></div>
			return (
				<FleetCustomRadio
					value={!!record?.["crane"]}
					onChange={(val) => {
						const params = calculateValues(record, otherSource?.vesselSource, "crane", val ? 1 : 0)
						actions?.onCommit?.(index, params)
					}}
				/>
			)
		}
	},
	{
		width: 95,
		label: "Port Expenses",
		dataIndex: "portExpense",
		render: (value, record, index, actions) => {
			// if (record?.purpose === "DL") return <div className="portGroup-disabled"></div>
			return (
				<FleetInput
					value={value}
					type="number"
					className="port-input"
					onChange={(val: number) => {
						actions?.onChange?.(index, "portExpense", val)
					}}
				/>
			)
		}
	},
	{
		width: 192,
		label: "ETA",
		dataIndex: "eta",
		render: (value, record, index, actions) => {
			// if (index === 0 || record?.purpose === "DL") return <div className="portGroup-disabled"></div>
			return (
				<FleetDatePicker
					value={value ? dayjs(value) : null}
					className="date-picker"
					showTime
					showHour
					format={(value) => value?.format("YYYY-MM-DD HH:mm")}
					showMinute
					showSecond={false}
					onChange={(val, timeStramp) => {
						const currentTime = timeStramp?.startOf?.("minute")?.valueOf?.()
						actions?.onChange?.(index, "eta", currentTime)
					}}
				/>
			)
		}
	},
	{
		width: 192,
		label: "ETD",
		dataIndex: "etd",
		render: (value, record, index, actions) => {
			return (
				<FleetDatePicker
					value={value ? dayjs(value) : null}
					className="date-picker"
					showTime
					showHour
					format={(value) => value?.format("YYYY-MM-DD HH:mm")}
					showMinute
					showSecond={false}
					onChange={(val, timeStramp) => {
						const currentTime = timeStramp?.startOf?.("minute")?.valueOf?.()
						actions?.onChange?.(index, "etd", currentTime)
					}}
				/>
			)
		}
	},
	{
		width: 178,
		label: "Sea FO",
		dataIndex: "seaFo",
		render: (value, record, index, actions) => {
			// if (record?.purpose === "DL") return <div className="portGroup-disabled"></div>
			return (
				<FleetInput
					value={value}
					type="number"
					className="port-input"
					onChange={(val: number) => {
						actions?.onChange(index, "seaFo", val)
					}}
				/>
			)
		}
	},
	{
		width: 118,
		label: "Sea MDO",
		dataIndex: "seaMdo",
		render: (value, record, index, actions) => {
			// if (record?.purpose === "DL") return <div className="portGroup-disabled"></div>
			return (
				<FleetInput
					value={value}
					type="number"
					className="port-input"
					onChange={(val: number) => {
						actions?.onChange(index, "seaMdo", val)
					}}
				/>
			)
		}
	},
	{
		width: 118,
		label: "Port FO",
		dataIndex: "conFo",
		render: (value, record, index, actions) => {
			// if (record?.purpose === "DL") return <div className="portGroup-disabled"></div>
			return (
				<FleetInput
					// value={value}
					value={record["conFo"]}
					type="number"
					className="port-input"
					onChange={(val: number) => {
						actions?.onChange(index, "conFo", val)
					}}
				/>
			)
		}
	},
	{
		width: 118,
		label: "Port MDO",
		dataIndex: "conDo",
		render: (value, record, index, actions) => {
			// if (record?.purpose === "DL") return <div className="portGroup-disabled"></div>
			return (
				<FleetInput
					value={record["conDo"]}
					type="number"
					className="port-input"
					onChange={(val: number) => {
						actions?.onChange(index, "conDo", val)
					}}
				/>
			)
		}
	},
	{
		width: 65,
		label: "Action",
		dataIndex: "action",
		fixed: "right",
		render: (value, record, index, actions) => {
			return (
				<div className="port-actions">
					<FleetTooltip
						outerLayer={<span className={classnames("font_family fleet-delete")}></span>}
						render={(setIsOpen) => {
							return (
								<FleetToast
									label="That file will be deleted. Are you sure?"
									onCancel={() => {
										setIsOpen(false)
									}}
									onConfirm={() => {
										setIsOpen(false)
										actions?.onDelete?.(index)
									}}
								/>
							)
						}}
					/>
				</div>
			)
		}
	}
]

export const portMarginUnitOptions: FleetOptionType<
	MovementPortEstItemType["portMarginUnit"],
	MovementPortEstItemType["portMarginUnit"]
>[] = [
	{
		label: "%",
		value: "%"
	},
	{
		label: "Day",
		value: "Day"
	}
]

export const initialActualItem: MovementPortActualItemType = {
	purpose: "DC",
	portCode: "",
	portName: "",
	distance: "",
	seaDay: "",
	workDays: "",
	idleDays: "",
	totalDays: "",
	portExpense: "",
	ata: "",
	atd: "",
	eta: "",
	etd: "",
	seaFuelHsFo: "",
	seaFuelLsFo: "",
	seaFuelMdo: "",
	seaFuelMgo: "",
	portHsFo: "",
	portLsFo: "",
	portMdo: "",
	portMgo: "",
	hsfoBrob: "",
	lsfoBrob: "",
	mdoBrob: "",
	mgoBrob: "",
	status: "Unsettled",
	settledTime: null
}

export const initialEstItem: MovementPortEstItemType = {
	purpose: "DC",
	portName: "",
	portCode: "",
	distance: "",
	ecaDistance: "",
	seaDays: "",
	insideEcaDays: "",
	cargoQuantity: "",
	workDays: "",
	idleDays: "",
	portMarginDay: "",
	portMarginUnit: "%",
	totalDays: "",
	crane: 0,
	portExpense: "",
	eta: "",
	etd: "",
	seaFo: "",
	seaMdo: "",
	conDo: "",
	conFo: "",
	cargoName: "",
	cargoUnit: "MT",
	loadingDischargeRate: ""
}
