import { CommonContactItemType } from "@/common/Select"
import { FleetOptionType } from "@/types/advancedType"
import { CommonInitItemEvent } from "@/types/event"

export const initialContactItem: CommonInitItemEvent<CommonContactItemType> = (item) => {
	return {
		id: item?.id ?? null,
		companyName: item?.companyName ?? "",
		contactsId: item?.contactsId ?? null,
		contactsType: item?.contactsType ?? null,
		email: item?.email ?? "",
		faxNumber: item?.faxNumber ?? "",
		landline1: item?.landline1 ?? "",
		landline2: item?.landline2 ?? "",
		mobilePhone1: item?.mobilePhone1 ?? "",
		mobilePhone2: item?.mobilePhone2 ?? "",
		name: item?.name ?? "",
		remark: item?.remark ?? "",
		businessType: item?.businessType ?? null,
		voyageId: item?.voyageId ?? null
	}
}

export const checkKeys: (keyof CommonContactItemType)[] = ["companyName", "name"]

export const businessOption: FleetOptionType<
	CommonContactItemType["businessType"],
	CommonContactItemType["businessType"]
>[] = [
	{
		label: "Owner",
		value: "Owner"
	},
	{
		label: "Broker",
		value: "Broker"
	},
	{
		label: "Charterer",
		value: "Charterer"
	},
	{
		label: "Port Agent",
		value: "Port Agent"
	},
	{
		label: "Shipper",
		value: "Shipper"
	},
	{
		label: "Physical Supplier",
		value: "Physical Supplier"
	},
	{
		label: "Others",
		value: "Others"
	}
]
