import { useMemo } from "react"
import { MovementCargoActualItemType } from "../components/charterBase/components/cargo/type"
import { MovementVesselBasicsType } from "../components/vesselBasics/type"
import { MovementTcvcOtherItemType } from "../type"
import useEstimation from "./useEstimation"
import { downloadMovementPnlParams } from "@/api/voyageManage/movement"
import { MovementPortActualItemType } from "../components"
import { MovementFinancialDataItemType, initialFinacialItem } from "../../../common"

const usePnl = <T>(
	estCalculate: any,
	vesselItem: MovementVesselBasicsType,
	cargoItem: MovementCargoActualItemType,
	portGroup: MovementPortActualItemType[],
	otherItem: MovementTcvcOtherItemType
) => {
	const {
		actualCalauate,
		laytime,
		otherExpenses,
		disputeCharges,
		portExpenses,
		hireExpense,
		freightExpense,
		portCalcutor,
		otherCommission
	} = useEstimation(vesselItem, cargoItem, portGroup, otherItem)

	const totalSummary = useMemo(() => {
		const revenues =
			(otherItem?.ar?.otherIncome ?? 0) +
			(otherItem?.ar?.invFreight ?? 0) +
			(actualCalauate?.freightIncome + laytime?.total - freightExpense?.total)
		const totalCost =
			(otherItem?.ap?.invHire ?? 0) +
			(otherItem?.bunkerPlan?.bunkerPlanSettled?.settledAmount ?? 0) +
			// (otherItem?.bunkerPlan?.bunkerPlanExpenseSettled?.settledAmount ?? 0) +
			(otherItem?.ap?.portRotation ?? 0) +
			(otherItem?.ap?.otherExpenseItem ?? 0) +
			(otherItem?.ap?.moveCommission ?? 0) +
			(otherItem?.insureArApDetail?.settledAmount ?? 0)
		return {
			revenues,
			totalCost
		}
	}, [otherItem, actualCalauate?.freightIncome, laytime?.total, freightExpense?.total])

	const totalProfitAndLoss = useMemo<MovementFinancialDataItemType>(() => {
		return initialFinacialItem({
			desc: "Profit & Loss",
			actual: actualCalauate?.totalProfit,
			posted: totalSummary.revenues - totalSummary.totalCost
		})
	}, [actualCalauate?.totalProfit, totalSummary])

	const totalRevenues = useMemo<MovementFinancialDataItemType>(() => {
		const balanceOfFreightAmount = actualCalauate?.freightIncome - freightExpense?.total
		return initialFinacialItem({
			desc: "Total Revenues",
			plan: estCalculate?.totalRevenue,
			actual: actualCalauate?.totalRevenue,
			diffActual: estCalculate?.totalRevenue
				? (actualCalauate?.totalRevenue - estCalculate?.totalRevenue) / estCalculate?.totalRevenue
				: 0,
			posted: totalSummary?.revenues,
			diffPosted: actualCalauate?.totalRevenue
				? (totalSummary?.revenues - actualCalauate?.totalRevenue) / actualCalauate?.totalRevenue
				: 0,

			children: [
				initialFinacialItem({
					desc: "Freight Income",
					actual: actualCalauate?.freightIncome,
					plan: estCalculate?.freightIncome,
					diffActual: estCalculate?.freightIncome
						? (actualCalauate?.freightIncome - estCalculate?.freightIncome) /
							estCalculate?.freightIncome
						: 0,
					posted: otherItem?.ar?.invFreight,
					diffPosted: actualCalauate?.freightIncome
						? ((otherItem?.ar?.invFreight ?? 0) - actualCalauate?.freightIncome) /
							actualCalauate?.freightIncome
						: 0,
					children: freightExpense?.calcuate
				}),
				// initialFinacialItem({
				// 	desc: "Balance of Freight",
				// 	// actual: actualCalauate?.freightIncome - (otherItem?.ar?.invFreight ?? 0)
				// 	actual: balanceOfFreightAmount
				// }),
				...laytime?.calcuate,
				initialFinacialItem({
					desc: "Others Income",
					actual: +otherItem?.otherIncome,
					posted: otherItem?.ar?.otherIncome,
					diffPosted: +otherItem?.otherIncome
						? ((otherItem?.ar?.otherIncome ?? 0) - +otherItem?.otherIncome) /
							+otherItem?.otherIncome
						: 0
				})
			]
		})
	}, [
		actualCalauate?.totalRevenue,
		otherItem?.ar,
		laytime?.total,
		otherItem?.otherIncome,
		estCalculate?.totalRevenue,
		estCalculate?.freightIncome,
		freightExpense,
		totalSummary
	])

	const totalOtherExpenses = useMemo(() => {
		const settledAmount = otherItem?.ap?.otherExpenseItem ?? 0
		const totalOtherExpense = settledAmount + (otherItem?.insureArApDetail?.settledAmount ?? 0)
		return initialFinacialItem({
			desc: "Other Expenses",
			plan: estCalculate?.otherExpense,
			actual: otherExpenses?.total,
			posted: settledAmount + (otherItem?.insureArApDetail?.settledAmount ?? 0),
			diffPosted: otherExpenses?.total
				? (totalOtherExpense - otherExpenses?.total) / otherExpenses?.total
				: 0,
			children: otherExpenses?.calcuate
		})
	}, [
		actualCalauate?.otherExpense,
		estCalculate?.otherExpense,
		otherExpenses,
		otherItem?.ap,
		otherItem?.insureArApDetail?.settledAmount
	])

	const totalPortExpense = useMemo(() => {
		const diffVal = actualCalauate?.portExpense - estCalculate?.portExpense
		const settledAmount = otherItem?.ap?.portRotation ?? 0
		return initialFinacialItem({
			desc: "Port Disbursement",
			plan: estCalculate?.portExpense,
			actual: portExpenses?.total,
			posted: settledAmount - (otherItem?.bunkerPlan?.bunkerPlanExpenseSettled?.settledAmount ?? 0),
			diffPosted: portExpenses?.total
				? (settledAmount - portExpenses?.total) / portExpenses?.total
				: 0,
			diffActual: actualCalauate?.portExpense ? diffVal / actualCalauate?.portExpense : 0,
			children: portExpenses?.calcuate
		})
	}, [
		otherItem?.bunkerPlan?.bunkerPlanExpenseSettled,
		actualCalauate?.portExpense,
		portExpenses?.total,
		portExpenses?.calcuate,
		estCalculate?.portExpense,
		otherItem?.ap
	])

	const totalHireExpense = useMemo(() => {
		const actualHireCost = actualCalauate?.hireCost
		// const actualHireCost = actualCalauate?.hireCost - +otherExpenses?.ownersCount
		const diffVal = actualHireCost - estCalculate?.hireCost
		return initialFinacialItem({
			desc: "Hire Cost",
			plan: estCalculate?.hireCost,
			actual: actualHireCost,
			diffActual: actualHireCost ? diffVal / actualHireCost : 0,
			posted: otherItem?.ap?.invHire,
			diffPosted: actualCalauate?.hireCost
				? ((otherItem?.ap?.invHire ?? 0) - actualCalauate?.hireCost) / actualCalauate?.hireCost
				: 0,
			children: [
				...hireExpense?.calcuate
				// initialFinacialItem({
				// 	desc: "Owr's Account",
				// 	actual: otherExpenses?.ownersCount
				// })
			]
		})
	}, [
		actualCalauate?.hireCost,
		estCalculate?.hireCost,
		estCalculate?.portExpense,
		otherExpenses?.ownersCount,
		hireExpense,
		otherItem?.ap
	])

	const totalCommission = useMemo(() => {
		const settledAmount = otherItem?.ap?.moveCommission ?? 0
		return initialFinacialItem({
			desc: "Commission",
			actual: otherCommission?.total,
			posted: settledAmount,
			diffPosted: otherCommission?.total
				? (settledAmount - otherCommission?.total) / otherCommission?.total
				: 0,
			// actualCalauate?.commission +
			// actualCalauate?.brokerage -
			// actualCalauate?.freightAddCommission -
			// actualCalauate?.freightBrokerage,

			// actualCalauate?.commission - actualCalauate?.freightAddCommission,
			children: [
				// initialFinacialItem({
				// 	desc: "Chrt's Brokerage",
				// 	actual: actualCalauate?.freightBrokerage,
				// 	plan: estCalculate?.freightBrokerage,
				// 	diffActual: actualCalauate?.freightBrokerage
				// 		? (actualCalauate?.freightBrokerage - estCalculate?.freightBrokerage) /
				// 			actualCalauate?.freightBrokerage
				// 		: 0
				// }),
				// initialFinacialItem({
				// 	desc: "Chit's Addcom.",
				// 	actual: actualCalauate?.freightAddCommission,
				// 	plan: estCalculate?.freightAddCommission,
				// 	diffActual: actualCalauate?.freightAddCommission
				// 		? (actualCalauate?.freightAddCommission - estCalculate?.freightAddCommission) /
				// 			actualCalauate?.freightAddCommission
				// 		: 0
				// }),
				// initialFinacialItem({
				// 	desc: "Owr's Brokerage",
				// 	actual: actualCalauate?.brokerage,
				// 	plan: estCalculate?.brokerage,
				// 	diffActual: actualCalauate?.brokerage
				// 		? (actualCalauate?.brokerage - estCalculate?.brokerage) / actualCalauate?.brokerage
				// 		: 0
				// }),
				// initialFinacialItem({
				// 	desc: "Owr's Addcom.",
				// 	actual: actualCalauate?.commission,
				// 	plan: estCalculate?.commission,
				// 	diffActual: actualCalauate?.commission
				// 		? (actualCalauate?.commission - estCalculate?.commission) / actualCalauate?.commission
				// 		: 0
				// }),
				...otherCommission?.calcuate
			]
		})
	}, [
		// actualCalauate?.brokerage,
		// actualCalauate?.commission,
		// actualCalauate?.freightAddCommission,
		// actualCalauate?.freightBrokerage,
		// estCalculate?.brokerage,
		// estCalculate?.commission,
		// estCalculate?.freightAddCommission,
		otherCommission,
		otherItem?.ap?.moveCommission
		// estCalculate?.freightBrokerage
	])

	const totalBunkerCost = useMemo(() => {
		return (
			otherItem?.bunkerPlan?.hsfoTotalPrice +
			otherItem?.bunkerPlan?.lsfoTotalPrice +
			otherItem?.bunkerPlan?.mdoTotalPrice +
			otherItem?.bunkerPlan?.mgoTotalPrice +
			+otherItem?.bunkerPlan?.scrubberTotalPrice
		)
	}, [otherItem?.bunkerPlan])

	const bunkerCost = useMemo(() => {
		return initialFinacialItem({
			desc: "Bunker Cost",
			actual: totalBunkerCost,
			plan: estCalculate?.bunkerCost,
			posted: otherItem?.bunkerPlan?.bunkerPlanSettled?.settledAmount ?? 0,
			diffPosted: totalBunkerCost
				? ((otherItem?.bunkerPlan?.bunkerPlanSettled?.settledAmount ?? 0) - totalBunkerCost) /
					totalBunkerCost
				: 0,
			children: [
				initialFinacialItem({
					desc: "HSFO Cost",
					actual: otherItem?.bunkerPlan?.hsfoTotalPrice + +otherItem?.bunkerPlan?.scrubberTotalPrice
				}),
				initialFinacialItem({
					desc: "LSFO Cost",
					actual: otherItem?.bunkerPlan?.lsfoTotalPrice,
					plan: estCalculate?.ifoCost
				}),
				initialFinacialItem({
					desc: "MDO Cost",
					actual: otherItem?.bunkerPlan?.mdoTotalPrice,
					plan: estCalculate?.mdoCost
				}),
				initialFinacialItem({
					desc: "MGO Cost",
					actual: otherItem?.bunkerPlan?.mgoTotalPrice
				})
			]
		})
	}, [
		totalBunkerCost,
		otherItem?.bunkerPlan?.bunkerPlanSettled,
		otherItem?.bunkerPlan,
		estCalculate?.bunkerCost,
		estCalculate?.mdoCost,
		estCalculate?.ifoCost,
		otherItem?.ap
	])

	const totalBunkerage = useMemo(() => {
		return initialFinacialItem({
			desc: "Bunkerage",
			actual: totalBunkerCost + otherItem?.bunkerPlan?.totalBunkerPlanExpense,
			posted:
				(otherItem?.bunkerPlan?.bunkerPlanSettled?.settledAmount ?? 0) +
				(otherItem?.bunkerPlan?.bunkerPlanExpenseSettled?.settledAmount ?? 0),
			children: [
				bunkerCost,
				initialFinacialItem({
					desc: "Bunker Port Expenses",
					actual: otherItem?.bunkerPlan?.totalBunkerPlanExpense,
					plan: estCalculate?.bunkerExpenses,
					posted: otherItem?.bunkerPlan?.bunkerPlanExpenseSettled
						? otherItem?.bunkerPlan?.bunkerPlanExpenseSettled?.settledAmount
						: null
				})
			]
		})
	}, [
		otherItem?.bunkerPlan?.totalBunkerPlanExpense,
		otherItem?.bunkerPlan?.bunkerPlanExpenseSettled,
		otherItem?.bunkerPlan?.bunkerPlanSettled,
		estCalculate?.bunkerExpenses,
		totalBunkerCost,
		bunkerCost
	])

	console.log("totalSummary", totalSummary)

	const totalCost = useMemo(() => {
		const diffVal = actualCalauate?.totalCost - estCalculate?.totalCost
		return initialFinacialItem({
			desc: "Total Cost",
			actual: actualCalauate?.totalCost,
			posted: totalSummary.totalCost,
			plan: estCalculate?.totalCost,
			diffActual: actualCalauate?.totalCost ? diffVal / actualCalauate?.totalCost : 0,
			children: [
				totalHireExpense,
				totalBunkerage,
				totalPortExpense,
				totalCommission,
				totalOtherExpenses
			]
		})
	}, [
		actualCalauate?.totalCost,
		totalOtherExpenses,
		totalSummary,
		estCalculate?.totalCost,
		totalHireExpense,
		totalBunkerage,
		totalPortExpense,
		totalCommission
	])

	const totalDisputeCharge = useMemo(() => {
		return initialFinacialItem({
			desc: "Dispute Charge",
			actual: disputeCharges?.total,
			children: disputeCharges?.calcuate
		})
	}, [disputeCharges])

	const handleCombineParams = (
		item: Omit<
			downloadMovementPnlParams,
			"freights" | "hires" | "disputeCharges" | "totalPnl" | "actualDays"
		>
	): downloadMovementPnlParams => {
		const isFreightSettled = otherItem?.freightList?.find(
			(freight) => freight?.status === "Settled"
		)
		return {
			...item,
			freights: {
				balanceOfFreightAmount: actualCalauate?.freightIncome - freightExpense?.total,
				// actualCalauate?.freightIncome + laytime?.total - freightExpense?.total,
				oceanFreightTime: otherItem?.freightList?.at(-1)?.latestFinancialDate,
				// oceanFreightAmount: actualCalauate?.freightIncome,
				oceanFreightAmount:
					(!!isFreightSettled ? otherItem?.ar?.invFreight : freightExpense?.total) - laytime?.total,
				// oceanFreightAmount: otherItem?.ar?.invFreight,
				laytime: laytime?.calcuate?.map((item) => ({ desc: item?.desc, actual: item?.actual })),
				otherIncome: +otherItem?.otherIncome,
				total: 0
			},
			hires: {
				hireList: hireExpense?.calcuate?.map((item) => ({
					desc: item?.desc,
					actual: item?.actual,
					time: item?.time,
					posted: item?.posted
				})),
				bunkerage: {
					bunkerCost: otherItem?.bunkerPlan?.bunkerPlanSettled?.latestFinancialDate
						? otherItem?.bunkerPlan?.bunkerPlanSettled?.settledAmount
						: totalBunkerCost,
					bunkerPortCharge: otherItem?.bunkerPlan?.bunkerPlanExpenseSettled?.latestFinancialDate
						? otherItem?.bunkerPlan?.bunkerPlanExpenseSettled?.settledAmount
						: otherItem?.bunkerPlan?.totalBunkerPlanExpense,
					bunkerCostTime: otherItem?.bunkerPlan?.bunkerPlanSettled?.latestFinancialDate,
					bunkerPortTime: otherItem?.bunkerPlan?.bunkerPlanExpenseSettled?.latestFinancialDate
				},
				portExpenses: portExpenses?.calcuate?.map((item) => ({
					desc: item?.desc,
					actual: item?.actual,
					portAgentName: item?.portAgentName,
					time: item?.time
				})),
				commission: {
					chrtsCommission: actualCalauate?.freightAddCommission,
					// chrtsBrokerage: actualCalauate?.freightBrokerage,
					// ownrsBrokerage: actualCalauate?.brokerage,
					ownrsCommission: actualCalauate?.commission
				},
				commissionList: otherCommission?.calcuate?.map((commissionItem) => ({
					actual: commissionItem?.actual,
					posted: commissionItem?.posted,
					time: commissionItem?.time,
					desc: commissionItem?.desc,
					payerName: commissionItem?.payerName
				})),
				otherExpenses: otherExpenses?.calcuate?.map((item) => ({
					desc: item?.desc,
					actual: item?.actual,
					time: item?.time,
					posted: item?.posted
				})),
				// ownersCount: +otherItem?.ownerAccount,
				total: 0
			},
			disputeCharges: disputeCharges?.calcuate?.map((item) => ({
				desc: item?.desc,
				actual: item?.actual
			})),
			// totalPnl: actualCalauate?.totalRevenue - actualCalauate?.totalCost,
			totalPnl: 0,
			actualDays:
				portCalcutor?.ballastPortTotalDays +
				portCalcutor?.ladenPortTotalDays +
				portCalcutor?.ballastSeaDays +
				portCalcutor?.ladenSeaDays
		}
	}

	return {
		actualCalauate,
		totalRevenues,
		totalCost,
		totalDisputeCharge,
		handleCombineParams,
		totalProfitAndLoss
	}
}

export default usePnl
