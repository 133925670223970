import { useEffect, useState } from "react"
import { useAppDispatch } from "@/hook"
import { useNavigate } from "react-router-dom"
import useReminder from "@/hook/useReminder"
import { FleetAisDataProps } from "@/common/AisData/type"
import { RouteCommonAisLineProps } from "../type"
import { onRequest } from "@/action"

const useIndex = (
	hide: boolean,
	imo: RouteCommonAisLineProps["imo"],
	rangeOption: RouteCommonAisLineProps["rangeOption"]
) => {
	const dispatch = useAppDispatch()
	const navigate = useNavigate()
	const { reminder } = useReminder()
	const [loading, setLoading] = useState(false)

	const [dataSource, setDataSource] = useState<FleetAisDataProps["dataSource"]>({
		dates: [],
		speed: 0,
		days: "3/day",
		speeds: []
	})

	const handleChange: FleetAisDataProps["onChange"] = (key, value) => {
		setDataSource((prev) => ({ ...prev, [key]: value }))
	}

	const getAisDataFront = () => {
		setLoading(true)
	}

	const getAisCurrentStateFailed = (error) => {
		setLoading(false)
		reminder("error", error?.msg ? error?.msg + ": " + error?.data : error?.data)
	}

	const getAisCurrentStateSuccess = ({ data }) => {
		setDataSource((prev) => ({
			...prev,
			dates: data?.dates ?? [],
			speeds: data?.route?.trackPoints ?? []
		}))
		setLoading(false)
	}

	const getRouteCommonAisData = () => {
		onRequest(
			"getRouteCommonAisDataApi",
			{
				imo: imo,
				startDate: rangeOption?.startDate,
				endDate: rangeOption?.endDate,
				type: rangeOption?.type
			},
			getAisDataFront,
			getAisCurrentStateSuccess,
			getAisCurrentStateFailed,
			dispatch,
			navigate
		)
	}

	useEffect(() => {
		if (!hide) return
		if (!imo) return
		getRouteCommonAisData()
	}, [hide, imo, rangeOption])

	return {
		loading,
		dataSource,
		handleChange
	}
}

export default useIndex
